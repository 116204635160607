import { useContext } from 'react';
import './index.scss';
import EmployeeComponent from 'components/Settings/Approval/Employee';
import { PermissionContext } from 'components/Global/AuthLayout';

export const Employee = () => {
	const context = useContext(PermissionContext);
	const check = context.isAdmin;
	return <div>{check && <EmployeeComponent />}</div>;
};
