import { Popover, Select } from 'antd';
import { useEffect, useState } from 'react';
import { invalidText } from 'utils/utils';

type Props = {
	defaultOption?: boolean;
	defaultLabel?: string;
	value: string | string[] | null;
	options: any[];
	onChange: (value: string | number | string[]) => void;
	placeholder: string;
	required: boolean;
	helperText: string;
	label?: string;
	isError: boolean;
	size?: 'small' | 'middle' | 'large';
	width?: string | number;
	className?: string;
	labelSuffix?: any;
	labelSuffixContent?: any;
	disabled?: boolean;
	mode?: 'multiple' | 'tags' | undefined;
	loading?: boolean;
	isViewOnly?: boolean;
	style?: any;
	extraLabel?: any;
};

const SelectDropdown = (props: Props) => {
	const {
		defaultOption = false,
		defaultLabel,
		value,
		options,
		placeholder,
		size = 'middle',
		required,
		helperText = 'Field required',
		onChange,
		label,
		isError,
		className,
		labelSuffix,
		width,
		mode,
		labelSuffixContent,
		disabled = false,
		loading = false,
		isViewOnly = false,
		style,
		extraLabel,
	} = props;

	const [hasError, setHasError] = useState(false);

	const handleChange = (value: string | string[]) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}
		onChange(value);
	};
	useEffect(() => {
		setHasError(false);
	}, [options]);

	return (
		<div className="input-field">
			{!options?.length && isViewOnly ? (
				<></>
			) : (
				<div
					style={{
						...style,
					}}
				>
					{label && (
						<p
							className="label"
							style={{
								marginRight: '4px',
								marginBottom: `${isViewOnly ? '5px' : ''}`,
							}}
						>
							{label}{' '}
							{required && !isViewOnly && (
								<span className="red">*</span>
							)}
							{extraLabel && <>{extraLabel}</>}
							<Popover
								content={labelSuffixContent}
								trigger="hover"
								className="cursor-pointer"
							>
								{labelSuffix}
							</Popover>
						</p>
					)}
					{isViewOnly ? (
						Array.isArray(value) && mode === 'multiple' ? (
							<p>
								<Popover
									content={
										<ul
											style={{
												maxHeight: '500px',
												overflow: 'auto',
											}}
										>
											{value.map((val) => (
												<>
													{options.find(
														(option) =>
															option.value === val
													)?.label || val}
													<br />
												</>
											))}
										</ul>
									}
									trigger="hover"
									placement="topRight"
								>
									{options.find(
										(option) => option.value === value[0]
									)?.label || value[0]}
									{value.length > 1 && '...'}
								</Popover>
							</p>
						) : (
							<p>
								{options.find(
									(option) => option.value === value
								)?.label || value}
							</p>
						)
					) : (
						<div>
							<Select
								showSearch
								optionFilterProp="label"
								mode={mode ?? undefined}
								value={value}
								options={options}
								onChange={(value) => handleChange(value)}
								size={size}
								placeholder={placeholder}
								maxTagCount={'responsive'}
								status={hasError || isError ? 'error' : ''}
								style={{
									width: width ?? '100%',
								}}
								disabled={disabled}
								loading={loading}
							>
								{defaultOption && (
									<option key="Static" value="">
										{defaultLabel}
									</option>
								)}
								{options &&
									options.length > 0 &&
									options?.map((option, key) => (
										<option key={key} value={option.value}>
											{option.label}
										</option>
									))}
							</Select>
							{(hasError || isError) && (
								<p
									className="red"
									style={{
										fontSize: '12px',
										marginLeft: '2px',
									}}
								>
									{helperText}
								</p>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default SelectDropdown;
