export const payrollStatusOptions = [
	{
		label: 'All Status',
		value: '',
	},
	{
		label: 'Pending',
		value: 'PENDING',
	},
	{
		label: 'Approved',
		value: 'APPROVED',
	},
	{
		label: 'Rejected',
		value: 'REJECTED',
	},
	{
		label: 'Draft',
		value: 'DRAFT',
	},
];

