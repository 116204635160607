/* eslint-disable react-hooks/exhaustive-deps */
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import React, { useContext, useEffect, useState } from 'react';
import { handleDownload, toastText } from 'utils/utils';
import Styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import GlobalReportTable from '../GlobalReportTable';
import GenerateNhimaModal from './GenerateNhimaModal';
import { reportApis } from 'Api/reports';
import { PermissionContext } from 'components/Global/AuthLayout';
import { Typography } from 'antd';

const NhimaReportComponent = () => {
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [nhimaReportData, setNhimaReportData] = useState<any[]>([]);
	const [downloadingReportId, setDownloadingReportId] = useState<
		string | null
	>(null);

	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Add_Payroll_Reports');

	const { Text } = Typography;
	const buttons = [
		{
			text: 'Generate',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setModalOpen(true);
			},
			disabled: isLoading,
		},
	];

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const cancelModal = () => {
		setModalOpen(false);
	};

	const fetchNhimaReports = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const nhimaReportData = await reportApis.getNhimaReports(query);
			setNhimaReportData(nhimaReportData.data.data);
			setTotalRecords(nhimaReportData.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching NHIMA Reports';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const downloadReport = async (reportId: string) => {
		const reportName = nhimaReportData.find(
			(data: any) => data.id === reportId
		)?.documentName;
		return await handleDownload(
			reportId,
			reportApis.downloadReport,
			setDownloadingReportId,
			reportName
		);
	};
	useEffect(() => {
		fetchNhimaReports();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<div className={Styles['container']}>
				<div className={Styles['nhima-header']}>
					<p>NHIMA</p>
					<span className={Styles['divider']}></span>
					<Text style={{ margin: 0, color: '#444' }}>
						National Health Insurance Management Authority
						(NHIMA)Report. This report outlines the health insurance
						contributions for employees, ensuring access to national
						healthcare services.
					</Text>
					{check && <Buttons buttons={buttons} />}
				</div>
				<GlobalReportTable
					ReportData={nhimaReportData}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					downloadReport={downloadReport}
					downloadingReportId={downloadingReportId}
					setDownloadingReportId={setDownloadingReportId}
				/>
			</div>
			{modalOpen && (
				<>
					<GenerateNhimaModal
						open={modalOpen}
						cancelGenerateModal={cancelModal}
						fetchNhimaReportDetails={fetchNhimaReports}
					/>
				</>
			)}
		</>
	);
};

export default NhimaReportComponent;
