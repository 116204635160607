import React, { useEffect, useState, useMemo } from 'react';
import { Table, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { capitalizeWords, formatNumber, toastText } from 'utils/utils';
import './index.scss';
import GlobalHeader from '../../GlobalHeader';

interface Props {
	selectedPayrollId: string;
	setDeductionJSON: (data: any) => void;
	onlyView: boolean;
	setIsUniversalLoading: any;
	headerData: any;
}

interface ColumnData {
	parent: string;
	title: string;
	children?: ColumnData[];
	key?: string;
}

const TotalDeductionTable: React.FC<Props> = ({
	selectedPayrollId,
	setDeductionJSON,
	onlyView,
	setIsUniversalLoading,
	headerData,
}) => {
	const [showEmployer, setShowEmployer] = useState(false);
	const [deductionData, setDeductionData] = useState<any[]>([]);
	const [rowData, setRowData] = useState<any[]>([]);
	const [columnsData, setColumnsData] = useState<ColumnData[]>([]);
	const [isDeductionLoading, setIsDeductionLoading] = useState(false);
	const [deductionLimit, setDeductionLimit] = useState(0);

	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(31);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const { data } = useSelector((state: any) => state?.userProfile);

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const handleSwitchChange = (checked: boolean) => {
		setShowEmployer(checked);
	};

	const formatData = (rows: any, napsaCapLimit: number) => {
		const formattedRowData = rows?.map((item: any) => {
			const formattedItem: any = {};
			for (const key in item) {
				if (key === 'Employee') {
					formattedItem[key] = item[key];
				} else if (!usdCurrencyToggle && isUsdCurrency) {
					const value = Number(item[key]);
					formattedItem[key] = formatNumber(
						value,
						data?.CompanyCurrencies,
						true
					);
				} else if (
					(key === 'napsaEmployerShare' ||
						key === 'napsaEmployeeShare') &&
					usdCurrencyToggle
				) {
					const value =
						Number(item[key] * (conversionRate ?? 1)) >
						napsaCapLimit
							? napsaCapLimit
							: Number(item[key] * conversionRate);

					formattedItem[key] = formatNumber(
						value,
						data?.CompanyCurrencies
					);
				} else if (key === 'total' && usdCurrencyToggle) {
					const value =
						Number(
							item['napsaEmployerShare'] * (conversionRate ?? 1)
						) > napsaCapLimit
							? Number(item[key] * conversionRate) -
							  (Number(
									item['napsaEmployerShare'] *
										(conversionRate ?? 1)
							  ) -
									napsaCapLimit)
							: Number(item[key] * conversionRate);
					formattedItem[key] = formatNumber(
						value,
						data?.CompanyCurrencies
					);
				} else {
					const value = Number(item[key] * (conversionRate ?? 1));
					formattedItem[key] = formatNumber(
						value,
						data?.CompanyCurrencies
					);
				}
			}
			return formattedItem;
		});

		setDeductionData(formattedRowData);
	};

	const generateColumns = useMemo(() => {
		const processColumn = (column: ColumnData): any => {
			const { title, children, key } = column;
			if (!children || children.length === 0) {
				if (
					!showEmployer &&
					(key === 'napsaEmployerShare' ||
						key === 'nhimaEmployerShare')
				) {
					return null;
				}
				return {
					title: capitalizeWords(title),
					dataIndex: key || title,
					key: key || title,
					width: '150px',
					className: 'children-normal-font-weight',
				};
			}

			const processedChildren = children
				.map(processColumn)
				.filter(Boolean);
			if (processedChildren.length === 0) {
				return null;
			}

			return {
				title: capitalizeWords(title),
				children: processedChildren,
				className: 'children-normal-font-weight',
			};
		};

		const groupedColumns = columnsData.reduce(
			(acc: any, column: ColumnData) => {
				const { parent } = column;
				if (!acc[parent]) {
					acc[parent] = [];
				}
				const processedColumn = processColumn(column);
				if (processedColumn) {
					acc[parent].push(processedColumn);
				}
				return acc;
			},
			{}
		);

		const convertedColumns = Object.entries(groupedColumns).map(
			([parent, children]) => ({
				title:
					parent === 'Statutory Components' ? (
						<>
							Statutory Components
							<Switch
								checked={showEmployer}
								onChange={handleSwitchChange}
								style={{ marginLeft: 10 }}
							/>
							<span style={{ marginLeft: 10 }}>
								Show employers
							</span>
						</>
					) : (
						parent
					),
				className: `background-color-dark ${
					parent === 'Statutory Components'
						? 'statutory-components'
						: ''
				}`,
				children,
			})
		);

		convertedColumns.unshift({
			title: '',
			className: 'background-color-dark',
			children: [
				{
					title: 'Employees',
					dataIndex: 'Employee',
					key: 'Employee',
					fixed: 'left',
					width: 250,
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>{record.id === 'total' ? text : `${text}`}</span>
					),
				},
			],
		});

		convertedColumns.push({
			title: 'Total',
			className: 'background-color-dark',
			children: [
				{
					title: '',
					dataIndex: 'total',
					key: 'total',
					width: '150px',
				},
			],
		});

		return convertedColumns;
	}, [columnsData, showEmployer]);

	useEffect(() => {
		formatData(rowData, deductionLimit);
	}, [usdCurrencyToggle]);

	const fetchDeductions = async (payrollId: string) => {
		try {
			let response;
			setIsDeductionLoading(true);
			setIsUniversalLoading(true);
			if (onlyView) {
				response = await viewPayrollApi.getAllEmployeeDeductions({
					payrollId: payrollId,
					page: page,
					pageSize: pageSize - 1,
				});
			} else {
				response = await payrollApi.getPayrollDeductions({
					payrollId: payrollId,
					page: page,
					pageSize: pageSize - 1,
				});
			}

			const { columns, rows, napsaCapLimit } = response.data.data;
			setDeductionLimit(napsaCapLimit ?? 1490.8);
			setTotalRecords(response?.data?.data?.totalRecords);
			setColumnsData(columns);
			setDeductionJSON(rows);

			setRowData(rows);
			formatData(response.data.data.rows, napsaCapLimit);
		} catch (err: any) {
			const message =
				err?.response?.data?.message ||
				'Something went wrong in fetching deductions';
			toastText(message, 'error');
		} finally {
			setIsDeductionLoading(false);
			setIsUniversalLoading(false);
		}
	};
	useEffect(() => {
		if (selectedPayrollId) {
			fetchDeductions(selectedPayrollId);
		}
	}, [selectedPayrollId, page, pageSize]);

	return (
		<div className="payroll-steps-table">
			<GlobalHeader
				headerData={headerData}
				toggleRequired={true}
				fetchAllData={() => fetchDeductions(selectedPayrollId)}
				isLoading={isDeductionLoading}
			/>
			<Table
				columns={generateColumns}
				dataSource={deductionData}
				className="total-deduction-table"
				rowClassName={(record, index) =>
					index === deductionData.length - 1 ? 'sticky-row' : ''
				}
				bordered
				scroll={{ y: 'calc(100vh - 540px)' }}
				pagination={{
					total: totalRecords,
					current: page,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				loading={isDeductionLoading}
				onChange={tableChangeHandler}
			/>
		</div>
	);
};

export default TotalDeductionTable;
