import { getApi, postApi } from 'apis';
const createCompensation = async (data: any) => {
	return await postApi('/workersCompensation/create', data, true);
};

const getLatestCompensation = async () => {
	return await getApi('/workersCompensation/latest');
};

const getCompensationHistory = async (params: any) => {
	return await getApi(`/workersCompensation/history`, params);
};

const calculateCompensation = async (data: any) => {
	return await postApi('/calculation/workersCompensation', data);
};

const getCompensationReports = async (params: any) => {
	return await getApi('/workersCompensation/compensationReports', params);
};

const createCompensationRecord = async (data: any, compensationRecordId: string) => {
	return await postApi(`/workersCompensation/record/${compensationRecordId}`, data, true);
};



export const workersCompensationApi = {
	createCompensation,
	getLatestCompensation,
	getCompensationHistory,
	getCompensationReports,
	createCompensationRecord,
	calculateCompensation,
};
