import { postApi, getApi, putApi } from 'apis';
const createPayPeriod = async (data: any) => {
	return await postApi('/payPeriod/create', data);
};

const getLatestPayPeriod = async () => {
	return await getApi('/payPeriod/latest');
};

const getAllPayPeriods = async (params: any) => {
	return await getApi(`/payPeriod`, params);
};

const getPayPeriodOptions = async (params: any) => {
	return await getApi(`/payPeriod/options`, params);
};

const updatePayPeriod = async (data: any) => {
	return await putApi(`/payPeriod/update`, data);
};

const lastEndDate = async (params: any) => {
	return await getApi(`/payPeriod/year-end-date`, params);
};

export const payPeriodApi = {
	createPayPeriod,
	getLatestPayPeriod,
	getAllPayPeriods,
	getPayPeriodOptions,
	updatePayPeriod,
	lastEndDate,
};
