import { Col, Form, Input, InputNumber, Row, UploadFile, Upload } from 'antd';
import { FC, useState } from 'react';
import Buttons from 'components/Global/Buttons';
import { formatToDateOnly, invalidText, toastText } from 'utils/utils';
import './index.scss';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { napsaApi } from 'Api/napsa';
import { UploadChangeParam } from 'antd/es/upload';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchCompanyWizard } from '../../../../../../redux/actions/payrollSettingWizardAction';

const AddNapsaSideDrawer: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [date, setDate] = useState<any>(dayjs());
	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const [employeeShare, setEmployeeShare] = useState<number | null>(null);
	const [employerShare, setEmployerShare] = useState<number | null>(null);
	const [napsaDeductionLimit, setNapsaDeductionLimit] = useState<
		number | null
	>(null);
	const [napsaAgeLimit, setNapsaAgeLimit] = useState<number | null>(null);

	const dispatch = useDispatch<AppDispatch>();

	let isRemoving = false;

	const [form] = Form.useForm();

			const { baseCurrencySymbol } = useSelector(
				(state: any) => state.userProfile.data
			);

	const {
		closeDrawerByAnimation,
		fetchLatestNapsaData,
		fetchAllNapsaDetails,
	} = props;

	const handleDate = (value: any) => {
		setDate(value);
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: false,
			onclick: () => {},
			font: '16px',
		},
	];

	const propsUpload = {
		name: 'file',
		// accept: '.xls,.xlsx,.csv',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}
			// const isValidType = [
			// 	'application/vnd.ms-excel',
			// 	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			// 	'text/csv',
			// 	'application/csv',
			// ].includes(file.type!);

			// if (!isValidType) {
			// 	toastText(
			// 		'Invalid file type! Only Excel files (.xls, .xlsx) and CSV files (.csv) are allowed.',
			// 		'error'
			// 	);
			// 	return Upload.LIST_IGNORE;
			// }

			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false; // Prevents automatic upload
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};

	const handleSubmit = async (values: any) => {
		setLoading(true);
		const napsaFormData = new FormData();
		napsaFormData.append(
			'effectiveDate',
			formatToDateOnly(values.effectiveDate)
		);
		napsaFormData.append('employeeShare', values.employeeShare);
		napsaFormData.append('employerShare', values.employerShare);
		napsaFormData.append('napsaDeductionLimit', values.napsaDeductionLimit);
		napsaFormData.append(
			'notes',
			invalidText(values.notes) ? null : values.notes
		);
		napsaFormData.append('napsaAgeLimit', values.napsaAgeLimit);
		napsaFormData.append('moduleName', 'NAPSA');
		napsaFormData.append('file', file as Blob);

		try {
			const response = await napsaApi.createNapsa(napsaFormData);
			let message =
				response?.data?.message || 'NAPSA details saved successfully';
			toastText(message, 'success');
			dispatch(fetchCompanyWizard());
			closeDrawerByAnimation(true);
			fetchLatestNapsaData();
			fetchAllNapsaDetails();
		} catch (error) {
			console.error('Error submitting form:', error);
			toastText('Failed to save NAPSA details', 'error');
		} finally {
			setLoading(false);
		}
	};

	const validatePercentage = (_: any, value: number) => {
		if (value < 0 || value > 100) {
			return Promise.reject('Percentage must be between 0 and 100');
		}
		return Promise.resolve();
	};

	const handleEmployeeShareChange = (value: number | null) => {
		setEmployeeShare(value);
		form.setFieldsValue({ employeeShare: value });
		if (value !== null) {
			setEmployerShare(value);
			form.setFieldsValue({ employerShare: value });
		}
	};

	const handleEmployerShareChange = (value: number | null) => {
		setEmployerShare(value);
		form.setFieldsValue({ employerShare: value });
		if (value !== null) {
			setEmployeeShare(value);
			form.setFieldsValue({ employeeShare: value });
		}
	};

	const handleNapsaDeductionLimitChange = (value: number | null) => {
		setNapsaDeductionLimit(value);
		form.setFieldsValue({ napsaDeductionLimit: value });
	};

	const handleNapsaAgeLimitChange = (value: number | null) => {
		setNapsaAgeLimit(value);
		form.setFieldsValue({ napsaAgeLimit: value });
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 24 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={{ effectiveDate: date }}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Effective Date'}{' '}
							<span className="required-color">*</span>
						</label>
						<div>
							<Form.Item
								name="effectiveDate"
								rules={[
									{
										required: true,
										message: 'Please select a date',
									},
								]}
							>
								<DatePickerField
									onChange={handleDate}
									value={date}
									isError={false}
									disabled={false}
									placeholder="Select Date"
									required
									name="effectiveDate"
									// disabledBeforeDates={dayjs().startOf('day')}
								/>
							</Form.Item>
						</div>
					</div>

					<Row>
						<Col span={12} className="input-column">
							<div className={styles['side-drawer-form__inputs']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Employee Share'}{' '}
									<span className="required-color">*</span>
								</label>

								<Form.Item
									name="employeeShare"
									rules={[
										{
											required: true,
											message:
												'Please enter employee share',
										},
										{ validator: validatePercentage },
									]}
								>
									<div>
										<InputNumber
											value={employeeShare}
											addonAfter="%"
											min={0}
											size="large"
											max={100}
											step={0.01}
											parser={(value: any) => {
												const parsedValue = parseFloat(
													value?.replace('%', '') ||
														'0'
												);
												return isNaN(parsedValue)
													? 0
													: parsedValue;
											}}
											onChange={handleEmployeeShareChange}
											style={{ width: '100%' }}
										/>
										<span className={styles['helper-text']}>
											Percentage of total earnings
										</span>
									</div>
								</Form.Item>
							</div>
						</Col>

						<Col span={12} className="input-column">
							<div className={styles['side-drawer-form__inputs']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Employer Share'}{' '}
									<span className="required-color">*</span>
								</label>
								<Form.Item
									name="employerShare"
									rules={[
										{
											required: true,
											message:
												'Please enter employer share',
										},
										{ validator: validatePercentage },
									]}
								>
									<div>
										<InputNumber
											value={employerShare}
											addonAfter="%"
											size="large"
											min={0}
											max={100}
											step={0.01}
											parser={(value: any) => {
												const parsedValue = parseFloat(
													value?.replace('%', '') ||
														'0'
												);
												return isNaN(parsedValue)
													? 0
													: parsedValue;
											}}
											onChange={handleEmployerShareChange}
											style={{ width: '100%' }}
										/>
										<span className={styles['helper-text']}>
											Percentage of total earnings
										</span>
									</div>
								</Form.Item>
							</div>
						</Col>
					</Row>

					<Row>
						<Col span={12} className="input-column">
							<div className={styles['side-drawer-form__inputs']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Napsa Deduction Limit'}{' '}
									<span className="required-color">*</span>
								</label>
								<Form.Item
									name="napsaDeductionLimit"
									rules={[
										{
											required: true,
											message:
												'Please enter napsa deduction limit',
										},
									]}
								>
									<div>
										<InputNumber
											value={napsaDeductionLimit}
											addonAfter={baseCurrencySymbol}
											size="large"
											min={0}
											step={0.01}
											onChange={
												handleNapsaDeductionLimitChange
											}
											style={{ width: '100%' }}
										/>
										<span className={styles['helper-text']}>
											Napsa Deduction Limit for USD
										</span>
									</div>
								</Form.Item>
							</div>
						</Col>
						<Col span={12} className="input-column">
							<div className={styles['side-drawer-form__inputs']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Napsa Age Limit'}{' '}
									<span className="required-color">*</span>
								</label>
								<Form.Item
									name="napsaAgeLimit"
									rules={[
										{
											required: true,
											message:
												'Please enter napsa age limit',
										},
									]}
								>
									<div>
										<InputNumber
											value={napsaAgeLimit}
											size="large"
											min={0}
											step={1}
											onChange={handleNapsaAgeLimitChange}
											style={{ width: '100%' }}
										/>
										<span className={styles['helper-text']}>
											Napsa Age Limit
										</span>
									</div>
								</Form.Item>
							</div>
						</Col>
					</Row>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Attachment'}
						</label>
						<Form.Item name="attachment" required>
							<Dragger {...propsUpload} className="test">
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-hint">
									Drag and drop a file or choose file from
									{/* Device Supported file formats. */}
									<br />
									{/* XLS, XLSX, CSV |{' '} */}
									<span className="color-purple">
										Maximum file size: 5MB
									</span>
								</p>
							</Dragger>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__napsa--label']}
						>
							{'Notes'}
						</label>
						<Form.Item name="notes" wrapperCol={{ span: 24 }}>
							<Input.TextArea
								className={styles['side-drawer-form--textarea']}
							/>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddNapsaSideDrawer;
