import { salaryApi } from 'Api/salaryDetails';
import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputNumberField from 'components/Global/InputNumberField';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import TextareaFieldInput from 'components/Global/textareaField';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import { AddSalaryModalProps } from './types';

const AddSalaryModal: FC<AddSalaryModalProps> = (props) => {
	const {
		handleCancel,
		isAddSalaryModalOpen,
		employeeId,
		fetchSalary,
		setIsEmployeeSalaryExists,
		isUSD,
	} = props;

	const [salaryDetails, setSalaryDetails] = useState<any>({
		monthlySalary: null,
		hourlySalary: null,
		midMonthSalary: null,
		effectiveStartDate: '',
		// notes: '',
		notes: null,
		year: dayjs(),
	});

	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	const [formError, setFormError] = useState<any>({
		monthlySalary: false,
		hourlySalary: false,
		midMonthSalary: isUSD ? false : false,
		// midMonthSalary: false,
		effectiveStartDate: false,
		notes: false,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [searchParams] = useSearchParams();
	const [payPeriodError, setPayPeriodError] = useState(false);
	const { data: payPeriodData } = useSelector(
		(state: any) => state.payPeriod
	);

	const { baseCurrencySymbol, usdCurrencySymbol } = useSelector(
		(state: any) => state.userProfile.data
	);

	const id = searchParams.get('employeeId');

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			disabled: isLoading,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleSubmit();
			},
		},
	];

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...salaryDetails },
			// { ...formError }
			{
				...formError,
				midMonthSalary: isUSD ? false : formError.midMonthSalary, // Mid-month salary not required for USD
			}
		);
		checkFormError = {
			...checkFormError,
			midMonthSalary: isUSD ? false : checkFormError.midMonthSalary,
			notes: false,
		};

		setFormError(checkFormError);

		if (invalidText(selectedPayPeriod)) {
			setPayPeriodError(true);
			return;
		}

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				...salaryDetails,
				id: searchParams.get('employeeId'),
				// midMonthSalary: isUSD
				// 	? null
				// 	: parseFloat(salaryDetails.midMonthSalary),
				midMonthSalary: parseFloat(salaryDetails.midMonthSalary),
				hourlySalary: parseFloat(salaryDetails.hourlySalary),
				monthlySalary: parseFloat(salaryDetails.monthlySalary),
				effectiveStartDate: formatToDateOnly(
					salaryDetails.effectiveStartDate
				),
				payperiodId: selectedPayPeriod,
				// payperiodId: salaryDetails.payperiodId,
			};
			if (!data.notes) {
				delete data['notes'];
			}

			setIsLoading(true);
			if (searchParams.get('employeeId')) {
				try {
					const response = await salaryApi.createSalary(data);
					let message =
						response?.data?.message ||
						'Employee salary created successfully';
					toastText(message, 'success');
					setIsEmployeeSalaryExists(true);
					fetchSalary();
					closeModal();
				} catch (err: any) {
					let message =
						err.response?.data?.message ||
						'Something went wrong in creating employee salary';
					toastText(message, 'error');
				}
			} else {
				toastText(
					'Create an employee before adding salary details',
					'error'
				);
			}
			setIsLoading(false);
		}
	};

	const closeModal = () => {
		setSalaryDetails({
			monthlySalary: null,
			hourlySalary: null,
			midMonthSalary: isUSD ? null : '',
			// midMonthSalary: null,
			effectiveStartDate: '',
			// payperiodId: '',
			notes: null,
		});
		setFormError({
			monthlySalary: false,
			hourlySalary: false,
			midMonthSalary: false,
			//  midMonthSalary: isUSD ? false : false,
			effectiveStartDate: false,
			// payperiodId: false,
			notes: false,
		});
		setPayPeriodError(false);
		setSelectedPayPeriod('');
		setSelectedYear(dayjs());

		handleCancel();
	};

	const handleChange = (
		value: string | number | null | String[],
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (name === 'midMonthSalary' && isUSD) {
			setSalaryDetails((prev: any) => ({
				...prev,
				midMonthSalary: value === '' ? null : value,
			}));

			setFormError((prev: any) => ({
				...prev,
				midMonthSalary: false, // No error for USD midMonthSalary
			}));
			return;
		}
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'year') {
			setSalaryDetails((prev: any) => ({
				...prev,
				year: value,
			}));
			// getPayPeriodData(dayjs(value as any).year());
			// setYear(value);
		}
		if (name === 'monthlySalary') {
			const newMonthlySalary = Number(value);
			const newHourlySalary = newMonthlySalary
				? (newMonthlySalary / 208).toFixed(2)
				: 0;

			setSalaryDetails((prev: any) => ({
				...prev,
				hourlySalary: newHourlySalary,
			}));
		}
		if (name === 'hourlySalary') {
			const newHourlySalary = Number(value);
			const newMonthlySalary = newHourlySalary
				? (newHourlySalary * 208).toFixed(2)
				: 0;

			setSalaryDetails((prev: any) => ({
				...prev,
				monthlySalary: newMonthlySalary,
			}));
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | String[],
		key: string
	) => {
		setSalaryDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handlePayPeriod = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			return;
		}

		setSelectedPayPeriod(value);
		const _findPayPeriod = payPeriodData.find(
			(item: any) => item.value === value
		);

		setSalaryDetails((prev: any) => ({
			...prev,
			effectiveStartDate: _findPayPeriod.startDate
				? dayjs(_findPayPeriod?.startDate)
				: '',
		}));
	};

	const handleYearChange = (value: any) => {
		setSelectedYear(value);
		setSalaryDetails((prev: any) => ({
			...prev,
			year: value,
			effectiveStartDate: '',
		}));
		setSelectedPayPeriod('');
	};

	return (
		<Modal
			open={isAddSalaryModalOpen}
			// onOk={handleSubmit}
			onCancel={closeModal}
			okText={'Save'}
			closable={false}
			width={800}
			footer={null}
		>
			<div className="modal">
				<div className={styles['modal-header']}>
					<Title level={4}>
						<span className="modal-title">Add Salary</span>
					</Title>
				</div>
				<Row className={styles['form-container-card']} gutter={50}>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<h3>EmployeeId : {employeeId}</h3>
					</Col>
				</Row>
				<Row className={styles['form-container-card']} gutter={50}>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputNumberField
							name="monthlySalary"
							value={salaryDetails.monthlySalary}
							label="Monthly Salary"
							required={true}
							helperText="Monthly salary required"
							onChange={(value) => {
								handleChange(value, 'monthlySalary', true);
							}}
							//	suffix={employeeCurrency}
							suffix={
								isUSD ? usdCurrencySymbol : baseCurrencySymbol
							}
							isError={formError.monthlySalary}
						/>
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputNumberField
							name="hourlySalary"
							value={salaryDetails.hourlySalary}
							label="Hourly salary"
							required={true}
							helperText="Hourly salary required"
							onChange={(value) => {
								handleChange(value, 'hourlySalary', true);
							}}
							//suffix={employeeCurrency}
							suffix={
								isUSD ? usdCurrencySymbol : baseCurrencySymbol
							}
							isError={formError.hourlySalary}
						/>
					</Col>
					<GlobalPayPeriod
						selectedYear={selectedYear}
						employeeId={id as string}
						handleSelectedYear={(value) => {
							handleYearChange(value);
						}}
						selectedPayPeriod={selectedPayPeriod}
						handlePayPeriod={(value) => handlePayPeriod(value)}
						rowStyle={{
							marginTop: '10px',
							width: '100%',
						}}
						payPeriodError={payPeriodError}
						isRow={false}
					/>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<DatePickerField
							name="effectiveStartDate"
							value={salaryDetails.effectiveStartDate}
							label="Effective Start Date"
							required={true}
							helperText="Effective start date required"
							onChange={(value) => {
								// handleChange(value, 'effectiveStartDate', true);
							}}
							isError={formError.effectiveStartDate}
							disabled={true}
						/>
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputNumberField
							name="midMonthSalary"
							value={salaryDetails.midMonthSalary}
							label="Mid month salary"
							required={!isUSD}
							// required={true}
							helperText="Mid month salary required"
							onChange={(value) => {
								handleChange(value, 'midMonthSalary', !isUSD);
							}}
							// onChange={(value) => {
							// 	handleChange(value, 'midMonthSalary', true);
							// }}
							suffix={
								isUSD ? usdCurrencySymbol : baseCurrencySymbol
							}
							//	suffix={employeeCurrency}
							isError={formError.midMonthSalary}
							//	disabled={isUSD}
						/>
					</Col>
					<Col span={24} className={`${styles['col']} margin-top-10`}>
						<TextareaFieldInput
							name="notes"
							value={salaryDetails.notes}
							label="Notes"
							required={false}
							helperText="Notes required"
							onChange={(value) => {
								handleChange(value, 'notes', false);
							}}
							isError={formError.notes}
						/>
					</Col>
				</Row>
				<div className={styles['buttons']}>
					<Buttons buttons={myButtons} />
				</div>
			</div>
		</Modal>
	);
};

export default AddSalaryModal;
