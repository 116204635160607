import { PermissionContext } from 'components/Global/AuthLayout';
import DeductionReportComponent from 'components/Report/DeductionReport';
import { useContext } from 'react';
const DeductionReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Earning_and_Deduction_Reports');
	return <>{check && <DeductionReportComponent />}</>;
};

export default DeductionReport;
