import React, { useContext } from 'react';

import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import LeaveManagementComponent from 'components/Settings/Masters/LeaveManagement';

export const LeaveManagement = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Leave_Management');

	return <div>{check && <LeaveManagementComponent />}</div>;
};
