import { Button, Select, Space, Tooltip } from 'antd';
import { useState } from 'react';
import DatePickerField from 'components/Global/DatePicker';
import styles from './index.module.scss';
import MonthlyTimeLogsModal from '../MonthlyAddLogsModal';
import {
	ProjectOutlined,
	PlusOutlined,
	CalendarOutlined,
} from '@ant-design/icons';

type Props = {
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	options: any;
	handleDatePicker: any;
	date: any;
	employeeCount: number;
	dropdownOptionLoading: boolean;
	handleStaffCodeChange: any;
	staffCode: any;
	fetchEmployeesTimeActivities: any;
};

const EmployeeHeader = (props: Props) => {
	const {
		statusFilterValue,
		handleStatusFilter,
		options,
		date,
		handleDatePicker,
		employeeCount,
		dropdownOptionLoading,
		handleStaffCodeChange,
		staffCode,
		fetchEmployeesTimeActivities,
	} = props;

	const [isAddLogsModalOpen, setIsAddLogsModalOpen] = useState(false);

	const staffCodeOptions = [
		{ label: 'Hourly Employee', value: 'HOURLY' },
		{ label: 'Monthly Employee', value: 'MONTHLY' },
	];

	return (
		<div className={styles['employee-header']}>
			<Space>
				{options?.length ? (
					<Space>
						<Select
							className={styles['employee-header-item']}
							value={statusFilterValue}
							options={options}
							onChange={(value) => {
								handleStatusFilter(value);
							}}
							size="large"
							placeholder="Select Status"
							loading={dropdownOptionLoading}
							disabled={dropdownOptionLoading}
							style={{ width: '230px' }}
						/>
					</Space>
				) : null}
				<Space>
					<Select
						className={styles['employee-header-item']}
						value={staffCode}
						options={staffCodeOptions}
						onChange={handleStaffCodeChange}
						size="large"
						style={{ width: '230px' }}
					/>
				</Space>
				<Space
					style={{
						fontSize: '1.5rem',
						color: 'gray',
						marginLeft: '20px',
					}}
				>
					<span>
						Number of Employees:{' '}
						<span style={{ color: 'black' }}>
							{employeeCount < 9
								? `0${employeeCount}`
								: employeeCount}
						</span>
					</span>
				</Space>
			</Space>
			<Space>
				<Space>
					<Tooltip title="Add Monthly Time Logs">
						<Button
							type="primary"
							icon={<CalendarOutlined size={20} />}
							onClick={() => {
								setIsAddLogsModalOpen(true);
							}}
						></Button>
					</Tooltip>
					{/* <Button type="primary" size="large">
						Add Log for Monthly Employees
					</Button> */}
				</Space>
				<DatePickerField
					name={'date'}
					value={date}
					required={false}
					isError={false}
					onChange={handleDatePicker}
					picker="month"
				/>
			</Space>
			{isAddLogsModalOpen && (
				<MonthlyTimeLogsModal
					isOpen={isAddLogsModalOpen}
					handleCancel={() => {
						setIsAddLogsModalOpen(false);
					}}
					fetchEmployeesTimeActivities={fetchEmployeesTimeActivities}
				/>
			)}
		</div>
	);
};

export default EmployeeHeader;
