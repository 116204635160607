import { PermissionContext } from 'components/Global/AuthLayout';
import NhimaReportComponent from 'components/Report/NhimaReport';
import { useContext } from 'react';

const NhimaReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <NhimaReportComponent />}</>;
};

export default NhimaReport;
