import React, { useContext } from 'react';
import './index.scss';
import EarningComponent from 'components/Settings/PayrollSettings/Earning';
import { PermissionContext } from 'components/Global/AuthLayout';

export const Earning = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Earnings_Code');

	return <div>{check && <EarningComponent />}</div>;
};
