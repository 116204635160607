import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import { Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
type headerProps = {
	sidebarDrawerOpen: () => void;
	NhimaExists: boolean;
	isLoading: boolean;
	fetchLatestNhimaData:any;
};

const NhimaHeader = ({ sidebarDrawerOpen, NhimaExists, isLoading,fetchLatestNhimaData }: headerProps) => {
		const permissionContext = useContext(PermissionContext);
		const isAdd = permissionContext.allowedPermissions.includes(
			'Add_Statutory_Components'
		);
	const buttonTitle = NhimaExists ? 'Edit NHIMA' : 'Add NHIMA';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: false,
		},
	];
	return (
		<div className={styles['nhima-header']}>
			<p>NHIMA</p>
			{isAdd && (
				<div className={styles['nhima-header__buttons']}>
					<Tooltip title="Refresh all the NHIMA">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={
								!isLoading ? fetchLatestNhimaData : undefined
							}
						/>
					</Tooltip>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default NhimaHeader;
