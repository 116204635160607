import React, { useState, useCallback, useMemo } from 'react';
import './index.scss';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import NapsaCalculateComponent from './NapsaCalculate';
import NhimaCalculateComponent from './NhimaCalculate';
import PayeCalculateComponent from './PayeCalculate';
import CompensationCalculateComponent from './CompensationCalculate';
import GlobalStatutoryHeader from './GlobalHeader';

const { TabPane } = Tabs;

const CalculateStatutory = () => {
	const [activeKey, setActiveKey] = useState('napsa');
	const [calculateModalOpen, setCalculateModalOpen] = useState(false);
	const [extraDetailsPopover, setExtraDetailsPopover] = useState(false);
	const [addCommunicationModal, setAddCommunicationModal] = useState(false);
	const [informationModal, setInformationModal] = useState(false);

	const getButtonText = useCallback(() => {
		switch (activeKey) {
			case 'napsa':
				return 'NAPSA';
			case 'nhima':
				return 'NHIMA';
			case 'paye':
				return 'PAYE';
			case 'worker-cpmpensation':
				return 'Compensation';
			default:
				return 'Calculate';
		}
	}, [activeKey]);

	const openCalculateModal = useCallback(() => {
		setCalculateModalOpen(true);
		setExtraDetailsPopover(false);
	}, []);

	const openAddCommunicationModal = useCallback(() => {
		setAddCommunicationModal(true);
		setExtraDetailsPopover(false);
	}, []);

	const toggleExtraDetails = useCallback(() => {
		setExtraDetailsPopover(!extraDetailsPopover);
	}, [extraDetailsPopover]);

	const openInformationModal = useCallback(() => {
		setInformationModal(true);
		setExtraDetailsPopover(false);
	}, []);

	const changeTab = (key: string) => {
		setActiveKey(key);
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setExtraDetailsPopover(false);
		setInformationModal(false);
	};

	const closeAllModals = () => {
		setCalculateModalOpen(false);
		setAddCommunicationModal(false);
		setExtraDetailsPopover(false);
		setInformationModal(false);
	};

	const globalHeaderMemo = useMemo(
		() => (
			<GlobalStatutoryHeader
				btnText={getButtonText()}
				openCalculateModal={openCalculateModal}
				openAddCommunicationModal={openAddCommunicationModal}
				openInformationModal={openInformationModal}
				extraDetailsPopover={extraDetailsPopover}
				toggleExtraDetails={toggleExtraDetails}
				showExtraDetails={activeKey !== 'worker-cpmpensation'}
			/>
		),
		[
			getButtonText,
			openCalculateModal,
			openAddCommunicationModal,
			openInformationModal,
			extraDetailsPopover,
			toggleExtraDetails,
			activeKey,
		]
	);

	return (
		<div className={styles['calculate-statutory']}>
			<p>Calculate Statutory Components</p>

			<div className="calculate-statutory">
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={changeTab}
					style={{
						fontSize: '18px',
						marginBottom: '40px',
					}}
					tabBarExtraContent={{
						right: globalHeaderMemo,
					}}
				>
					<TabPane key="napsa" tab="NAPSA">
						{activeKey === 'napsa' && (
							<NapsaCalculateComponent
								calculateModalOpen={calculateModalOpen}
								addCommunicationModal={addCommunicationModal}
								informationModal={informationModal}
								closeAllModals={closeAllModals}
							/>
						)}
					</TabPane>
					<TabPane key="nhima" tab="NHIMA">
						{activeKey === 'nhima' && (
							<NhimaCalculateComponent
								calculateModalOpen={calculateModalOpen}
								addCommunicationModal={addCommunicationModal}
								informationModal={informationModal}
								closeAllModals={closeAllModals}
							/>
						)}
					</TabPane>
					<TabPane key="paye" tab="PAYE">
						{activeKey === 'paye' && (
							<PayeCalculateComponent
								calculateModalOpen={calculateModalOpen}
								addCommunicationModal={addCommunicationModal}
								informationModal={informationModal}
								closeAllModals={closeAllModals}
							/>
						)}
					</TabPane>
					<TabPane
						key="worker-cpmpensation"
						tab="Workers Compensation"
					>
						{activeKey === 'worker-cpmpensation' && (
							<CompensationCalculateComponent
								calculateModalOpen={calculateModalOpen}
								closeAllModals={closeAllModals}
							/>
						)}
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default React.memo(CalculateStatutory);
