import { getApi, postApi } from 'apis';

const getAllMidMonthPay = async (params: any) => {
	return await getApi('/midMonthPayConfig', params);
};
const getMostRecentEntry = async (params: any) => {
	return await getApi('/midMonthPayConfig/mostRecent', params);
};

const createMidMonthPay = async (data: any) => {
	return await postApi('/midMonthPayConfig', data);
};

export const midMonthPayConfigApi = {
	getAllMidMonthPay,
	getMostRecentEntry,
	createMidMonthPay,
};
