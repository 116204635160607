import { Card, Col, Row, Typography } from 'antd';
import {
	UserOutlined,
	CalendarOutlined,
	IdcardOutlined,
} from '@ant-design/icons';
import React from 'react';
import styles from './index.module.scss';

const { Title, Text } = Typography;

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	employeeId: string;
	employeeData: any;
	getEmployeeDetail: (employeeId: string) => void;
	fetchEmployees: any;
};

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};

const EmployeeDetails = ({ employeeData }: Props) => {
	const maskSSN = (ssn: string) => {
		if (!ssn || ssn.length < 9) return ssn || '-';
		return `*****${ssn.slice(-4)}`;
	};

	return (
		<div className={styles['scrollable-content']}>
			<Row gutter={[16, 24]}>
				{/* Personal Details */}
				<Col span={24}>
					<Card
						title={
							<Title level={4} className={styles['card-title']}>
								<UserOutlined /> Personal Details
							</Title>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>First Name :</b>{' '}
									{employeeData.firstName || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Last Name :</b>{' '}
									{employeeData.lastName || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Date of Birth :</b>{' '}
									{employeeData.dateOfBirth
										? formatDate(employeeData.dateOfBirth)
										: '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Gender :</b> {employeeData.gender || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Country :</b>{' '}
									{employeeData.country || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Marital Status :</b>{' '}
									{employeeData.maritalStatus || '-'}
								</Text>
							</Col>
						</Row>
					</Card>
				</Col>

				{/* Employee Details */}
				<Col span={24}>
					<Card
						title={
							<Title level={4} className={styles['card-title']}>
								<CalendarOutlined /> Employee Details
							</Title>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Joining Date :</b>{' '}
									{employeeData.joiningDate
										? formatDate(employeeData.joiningDate)
										: '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Branch :</b>{' '}
									{employeeData.branch?.name || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Department :</b>{' '}
									{employeeData.department?.name || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Cost Center :</b>{' '}
									{employeeData.costCenter?.name || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Category :</b>{' '}
									{employeeData.category?.name || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Job Grade :</b>{' '}
									{employeeData.jobGrade?.position || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Staff Code :</b>{' '}
									{employeeData.staffCode || '-'}
								</Text>
							</Col>
						</Row>
					</Card>
				</Col>

				{/* Identification Information */}
				<Col span={24}>
					<Card
						title={
							<Title level={4} className={styles['card-title']}>
								<IdcardOutlined /> Identification Information
							</Title>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>SSN :</b> {maskSSN(employeeData.ssn)}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Record Status :</b>{' '}
									{employeeData.recordStatus || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>Passport No :</b>{' '}
									{employeeData.passportNo || '-'}
								</Text>
							</Col>
							<Col xs={24} sm={12}>
								<Text className={styles['card-text']}>
									<b>National Registration No :</b>{' '}
									{employeeData.nationalRegistrationNo || '-'}
								</Text>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default EmployeeDetails;
