import { holidayApi } from 'Api/masters/holiday';
import { shiftsApi } from 'Api/shifts';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import useDebounce from 'components/Global/Hooks/UseDebounce';
import TableActionHeader from 'components/Global/TableActionHeader';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { checkPermission, toastText } from 'utils/utils';
import CreateShiftModal from './CreateShiftModal';
import ShiftHeader from './Header';
import ShiftTable from './ShiftTable';
import styles from './index.module.scss';
import { SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type Props = {};

function ShiftsComponent(props: Props) {
	const permissionContext = useContext(PermissionContext);

	const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState(false);
	const [isViewShiftModalOpen, setIsViewShiftModalOpen] = useState(false);
	const [editShift, setEditShift] = useState<any>(null);
	const [shiftData, setShiftData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isShiftDeleteModalOpen, setIsShiftDeleteModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isUserSupervisor, setIsUserSupervisor] = useState(false);
	const debouncedSearchValue = useDebounce(searchValue, 500);

	const buttons = [
		{
			text: 'Add Shift',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsCreateShiftModalOpen(true);
			},
			disabled: isLoading,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const closeCreateShiftModal = () => {
		setIsCreateShiftModalOpen(false);
		setIsViewShiftModalOpen(false);
		setEditShift(null);
	};

	const openCreateShiftModal = () => {
		setIsCreateShiftModalOpen(true);
	};
	const openViewShiftModal = () => {
		setIsViewShiftModalOpen(true);
	};
	const isViewPermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Time Shift',
		permission: ['view'],
	});

	const isAddPermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Time Shift',
		permission: ['add'],
	});

	const fetchShifts = async () => {
		try {
			setIsLoading(true);
			const query = {
				page: currentPage,
				limit: pageSize,
				sortBy: sortField,
				sortOrder: sortOrder,
				search: debouncedSearchValue,
			};
			const response = await shiftsApi.getShifts(query);
			setShiftData(response.data.data);
			setIsUserSupervisor(response.data.isSupervisor);
			setTotalRecords(response.data.total);
		} catch (err: any) {
			const message =
				err?.response?.data?.message ||
				'Something went wrong in fetching shifts';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const deleteHandler = async () => {
		if (editShift) {
			try {
				setIsDeleteLoading(true);
				const apiRes = await shiftsApi.deleteShift(editShift.id);
				if (totalRecords > 1 && totalRecords % pageSize === 1) {
					setCurrentPage(currentPage - 1);
				}
				closeDeleteShiftModal();
				fetchShifts();
				const message =
					apiRes?.data?.message || 'Shift deleted successfully';
				toastText(message, 'success');
			} catch (error: any) {
				const errorMessage =
					error.response?.data?.message ||
					'Something went wrong in delete shift';
				toastText(errorMessage, 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const handleOk = () => {
		setIsShiftDeleteModalOpen(false);
		setEditShift(null);
	};

	const closeDeleteShiftModal = () => {
		setIsShiftDeleteModalOpen(false);
		setEditShift(null);
	};
	useEffect(() => {
		fetchShifts();
	}, [pageSize, currentPage, sortField, sortOrder, debouncedSearchValue]);

	return (
		<>
			<div
				style={{
					maxHeight: 'calc(100vh - 100px)',
				}}
			>
				<TableActionHeader title={'Employees'}>
					<div>
						{isAddPermission && (
							<div className={styles['shift-header__buttons']}>
								<Tooltip title="Refresh all the Shifts">
									<SyncOutlined
										style={{
											fontSize: '20px',
											cursor: isLoading
												? 'not-allowed'
												: 'pointer',
											color: '#584495',
											transition: 'transform 0.3s',
											marginRight: '10px',
										}}
										onClick={
											!isLoading ? fetchShifts : undefined
										}
									/>
								</Tooltip>
								<Buttons buttons={buttons} />
							</div>
						)}
					</div>
				</TableActionHeader>
				<div className={styles['shift-container']}>
					<ShiftHeader
						searchValue={searchValue}
						handleSearch={handleSearch}
						handleSearchApi={() => {}}
					/>
					{isViewPermission && (
						<ShiftTable
							tableData={shiftData}
							totalRecords={totalRecords}
							currentPage={currentPage}
							pageSize={pageSize}
							isLoading={isLoading}
							tableChangeHandler={tableChangeHandler}
							openCreateShiftModal={openCreateShiftModal}
							openViewShiftModal={openViewShiftModal}
							setEditShift={setEditShift}
							setIsShiftDeleteModalOpen={
								setIsShiftDeleteModalOpen
							}
							fetchShifts={fetchShifts}
							isUserSupervisor={isUserSupervisor}
						/>
					)}
				</div>
				<CreateShiftModal
					isModalOpen={isCreateShiftModalOpen || isViewShiftModalOpen}
					closeModal={closeCreateShiftModal}
					editShift={editShift}
					fetchShifts={fetchShifts}
					isViewOnly={isViewShiftModalOpen}
					isUserSupervisor={isUserSupervisor}
				/>
				<ConfirmDelete
					handleCancel={closeDeleteShiftModal}
					handleOk={handleOk}
					isModalOpen={isShiftDeleteModalOpen}
					deleteHandler={deleteHandler}
					isLoading={isDeleteLoading}
				/>
			</div>
		</>
	);
}

export default ShiftsComponent;
