import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { EmployeeLeaveImage } from 'utils/svgs';
import { formatToDateOnly, toastText } from 'utils/utils';
import GlobalHeader from '../GlobalHeader';
import EmployeeLeavesTable from './Table';
import styles from './index.module.scss';

type EmployeeLeavesProps = {
	payrollId: any;
	onlyView: boolean;
	headerData: any;
	setEmployeeLeaveData: (data: any) => void;
	EmployeeLeaveData: any;
	setIsUniversalLoading: any;
};
const EmployeeLeavesComponent = (props: EmployeeLeavesProps) => {
	// const [employeeData, setEmployeeData] = useState<any[]>([]);
	const [employeeLeaveLoading, setEmployeeLeaveLoading] = useState(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(30);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};
	const {
		payrollId,
		onlyView,
		headerData,
		setEmployeeLeaveData,
		EmployeeLeaveData,
		setIsUniversalLoading,
	} = props;

	const fetchEmployeeLeave = async (payrollId: string) => {
		try {
			const query = {
				payrollId: payrollId,
				page: page,
				pageSize: pageSize,
			};
			setEmployeeLeaveLoading(true);
			setIsUniversalLoading(true);
			let leaveRequest;
			if (onlyView) {
				leaveRequest = await viewPayrollApi.getAllEmployeeLeaves(query);
			} else {
				leaveRequest = await payrollApi.getEmployeesLeaves(query);
			}
			setEmployeeLeaveData(leaveRequest.data.data.data);
			setTotalRecords(leaveRequest.data.data.totalRecords);
			setStartDate(formatToDateOnly(leaveRequest.data.data.startDate));
			setEndDate(formatToDateOnly(leaveRequest.data.data.endDate));
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setEmployeeLeaveLoading(false);
			setIsUniversalLoading(false);
		}
	};

	useEffect(() => {
		if (payrollId) {
			fetchEmployeeLeave(payrollId);
		}
	}, [payrollId, page, pageSize]);

	const handleCheckboxChange = (id: string, value: boolean) => {
		const updatedData = EmployeeLeaveData.map((item: any) => {
			if (item.id === id) {
				return {
					...item,
					deductFromSalary: value,
				};
			}
			return item;
		});
		setEmployeeLeaveData(updatedData);
	};

	return (
		<>
			<Row>
				<Col span={16}>
					<div className={styles['employee-leaves__table']}>
						<GlobalHeader
							headerData={headerData}
							toggleRequired={false}
							isLoading={employeeLeaveLoading}
							fetchAllData={() => fetchEmployeeLeave(payrollId)}
						/>
						<EmployeeLeavesTable
							isLoading={employeeLeaveLoading}
							handleCheckboxChange={handleCheckboxChange}
							employeeData={EmployeeLeaveData}
							onlyView={onlyView}
							startDate={startDate}
							endDate={endDate}
							totalRecords={totalRecords}
							page={page}
							pageSize={pageSize}
							tableChangeHandler={tableChangeHandler}
						/>
					</div>
				</Col>
				<Col span={1}></Col>
				<Col span={7}>
					<div>
						<EmployeeLeaveImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default EmployeeLeavesComponent;
