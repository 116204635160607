import { timeSheetsApi } from 'Api/timeSheet';
import { Button, Col, Divider, Modal, Popover, Row, Select, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { getApiExcel } from 'apis';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import DateRangePickerField from 'components/Global/DateRangePicker';
import InputField from 'components/Global/InputField';
import dayjs from 'dayjs';
import { GenerateTimeSheetInterface } from 'interfaces/employee.interface';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { useSelector } from 'react-redux';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import Ellipse from 'components/Global/Ellipse';

type Props = {
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	options: any;
	button: any;
	dateValue: any;
	handleDatePicker: any;
	dropdownOptionsLoading: boolean;
	activeKey: string;
	handlePendingDate: (value: any) => void;
	pendingDateValue: any;
	shiftOptions: any;
	handleSelectedShift: (value: string) => void;
	selectedShift: string;
	isFetchShiftsLoading: boolean;
};

const TimeLogsTableHeader = (props: Props) => {
	const {
		statusFilterValue,
		handleStatusFilter,
		options,
		handleDatePicker,
		dateValue,
		dropdownOptionsLoading,
		activeKey,
		pendingDateValue,
		handlePendingDate,
		shiftOptions,
		handleSelectedShift,
		selectedShift,
		isFetchShiftsLoading,
	} = props;

	const permissions = useContext(PermissionContext);
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isCreateLoading, setIsCreateLoading] = useState(false);
	const [isExportLoading, setIsExportLoading] = useState(false);
	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');
	const [payPeriodError, setPayPeriodError] = useState(false);

	const { data: payPeriodData } = useSelector(
		(state: any) => state.payPeriod
	);

	const [formError, setFormError] = useState<any>({
		timeSheetName: false,
	});
	const [generateTimeSheetDetails, setGenerateTimeSheetDetails] =
		useState<any>({
			timeSheetName: '',
		});

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isCreateLoading,
			onclick: () => {
				handleCancel();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Generate',
			isLoading: isCreateLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	const handleSelectYear = (value: any) => {
		setSelectedPayPeriod('');
		setSelectedYear(value);
	};

	const showModal = () => {
		setIsModalOpen(true);
		setIsOpen(false);
	};

	const handleCancel = () => {
		setSelectedPayPeriod('');
		setSelectedYear(dayjs());
		setPayPeriodError(false);
		setIsModalOpen(false);
	};

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string
	) => {
		setGenerateTimeSheetDetails((prev: any) => {
			const newDetails = {
				...prev,
				[key]: value,
			};
			return newDetails;
		});

		// Validate the form after updating
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const createTimeSheet = async () => {
		try {
			setIsCreateLoading(true);
			const timeSheet = await timeSheetsApi.createTimeSheets({
				// fromDate: dayjs(generateTimeSheetDetails.fromDate).format(
				// 	'YYYY-MM-DD'
				// ),
				// toDate: dayjs(generateTimeSheetDetails.toDate).format(
				// 	'YYYY-MM-DD'
				// ),
				timeSheetName: generateTimeSheetDetails.timeSheetName,
				year: selectedYear.year(),
				payPeriodId: selectedPayPeriod,
			});
			toastText(timeSheet.data.message, 'success');

			setIsModalOpen(false);
			navigate('/time-sheets');
		} catch (err: any) {
			let message = 'Something went wrong in creating time sheet';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsCreateLoading(false);
		}
	};

	const handleExportExcel = async () => {
		try {
			setIsExportLoading(true);
			const startDate = dateValue[0].format('YYYY-MM-DD');
			const endDate = dateValue[1].format('YYYY-MM-DD');
			// const month = dateValue.month() + 1;
			// const year = dateValue.year();
			const query = {
				startDate,
				endDate,
			};

			const response: any = await getApiExcel(
				'/timeActivities/export',
				query
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_timelogs.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const excelUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = excelUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			// toastText(response.data, 'success');
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting excel report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...generateTimeSheetDetails,
			},
			{ ...formError }
		);
		checkFormError = {
			...checkFormError,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError) || invalidText(selectedPayPeriod)) {
			if (invalidText(selectedPayPeriod)) {
				setPayPeriodError(true);
			}
		} else {
			createTimeSheet();
		}
	};

	const handlePayPeriodChange = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			setSelectedPayPeriod(value);
			return;
		}

		setSelectedPayPeriod(value);
		setPayPeriodError(false);

		const payPeriod = payPeriodData.find(
			(payPeriod: any) => payPeriod.value === value
		);

		if (payPeriod) {
			const formattedStartDate = dayjs(payPeriod.startDate).format(
				'DD/MM/YYYY'
			);
			const formattedEndDate = dayjs(payPeriod.endDate).format(
				'DD/MM/YYYY'
			);
			const timesheetName = `(${formattedStartDate} - ${formattedEndDate})`;

			setGenerateTimeSheetDetails((prev: any) => ({
				...prev,
				timeSheetName: timesheetName,
			}));
		}
	};

	const handleYearChange = (value: any) => {
		setSelectedYear(value);
		// (prev: any) => ({
		// 	...prev,
		// 	year: value,
		// });
		setSelectedPayPeriod('');
	};

	useEffect(() => {
		if (isModalOpen) {
			setGenerateTimeSheetDetails({
				// fromDate: '',
				// toDate: '',
				timeSheetName: '',
			});
		}
	}, [isModalOpen]);

	return (
		<div className={styles['employee-header']}>
			<Space>
				{activeKey === 'missingLogs' && (
					<>
						<DateRangePickerField
							name={'dateFilter'}
							value={dateValue}
							required={false}
							picker="month"
							isError={false}
							onChange={handleDatePicker}
						/>
					</>
				)}

				{options?.length > 0 &&
					activeKey !== 'pendingTimeLogs' &&
					activeKey !== 'missingLogs' && (
						<Select
							className={styles['employee-header-item']}
							value={statusFilterValue}
							options={options}
							onChange={(value) => {
								handleStatusFilter(value);
							}}
							size="large"
							placeholder="Select Status"
							loading={dropdownOptionsLoading}
							disabled={dropdownOptionsLoading}
						/>
					)}
				{activeKey === 'pendingTimeLogs' ? (
					<>
						<DatePickerField
							name={'date'}
							value={pendingDateValue}
							required={false}
							isError={false}
							onChange={handlePendingDate}
							picker="month"
						/>
						<Select
							className={styles['employee-header-item']}
							value={selectedShift}
							onChange={(value) => {
								handleSelectedShift(value);
							}}
							size="large"
							placeholder="Select Shift"
							loading={isFetchShiftsLoading}
							disabled={isFetchShiftsLoading}
							// options={shiftOptions}
							options={shiftOptions.map((item: any) => ({
								value: item.value,
								label: (
									<Ellipse
										maxLength={50}
										message={item.label}
										isTooltip={true}
										tooltipMessage={item.label}
										key={item.label}
									/>
								),
							}))}
						/>
					</>
				) : (
					activeKey !== 'missingLogs' && (
						<DateRangePickerField
							name={'dateFilter'}
							value={dateValue}
							required={false}
							picker="month"
							isError={false}
							onChange={handleDatePicker}
						/>
					)
				)}
			</Space>
			<Space className="timesheet-buttons">
				<Popover
					content={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Button
								type="text"
								onClick={handleExportExcel}
								loading={isExportLoading}
								style={{ textAlign: 'left', display: 'block' }}
							>
								Export To Excel
							</Button>
							<Divider style={{ margin: '8px 0' }} />
							{(permissions.allowedPermissions.includes(
								'Add_Time_Sheet'
							) ||
								permissions.allowedPermissions.includes(
									'Edit_Time_Sheet'
								)) && (
								<Button type="text" onClick={showModal}>
									Generate TimeSheet
								</Button>
							)}
						</div>
					}
					trigger="click"
					open={isOpen}
					onOpenChange={() => setIsOpen((prev) => !prev)}
					placement="bottomRight"
				>
					{activeKey === 'timeLogs' && (
						<Button type="default" size="large">
							<strong> ...</strong>
						</Button>
					)}
				</Popover>
			</Space>
			{isModalOpen && (
				<Modal
					open={isModalOpen}
					// onOk={handleSubmit}
					onCancel={handleCancel}
					okText={'Save'}
					closable={false}
					footer={null}
					className="document__popup"
					width="600px"
				>
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span className="modal-title">
									Generate TimeSheet
								</span>
							</Title>
							<div
								className={styles['modal-header-filter']}
							></div>
						</div>
						<div>
							<Row
								className={styles['form-container-card']}
								gutter={50}
							>
								<GlobalPayPeriod
									selectedYear={selectedYear}
									handleSelectedYear={(value) => {
										handleYearChange(value);
									}}
									selectedPayPeriod={selectedPayPeriod}
									handlePayPeriod={(value) =>
										handlePayPeriodChange(value)
									}
									colSpan={24}
									rowStyle={{
										margin: '0 0 1rem 0',
									}}
									colStyle={{ marginBottom: '2rem' }}
									isRow={true}
									isYearRequired={true}
									isPayPeriodRequired={true}
									yearError={false}
									payPeriodError={payPeriodError}
								/>
								<Col span={24}>
									<InputField
										name="timeSheetName"
										value={
											generateTimeSheetDetails.timeSheetName
										}
										label="Timesheet Name"
										required={false}
										// helperText="Timesheet name required"
										onChange={(value) => {
											handleChange(
												value,
												'timeSheetName',
												true
											);
										}}
										isError={false}
										disabled={isDisabled}
									/>
								</Col>
							</Row>
							<Row className={styles['modal-buttons']}>
								<Buttons buttons={buttons} />
							</Row>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default TimeLogsTableHeader;
