import { CloseOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { getApi, getApiExcel } from 'apis';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedTimeSheet: any;
	// selectedShift: string;
	// selectedDate: string;
};

const AddModal = (props: Props) => {
	const { isOpen, handleCancel, selectedTimeSheet } = props;
	const [timeActivities, setTimeActivities] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [isExportLoading, setIsExportLoading] = useState(false);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const fetchTimeActivities = async () => {
		const query = {
			timeSheetId: selectedTimeSheet.id,
			page: currentPage,
			pageSize,
		};
		setIsLoading(true);
		try {
			const response = await getApi(
				`/timeActivities/get-time-activities`,
				query
			);
			setTimeActivities(response.data.timeActivities);
			setTotalRecords(response.data.totalRecords);
		} catch (error) {
			console.error('Error fetching Monthly Summary:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const exportPdf = async () => {
		try {
			const startDate = moment(selectedTimeSheet.fromDate).format(
				'YYYY-MM-DD'
			);
			const endDate = moment(selectedTimeSheet.toDate).format(
				'YYYY-MM-DD'
			);

			const query = {
				startDate,
				endDate,
				timeSheetId: selectedTimeSheet.id,
			};

			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/timeActivities/export/pdf',
				query
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`-timesheet.pdf`;

			const pdfBlob = new Blob([response.data], {
				type: 'application/pdf',
			});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting pdf report',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	useEffect(() => {
		if (selectedTimeSheet) {
			fetchTimeActivities();
		}
	}, [selectedTimeSheet, currentPage, pageSize]);

	return (
		<Modal
			open={isOpen}
			style={{
				minWidth: '1200px',
				zIndex: '99999',
			}}
			closable={false}
			onCancel={handleCancel}
			footer={
				<div className={styles['modal-footer']}>
					<Button
						type="primary"
						onClick={exportPdf}
						loading={isExportLoading}
						disabled={isLoading}
					>
						Export PDF
					</Button>
				</div>
			}
			width={800}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Typography.Title level={4}>
						<span>
							<ScheduleOutlined
								style={{
									fontSize: '20px',
									marginRight: '10px',
								}}
							/>
							Time Sheets {selectedTimeSheet.timeSheetName}
						</span>
					</Typography.Title>
					<Button
						//className={styles['close-button']}
						icon={<CloseOutlined />}
						onClick={handleCancel}
					/>
				</div>
				<Spin spinning={isLoading}>
					<Table
						dataSource={timeActivities}
						scroll={{ y: 'calc(100vh - 360px)' }}
						rowKey="id"
						pagination={{
							total: totalRecords,
							current: currentPage,
							pageSize: pageSize,
							showSizeChanger: false,
							// onShowSizeChange: pageSizeHandler,
							// pageSizeOptions: [10, 20, 50, 100, 200],
						}}
						onChange={tableChangeHandler}
					>
						<Column
							title="Employee Code"
							dataIndex="optionalEmployeeId"
							key="optionalEmployeeId"
							className="bg-white"
						/>
						<Column
							title="Employee Name"
							key="employeeName"
							className="bg-white"
							render={(text, record: any) =>
								`${record.firstName} ${record.lastName}`
							}
						/>
						<Column
							title="Regular Hours"
							dataIndex="totalRegularHours"
							key="totalRegularHours"
							className="bg-white"
						/>
						<Column
							title="Overtime Hours"
							dataIndex="totalOvertimeHours"
							key="totalOvertimeHours"
							className="bg-white"
						/>
						<Column
							title="Total Hours"
							dataIndex="totalHours"
							key="totalHours"
							className="bg-white"
						/>
						<Column
							title="Shift Time Difference"
							dataIndex="totalTimeShiftHours"
							key="totalTimeShiftHours"
							className="bg-white"
						/>
					</Table>
				</Spin>
			</div>
		</Modal>
	);
};

export default AddModal;
