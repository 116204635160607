import { payrollApi } from 'Api/payroll';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import PayrollHeader from './Header';
import PayrollTable from './Table';
import styles from './index.module.scss';
import useDebounce from 'components/Global/Hooks/UseDebounce';

const PayrollOverview = () => {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState('');
	const [currency, setcurrency] = useState('');
	const [statusFilterValue, setStatusFilterValue] = useState('');
	const [yearFilterValue, setYearFilterValue] = useState(dayjs());
	const [payPeriodFilterValue, setPayPeriodFilterValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [sortOrder, setSortOrder] = useState('');
	const [totalRecords, setTotalRecords] = useState(0);
	const [payrollData, setPayrollData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [deletePayrollId, setDeletePayrollId] = useState<string | null>(null);
	// const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const debounceSearch = useDebounce(searchValue, 500);

	const handleCurrency = (value: string) => {
		setcurrency(value);
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const handleStatusFilter = (value: string) => {
		setStatusFilterValue(value);
	};

	const handleYear = (value: any) => {
		setYearFilterValue(value);
	};

	const handlePayPeriod = (value: string) => {
		setPayPeriodFilterValue(value);
	};

	const handleSearchApi = async (value: string) => {
		//search api
	};

	// const syncBankResponse = async () => {
	// 	try {
	// 		const response = await payrollApi.syncBankResponse();
	// 		setIsResponseLoading(true);
	// 		console.log('Response: ', response);
	// 		if (response.data.status === 'success') {
	// 			toastText('Bank files synced successfully', 'success');
	// 		} else {
	// 			toastText(
	// 				'Something went wrong in sync bank files request',
	// 				'error'
	// 			);
	// 		}
	// 	} catch (err) {
	// 		setIsResponseLoading(false);
	// 		toastText(
	// 			'Something went wrong in sync bank files request',
	// 			'error'
	// 		);
	// 	}
	// };

	const buttons = [
		{
			text: 'Run Payroll',
			isLoading: false,
			className: 'btn-blue',
			minWidth: '70px',
			isSubmit: true,
			onclick: () => {
				navigate('/run-payroll');
			},
			disabled: false,
		},
	];

	const fetchAllPayrolls = async () => {
		try {
			const query = {
				page: currentPage,
				search: debounceSearch,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				status: statusFilterValue,
				payPeriodId: payPeriodFilterValue,
				payYear: yearFilterValue.format('YYYY'),
				payCurrency: currency,
			};
			setIsLoading(true);
			const respayrollData = await payrollApi.payrollOverview(query);
			setPayrollData(respayrollData.data.data.data);
			setTotalRecords(respayrollData.data.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching payrolls request';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
		///	setDeletePayrollId(null);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		setDeletePayrollId(null);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setDeletePayrollId(null);
	};

	const deleteHandler = async () => {
		if (deletePayrollId) {
			try {
				setIsDeleteLoading(true);
				await payrollApi.deletePayroll(deletePayrollId);
				handleCancel();
				fetchAllPayrolls();
				toastText('Payroll deleted successfully', 'success');
			} catch (error) {
				toastText('Something went wrong in delete payroll', 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	useEffect(() => {
		fetchAllPayrolls();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentPage,
		pageSize,
		debounceSearch,
		statusFilterValue,
		currency,
		sortOrder,
		sortField,
		yearFilterValue,
		payPeriodFilterValue,
	]);

	return (
		<>
			<PayrollHeader
				searchText={searchValue}
				handleSearch={handleSearch}
				handleSearchApi={handleSearchApi}
				StatusFilter={statusFilterValue}
				handleStatusFilter={handleStatusFilter}
				YearFilterValue={yearFilterValue}
				handleYear={handleYear}
				PayPeriodFilterValue={payPeriodFilterValue}
				handlePayPeriod={handlePayPeriod}
				currencyValue={currency}
				handleCurrency={handleCurrency}
				button={buttons}
				fetchAllPayrolls={fetchAllPayrolls}
				isLoading={isLoading}
			/>
			<div className={styles['overview-container']}>
				<PayrollTable
					tableData={payrollData}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					showDeleteModal={showModal}
					setDeletePayroll={setDeletePayrollId}
					fetchAllPayrolls={fetchAllPayrolls}
					// fetchRecords={fetchAllPayrolls}
				/>
			</div>
			<ConfirmDelete
				isModalOpen={isModalOpen}
				handleCancel={handleCancel}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
				handleOk={handleOk}
			/>
		</>
	);
};

export default PayrollOverview;
