import { CloseOutlined } from '@ant-design/icons';
import { Col, Empty, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { nhimaApi } from 'Api/nhima';
import Buttons from 'components/Global/Buttons';
import CheckBox from 'components/Global/Checkbox';
import InputNumberField from 'components/Global/InputNumberField';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { invalidText, toastText } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';

type CalculateNhimaModalProps = {
	open: boolean;
	cancelCalculateModal: () => void;
	fetchNhimaReportDetails: () => void;
};
const CalculateNhimaModal = (props: CalculateNhimaModalProps) => {
	const { open, cancelCalculateModal, fetchNhimaReportDetails } = props;
	const [loading, setLoading] = useState(false);
	// const [selectAll, setSelectAll] = useState(false);

	const [currencyFields, setCurrencyFields] = useState<any>([]);
	const [currencyError, setCurrencyError] = useState<Boolean>(false);
	const [payPeriodError, setPayPeriodError] = useState<any>(false);
	const [selectedPayPeriod, setSelectedPayPeriod] = useState<string>('');
	const [selectedYear, setSelectedYear] = useState<any>(dayjs());

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const currencyOptions = constantDropdownOptions?.currencyData;

	const [formError, setFormError] = useState<any>({
		year: false,
		month: false,
	});

	const [hasError, setHasError] = useState(false);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: cancelCalculateModal,
			minWidth: '10rem',
		},
		{
			text: 'Calculate',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: selectedPayPeriod ? false : true,
			onclick: handleSubmit,
			minWidth: '10rem',
		},
	];

	const handleCancelModal = () => {
		setSelectedPayPeriod('');
		setSelectedYear(dayjs());
		setPayPeriodError(false);
		setFormError({
			year: false,
			month: false,
			currencyFields: false,
		});
		setHasError(false);
		setCurrencyFields([]);
		setCurrencyError(false);
		cancelCalculateModal();
	};

	const handleCheckboxChange = (id: number, checked: boolean) => {
		const updatedData = currencyFields.map((item: any) =>
			item.id === id
				? {
						...item,
						conversionRate: checked ? item.conversionRate : null,
						checked: checked,
				  }
				: item
		);

		const CheckCurrencyError = currencyFields.some(
			(item: any) => item.isChecked
		);

		setCurrencyError(CheckCurrencyError);

		setCurrencyFields(updatedData);
	};

	const handleCurrencyChange = (id: number, value: number) => {
		const updatedData = currencyFields.map((item: any) =>
			item.id === id
				? { ...item, conversionRate: value, error: false }
				: item
		);

		setCurrencyFields(updatedData);
	};

	async function handleSubmit() {
		if (invalidText(selectedPayPeriod)) {
			setPayPeriodError(true);
			return;
		}

		const currencyErrors = currencyFields.map((item: any) => {
			if (item.isBaseCurrency) {
				return item;
			}
			if (
				item.checked &&
				(!item.conversionRate || item.conversionRate <= 0)
			) {
				item.error = true;
			} else {
				item.error = false;
			}
			return item;
		});

		setCurrencyFields(currencyErrors);

		const CheckCurrencyError = currencyFields.some(
			(item: any) => item.checked
		);

		setCurrencyError(!CheckCurrencyError);

		if (currencyFields.length === 0) {
			return;
		}

		if (currencyFields.some((item: any) => item.error)) {
			return;
		}

		try {
			setLoading(true);

			const currencyArray: any[] = [];

			currencyFields.forEach((item: any) => {
				if (item.checked) {
					currencyArray.push({
						currencyId: item.id,
						currencyRate: item.conversionRate || 1,
						currencyType: item.label,
					});
				}
			});

			const data = {
				payPeriodId: selectedPayPeriod,
				currencies: currencyArray,
			};
			await nhimaApi.calculateNhima(data);
			toastText('NHIMA calculated Successfully', 'success');
			fetchNhimaReportDetails();
			handleCancelModal();
		} catch (error: any) {
			console.error('error: ', error);
			if (error.response?.data?.error?.code !== 1005) {
				toastText(
					'Something went wrong calculating NHIMA report',
					'error'
				);
			} else {
				toastText(error.response?.data?.message, 'error');
			}
		} finally {
			setLoading(false);
		}
	}

	const handleSelectYear = (value: any) => {
		setSelectedPayPeriod('');
		setSelectedYear(value);
	};
	const handlePayPeriodChange = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			setSelectedPayPeriod(value);
			return;
		}

		setSelectedPayPeriod(value);
		setPayPeriodError(false);
	};

	useEffect(() => {
		if (open) {
			setSelectedPayPeriod('');
			setSelectedYear(dayjs());
			setFormError({
				year: false,
				month: false,
				currencyFields: false,
			});
			setHasError(false);
		}
	}, [open]);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		if (currencyOptions?.length > 0 && currencyFields.length === 0) {
			const baseCurrency = currencyOptions.find(
				(currency: any) => currency?.isBaseCurrency
			);
			const remainingCurrencies = currencyOptions.filter(
				(currency: any) => !currency?.isBaseCurrency && currency?.value
			);
			const allCurrencies = [baseCurrency, ...remainingCurrencies];
			const initialCurrencyFields = allCurrencies
				.filter((currency: any) => currency?.value)
				.map((currency: any) => {
					const baseField = {
						id: currency.value,
						label: currency.label,
						conversionRate: currency.isBaseCurrency ? 1 : null,
						checked: currency.isBaseCurrency,
						disabled: currency.isBaseCurrency,
						isBaseCurrency: currency.isBaseCurrency,
					};
					if (!currency.isBaseCurrency) {
						return {
							...baseField,
							error: false,
						};
					}

					return baseField;
				});

			setCurrencyFields(initialCurrencyFields);
		}
	}, [currencyOptions, currencyFields.length]);

	return (
		<>
			<Modal
				open={open}
				width={500}
				maskClosable={true}
				closable={false}
				onCancel={handleCancelModal}
				footer={null}
				style={{ position: 'absolute', top: 150, right: 50 }}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>NHIMA</Title>
						<div
							className={styles['close-icon']}
							onClick={handleCancelModal}
						>
							<CloseOutlined />
						</div>
					</div>
					<div>
						<Row
							className={styles['form-container-card']}
							gutter={50}
						>
							<GlobalPayPeriod
								handlePayPeriod={(value) =>
									handlePayPeriodChange(value)
								}
								selectedPayPeriod={selectedPayPeriod}
								isRow={true}
								handleSelectedYear={(value) =>
									handleSelectYear(value)
								}
								isYearRequired={true}
								isPayPeriodRequired={true}
								colSpan={24}
								colStyle={{ marginBottom: '1rem' }}
								payPeriodError={payPeriodError}
								selectedYear={selectedYear}
								rowStyle={{ margin: '0 0 1rem 0' }}
							/>

							<Col span={24} className={styles['col']}>
								<div className={styles['form-group']}>
									<label className={styles['form-label']}>
										Currency conversion rate{' '}
										<span className={styles['required']}>
											*{' '}
										</span>
									</label>
								</div>

								{currencyFields.length === 0 && (
									<>
										<Empty />
									</>
								)}
								{currencyFields?.map((item: any) => {
									if (item.id === '') {
										return <></>;
									} else if (item.isBaseCurrency) {
										return (
											<>
												<div
													className={
														styles['currency-group']
													}
												>
													<div
														className={
															styles[
																'currency-group_checkbox'
															]
														}
													>
														<CheckBox
															disabled
															checked={
																item.checked
															}
															label={item.label}
															onChange={(
																checked
															) =>
																handleCheckboxChange(
																	item.id,
																	checked
																)
															}
														/>
													</div>
												</div>
											</>
										);
									} else {
										return (
											<div
												className={
													styles['currency-group']
												}
											>
												<div
													className={
														styles[
															'currency-group_checkbox'
														]
													}
												>
													<CheckBox
														checked={item.checked}
														label={item.label}
														onChange={(checked) =>
															handleCheckboxChange(
																item.id,
																checked
															)
														}
													/>
												</div>
												<div>
													<InputNumberField
														value={
															item.conversionRate
														}
														label=""
														required
														placeholder="Enter conversion rate"
														name={`currency-${item.id}`}
														size="large"
														style={{
															width: '126%',
														}}
														isError={item.error}
														onChange={(
															value: any
														) =>
															handleCurrencyChange(
																item.id,
																value
															)
														}
														helperText="Conversion rate is required"
													/>
												</div>
											</div>
										);
									}
								})}
							</Col>
							{currencyError && (
								<Col span={24} className={styles['col']}>
									<p className={styles['error-text']}>
										Please select One Currency
									</p>
								</Col>
							)}
						</Row>

						<Row className={styles['modal-buttons']}>
							<Buttons buttons={buttons} />
						</Row>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CalculateNhimaModal;
