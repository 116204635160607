import { payrollApi } from 'Api/payroll';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalizeWords, formatNumber, toastText } from 'utils/utils';
import './index.scss';
import { viewPayrollApi } from 'Api/payrollView';
import GlobalHeader from '../../GlobalHeader';

type Props = {
	selectedPayrollId: string;
	setEarningJSON: any;
	onlyView: boolean;
	setIsUniversalLoading: any;
	headerData: any;
};

const TotalEarningTable: React.FC<Props> = (props: Props) => {
	const {
		selectedPayrollId,
		setEarningJSON,
		onlyView,
		setIsUniversalLoading,
		headerData,
	} = props;

	const [earningsData, setEarningsData] = useState<any[]>([]);
	const [isEarningsLoading, setEarningsLoading] = useState(false);
	const [columns, setColumns] = useState<any>([]);
	const [rowData, setRowData] = useState<any[]>([]);
	const { data } = useSelector((state: any) => state?.userProfile);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(31);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const formatData = (rows: any) => {
		const formattedRowData = rows?.map((item: any) => {
			const formattedItem: any = {};

			for (const key in item) {
				if (key == 'Employee') {
					formattedItem[key] = item[key];
				} else if (!usdCurrencyToggle && isUsdCurrency) {
					formattedItem[key] = formatNumber(
						Number(item[key]),
						data?.CompanyCurrencies,
						true
					);
				} else {
					formattedItem[key] = formatNumber(
						Number(item[key] * (conversionRate ?? 1)),
						data?.CompanyCurrencies
					);
				}
			}

			return formattedItem;
		});
		setEarningsData(formattedRowData);
	};

	const generateColumns = (columnsData: any) => {
		const groupedColumns = columnsData.reduce((acc: any, column: any) => {
			const { parent, title } = column;
			if (!acc[parent]) {
				acc[parent] = [];
			}
			acc[parent].push({
				id: Math.random(),
				title: capitalizeWords(title),
				dataIndex: title,
				key: title,
				width: '150px',
			});
			return acc;
		}, {});

		for (const key in groupedColumns) {
			const children = groupedColumns[key];
			children.forEach((child: any, index: number) => {
				child.className = `children-normal-font-weight ${
					index !== children.length - 1 ? 'border-right-none' : ''
				}`;
			});
		}

		// Convert grouped data into the final format
		const convertedColumns = Object.keys(groupedColumns).map((parent) => ({
			title: capitalizeWords(parent),
			className: 'background-color-dark',
			children: groupedColumns[parent],
		}));

		// Adding additional columns as needed
		convertedColumns.unshift({
			title: '',
			className: 'background-color-dark',
			children: [
				{
					title: 'Employees',
					dataIndex: 'Employee',
					key: 'Employee',
					fixed: 'left',
					width: 250,
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>{record.id === 'total' ? text : `${text}`}</span>
					),
				},
			],
		});

		convertedColumns.push({
			title: 'Total',
			className: 'background-color-dark',
			children: [
				{
					title: '',
					dataIndex: 'total',
					key: 'total',
					width: '150px',
				},
			],
		});

		return convertedColumns;
	};

	const fetchEarnings = async (payRollId: string) => {
		try {
			let response;
			setEarningsLoading(true);

			setIsUniversalLoading(true);
			if (onlyView) {
				response = await viewPayrollApi.getAllEmployeeEarnings({
					payrollId: payRollId,
					page: page,
					pageSize: pageSize - 1,
				});
			} else {
				response = await payrollApi.getPayrollEarnings({
					payrollId: payRollId,
					page: page,
					pageSize: pageSize - 1,
				});
			}
			const { columns, rows } = response?.data?.data;
			const generatedColumns = generateColumns(columns);
			setEarningJSON(rows);
			setTotalRecords(response?.data?.data?.totalRecords);
			setRowData(rows);

			formatData(rows);

			setColumns(generatedColumns);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setEarningsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	useEffect(() => {
		if (selectedPayrollId) {
			fetchEarnings(selectedPayrollId);
		}
	}, [selectedPayrollId, page, pageSize]);

	useEffect(() => {
		formatData(rowData);
	}, [usdCurrencyToggle]);
	return (
		<>
			<div className="payroll-steps-table">
				<GlobalHeader
					headerData={headerData}
					toggleRequired={true}
					fetchAllData={() => fetchEarnings(selectedPayrollId)}
					isLoading={isEarningsLoading}
				/>
				<Table
					className="total-earning-table"
					columns={columns}
					dataSource={earningsData}
					rowClassName={(record, index) =>
						index === earningsData.length - 1 ? 'sticky-row' : ''
					}
					bordered
					scroll={{ y: 'calc(100vh - 510px)' }}
					pagination={{
						total: totalRecords,
						current: page,
						pageSize: pageSize,
						showSizeChanger: false,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					rowKey={(record: any) => record.id}
					loading={isEarningsLoading}
					onChange={tableChangeHandler}
				/>
			</div>
		</>
	);
};

export default TotalEarningTable;
