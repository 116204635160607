import { getApproveRequestApi } from 'Api/approveRequest';
import { payrollApi } from 'Api/payroll';
import { Divider, Modal } from 'antd';
import { Loader } from 'components/Global';
import Buttons from 'components/Global/Buttons';
import EmployeeShareComponent from 'components/Settings/PayrollSettings/StatutoryComponent/PayeTab/ToolTip';
import { useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import DownloadComponent from 'components/Global/Download';

type Props = {
	isNotificationModalOpen: boolean;
	handleCancel: () => void;
	approvalId: string | null;
	notificationId: string | null;
};

const attachmentTitle = ['Attachment', 'Combine Pay Slip'];

const AddPushNotificationModal = (props: Props) => {
	const [notificationData, setNotificationData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [displayData, setDisplayData] = useState<any[]>([]);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);
	const [modalTitle, setModalTitle] = useState('');

	const {
		isNotificationModalOpen,
		handleCancel,
		approvalId,
		notificationId,
	} = props;

	const buttons = [
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			// onclick: rejectNotification,
			minWidth: '10rem',
			minHeight: '42px',
			onclick: () => {
				notificationData[0]?.moduleName === 'PAYROLL'
					? rejectPayroll()
					: rejectApprovalRequest();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				notificationData[0]?.moduleName === 'PAYROLL'
					? approvePayroll()
					: acceptApprovalRequest();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			minWidth: '60px',
			minHeight: '42px',
			isLoading: approveIsLoading,
		},
	];

	async function approvePayroll() {
		if (!notificationData?.length) {
			toastText('Approval request not found', 'error');
			return;
		}
		try {
			setApproveIsLoading(true);
			await payrollApi.approvePayroll({
				approvalId: notificationData[0].ApprovalRequest[0].id,
				payrollId: notificationData[0].entityData.id,
			});
			toastText('Payroll approved successfully', 'success');
			handleCancel();
		} catch (error) {
			toastText('Something went wrong in approving payroll', 'error');
		} finally {
			setApproveIsLoading(false);
		}
	}

	async function rejectPayroll() {
		if (!notificationData?.length) {
			toastText('Approval request not found', 'error');
			return;
		}
		try {
			setRejectIsLoading(true);
			await payrollApi.rejectPayroll({
				approvalId: notificationData[0].ApprovalRequest[0].id,
				payrollId: notificationData[0].entityData.id,
			});
			toastText('Payroll rejected successfully', 'success');
			handleCancel();
		} catch (error) {
			toastText('Something went wrong in rejecting payroll', 'error');
		} finally {
			setRejectIsLoading(false);
		}
	}

	const acceptApprovalRequest = async () => {
		try {
			setApproveIsLoading(true);
			await getApproveRequestApi.acceptNotificationApproval({
				approvalId: approvalId,
				userNotificationId: notificationId,
			});
			toastText(`${modalTitle || 'Approval'} accepted!`, 'success');
			handleCancel();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in accepting approval';
			toastText(message, 'error');
		} finally {
			setApproveIsLoading(false);
		}
	};

	const rejectApprovalRequest = async () => {
		try {
			setRejectIsLoading(true);
			await getApproveRequestApi.rejectNotificationApproval({
				approvalId: approvalId,
				userNotificationId: notificationId,
			});
			toastText(`${modalTitle || 'Approval'} rejected!`, 'success');
			handleCancel();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in rejected approval';
			toastText(message, 'error');
		} finally {
			setRejectIsLoading(false);
		}
	};

	const fetchApprovalRequest = async () => {
		try {
			setIsLoading(true);
			const response =
				await getApproveRequestApi.getApprovalRequestsForNotification({
					approvalId: approvalId,
				});
			const _data = response.data.data;

			setNotificationData(_data.data);
			setDisplayData(_data.displayData);
			setModalTitle(_data.modalTitle);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isNotificationModalOpen) {
			fetchApprovalRequest();
		}
	}, [isNotificationModalOpen]);

	const renderNotificationData = () => {
		const titles = Object.keys(displayData);
		const content = Object.values(displayData);

		return (
			<div className={styles['notification-modal__list']}>
				{titles.map((title, index) => {
					const contentAtIndex = attachmentTitle.includes(title)
						? JSON.parse(content[index])
						: content[index];
					return (
						<div
							key={index}
							className={styles['notification-modal__list--item']}
						>
							<div
								style={{
									width: '30%',
									fontWeight: 'bold',
									fontSize: '14px',
								}}
							>
								{title}
							</div>
							{title === 'Paye Employee Share' ? (
								EmployeeShareComponent({
									employeeShare: content[index],
									placement: 'right',
								})
							) : attachmentTitle.includes(title) ? (
								<div>
									<DownloadComponent
										documentUrl={contentAtIndex?.fileUrl}
										downloadText={contentAtIndex?.fileName}
										showTooltip={true}
										text={contentAtIndex?.fileName}
										isLink={true}
										maxLength={40}
									/>
								</div>
							) : (
								<div>
									{' '}
									{invalidText(contentAtIndex)
										? '- '
										: contentAtIndex}{' '}
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<Modal
			open={isNotificationModalOpen}
			onCancel={handleCancel}
			okText="Save"
			closable={false}
			width={600}
			footer={null}
		>
			{isLoading ? (
				<div
					style={{
						minHeight: '150px',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Loader />
				</div>
			) : !notificationData?.length ? (
				<div className={styles['notification-modal--icon__text']}>
					No approval request present!
				</div>
			) : (
				<>
					<div className={styles['notification-modal']}>
						<h2 className={styles['notification-modal__title']}>
							{modalTitle}
						</h2>
						{!isLoading && (
							<Divider
								style={{
									margin: '1rem 0 1rem 0',
								}}
							/>
						)}
						{renderNotificationData()}
					</div>

					<div className={styles.footerButtons}>
						<Buttons buttons={buttons} />
					</div>
				</>
			)}
		</Modal>
	);
};

export default AddPushNotificationModal;
