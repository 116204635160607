import { deleteApiWithData, getApi, postApi } from 'apis';
import { SideDrawerWrapper } from 'components/Global';
import { PermissionContext } from 'components/Global/AuthLayout';
import TableActionHeader from 'components/Global/TableActionHeader';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { AddSvg } from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import AddUserBody from './AddUserBody';
import UserTable from './Table';
import styles from './index.module.scss';
import useDebounce from 'components/Global/Hooks/UseDebounce';

// UsersTable component
const UsersTable = () => {
	const permissions = useContext(PermissionContext);

	// State variables
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [roleOptions, setRoleOptions] = useState([]);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(
		Number(localStorage.getItem('userTableSize')) || 10
	);
	const [filter, setFilter] = useState('');
	const [sort, setSort] = useState('');
	const [type, setType] = useState('asc');
	const [drawerInfo, setDrawerInfo] = useState({
		drawerTitle: 'Users',
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editSelectedUser, setEditSelectedUser] = useState<any>();
	const [formateData, setFormateData] = useState<any>([]);
	const [sortDirection, setSortDirection] = useState('ascend');
	const [totalUsers, setTotalUsers] = useState(0);
	const [userData, setUserData] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const debouncedSearchValue = useDebounce(search, 500);
	const isAddPermission = checkPermission(permissions.permissions, {
		permissionName: 'Users',
		permission: ['add'],
	});

	// Dispatch
	const dispatch = useDispatch<AppDispatch>();

	// Location and Navigation
	const location = useLocation();
	const navigate = useNavigate();

	// Remove the side drawer
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	// Open side drawer with animation
	const openDrawerHandler = () => {
		setDrawerInfo({ drawerTitle: 'Add User' });
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	// Close the side drawer with animation
	const closeDrawerByAnimation = () => {
		setEditSelectedUser(undefined); //!isAddUserLoading &&
		setDrawerAnimation(false); //!isAddUserLoading &&
	};

	// Handle search operation
	const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setSearch(value);
		setPage(1);
	};

	// Handle sorting operation
	const performSortHandler = (type: string) => {
		if (type !== sortDirection) {
			setSortDirection(type);
			setType(type === 'ascend' ? 'asc' : 'desc');
			setPage(1);
		}
	};

	// Handle pagination for the table
	const paginationChangeHandler = (pageNo: number, pageSize: number) => {
		setPage(pageNo);
		setLimit(pageSize);
	};

	// Modify the page size
	const modifyPageSize = (current: number, size: number) => {
		if (!size) {
			setLimit(10);
		} else {
			setLimit(size);
			localStorage.setItem('userTableSize', size.toString());
		}
		setPage(1);
	};

	// Open the modal
	const showModal = () => {
		setIsModalOpen(true);
	};

	// Change data and title between components
	const setDrawerInfoHandler = (drawerTitle: any) => {
		setDrawerInfo({ drawerTitle });
	};

	// Confirm operation
	const handleOk = () => {
		setIsModalOpen(false);
		setEditSelectedUser(null);
	};

	// Cancel operation
	const handleCancel = () => {
		setIsModalOpen(false); // !isAddUserLoading &&
		setEditSelectedUser(null);
	};

	// Delete user
	const deleteHandler = async () => {
		if (editSelectedUser) {
			try {
				setIsDeleteLoading(true);
				await deleteApiWithData('/user', editSelectedUser);
				handleCancel();
				fetchUsers();
			} catch (error) {
				toastText('Something went wrong in delete user', 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};
	const reinviteUserAction = async (data: any) => {
		setIsLoading(true);
		try {
			await postApi('/user/reinvite-user', {
				userCompanyRoleId: data.userCompanyRoleId,
			});
			toastText('User re-invited successfully', 'success');
		} catch (error) {
			toastText('Something went wrong in re-inviting user', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchUsers = async () => {
		try {
			setIsLoading(true);

			const query = {
				limit,
				page,
				search: debouncedSearchValue,
				sort,
				type,
				filter,
			};

			const users = await getApi('/user', query);

			setUserData(users.data.data);
			setTotalUsers(users.data.total);
		} catch (err) {
			toastText('Something went wrong in fetching users', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, [limit, page, debouncedSearchValue, sort, type, filter]);

	const fetchRoles = async () => {
		try {
			const data: any = await getApi('/role');

			if (data.data.data) {
				const roles = data.data.data.map((role: any) => {
					return {
						id: role.id,
						name: role.roleName,
					};
				});
				setRoleOptions(roles);
			}
		} catch (err) {
			// toastText('Something went wrong in fetching roles', 'error');
		}
	};

	useEffect(() => {
		fetchRoles();
	}, []);
	// JSX
	return (
		<>
			<div className={styles['user-table']}>
				<TableActionHeader title={'Users'}>
					<div className={styles['user-table__action']}>
						{(isAddPermission || permissions.isSuperAdmin) && (
							<button
								className={`btn-blue ${styles['user-table__action--button']}`}
								onClick={openDrawerHandler}
								disabled={isLoading}
							>
								{/* <AddSvg /> */}
								<p>Add User</p>
							</button>
						)}
					</div>
				</TableActionHeader>
				<UserTable
					userDataSource={userData}
					paginationChangeHandler={paginationChangeHandler}
					currentPage={page}
					totalRecords={totalUsers}
					performSearchHandler={performSearchHandler}
					searchValue={search}
					showModal={showModal}
					openDrawerHandler={openDrawerHandler}
					setDrawerInfoHandler={setDrawerInfoHandler}
					setEditSelectedUser={setEditSelectedUser}
					performSortHandler={(type: any) => performSortHandler(type)}
					performFilterHandler={(value) => setFilter(value)}
					filterValue={filter}
					PageSize={limit}
					modifyPageSize={modifyPageSize}
					sortDirection={sortDirection}
					isLoading={isLoading}
					isAddUserLoading={false}
					reinviteUserAction={reinviteUserAction}
				/>
			</div>
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isModalOpen}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading} //isLoading
				// isAddUserLoading={false} //isAddUserLoading
			/>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<AddUserBody
						closeDrawerByAnimation={closeDrawerByAnimation}
						editSelectedUser={editSelectedUser}
						setEditSelectedUser={setEditSelectedUser}
						fetchUsers={fetchUsers}
						roleOptions={roleOptions}
					/>
				</SideDrawerWrapper>
			)}
		</>
	);
};

export default UsersTable;
