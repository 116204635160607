import { postApi } from 'apis';
import { LoginLayoutBody } from 'components/Login';
import { FORMDATA } from 'constants/Data';
import { LoginLayout } from 'layouts/Login';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toastText } from 'utils/utils';

// Login page
const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const userPresentInSystem = searchParams.get('ispresent');
	const setPassword = searchParams.get('setPassword');
	const [isPasswordValidate, setIsPasswordValidate] = useState(false);
	const location = useLocation();
	const currentPath = location.pathname;
	// State Management
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		verifyToken();
	}, []);

	// Verify Token Api
	const verifyToken = async () => {
		setIsLoading(true);
		if (currentPath === '/reset-password') {
			try {
				await postApi(`/auth/verify-token?token=${token}`);
			} catch (error: any) {
				toastText('This link has expired', 'error');
				navigate('/login');
			} finally {
				setIsLoading(false);
			}
		}
		if (currentPath === '/set-password') {
			try {
				const baseUrl = `/user/verify-token?token=${token}`;
				const url = userPresentInSystem
					? `${baseUrl}&ispresent=${userPresentInSystem}`
					: baseUrl;

				await postApi(url);
				if (userPresentInSystem) {
					navigate('/login');
				}
			} catch (error) {
				toastText('This link has expired', 'error');
				navigate('/login');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const onSubmit = async (values: any) => {
		if (setPassword === null && isPasswordValidate) {
			setIsLoading(true);

			let data;

			if (currentPath === '/set-password') {
				data = {
					setPassword: true,
					password: values.password,
					confirmPassword: values.confirmPassword,
				};
			} else {
				data = {
					setPassword: false,
					password: values.password,
					confirmPassword: values.confirmPassword,
				};
			}

			try {
				const response = await postApi(
					`/auth/change-password?token=${token}`,
					data
				);
				toastText(response?.data?.message, 'success');
				navigate('/login');
			} catch (error: any) {
				toastText(error?.response?.data?.message, 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	// JSX
	return (
		<LoginLayout>
			<LoginLayoutBody
				title={'Set Password'}
				formData={FORMDATA.resetPassword}
				buttonTitle={'Set Password'}
				onSubmit={onSubmit}
				isLoading={isLoading}
				redirectUrl="/login"
				redirectText="Back to login"
				setIsPasswordValidate={setIsPasswordValidate}
				isRememberMe={false}
				setIsRememberMe={() => {}}
			></LoginLayoutBody>
		</LoginLayout>
	);
};

export default ResetPassword;
