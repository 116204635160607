import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	EditOutlined,
	LoadingOutlined,
	PlusCircleOutlined,
	SendOutlined,
} from '@ant-design/icons';
import { employeeApi } from 'Api/employee';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatChanges, formatEntityName, toastText } from 'utils/utils';
import styles from './index.module.scss';

const EmployeeActivityBody = () => {
	const [employeeActivityData, setEmployeeActivityData] = useState<any>([]);
	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const employeeIdFromSearchParams = searchParams.get('employeeId');

		if (employeeIdFromSearchParams) {
			fetchEmployeeActivityData(employeeIdFromSearchParams);
		}
	}, [searchParams, currentPage]);

	const fetchEmployeeActivityData = async (employeeId: string) => {
		setIsLoading(true);
		try {
			const resData = await employeeApi.getEmployeeActivityData({
				entityId: employeeId,
				page: currentPage,
			});
			setEmployeeActivityData((prev: any) => [
				...prev,
				...resData?.data?.data,
			]);
			setTotalRecords(resData?.data?.totalRecords);
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in fetching employee activity';
			toastText(message, 'error');
		}
		setIsLoading(false);
	};

	const handleShowMoreClick = () => {
		setCurrentPage(currentPage + 1);
	};

	return (
		<>
			{isLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '90vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<div>
					<hr />
					<div className={styles['employeeActivity']}>
						{employeeActivityData?.map(
							(activity: any, index: number) => (
								<>
									{activity.eventType === 'CREATE' && (
										<div
											className={
												styles[
													'employeeActivity--container'
												]
											}
										>
											<div
												className={
													styles[
														'employeeActivity--container__status'
													]
												}
											>
												<div
													className={
														styles[
															'employeeActivity--container__status--icon'
														]
													}
												>
													<PlusCircleOutlined
														style={{
															fontSize: '20px',
														}}
													/>
												</div>
												<div
													className={
														styles[
															'employeeActivity--container__status--details'
														]
													}
												>
													<div
														className={
															styles[
																'employeeActivity--container__status--details__title'
															]
														}
													>
														Entry Created
													</div>
													<div>
														{activity.createdBy} |{' '}
														<span className="color-purple">
															{dayjs(
																activity.createdAt
															).format(
																'DD/MM/YYYY hh:mm A'
															)}
														</span>
													</div>
													<div className="color-purple">
														The{' '}
														{formatEntityName(
															activity.entityName
														)}{' '}
														is created.
													</div>
												</div>
											</div>
										</div>
									)}
									{activity.eventType === 'EDIT' && (
										<div
											className={
												styles[
													'employeeActivity--container'
												]
											}
										>
											<div
												className={
													styles[
														'employeeActivity--container__status'
													]
												}
											>
												<div
													className={
														styles[
															'employeeActivity--container__status--icon'
														]
													}
												>
													<EditOutlined
														style={{
															fontSize: '20px',
														}}
													/>
												</div>
												<div
													className={
														styles[
															'employeeActivity--container__status--details'
														]
													}
												>
													<div
														className={
															styles[
																'employeeActivity--container__status--details__title'
															]
														}
													>
														Entry Updated
													</div>
													<div>
														{activity.createdBy} |{' '}
														<span className="color-purple">
															{dayjs(
																activity.createdAt
															).format(
																'DD/MM/YYYY hh:mm A'
															)}
														</span>
													</div>
													<div className="color-purple">
														The{' '}
														{formatEntityName(
															activity.entityName
														)}{' '}
														details are updated{' '}
														<span
															className="color-pink"
															dangerouslySetInnerHTML={{
																__html: formatChanges(
																	activity?.changes
																),
															}}
														>
															{/* {formatChanges(
																activity.changes
															)} */}
														</span>
														.
													</div>
												</div>
											</div>
										</div>
									)}
									{activity.eventType === 'APPROVAL_SENT' && (
										<div
											className={
												styles[
													'employeeActivity--container'
												]
											}
										>
											<div
												className={
													styles[
														'employeeActivity--container__status'
													]
												}
											>
												<div
													className={
														styles[
															'employeeActivity--container__status--icon'
														]
													}
												>
													<SendOutlined
														style={{
															fontSize: '20px',
														}}
													/>
												</div>
												<div
													className={
														styles[
															'employeeActivity--container__status--details'
														]
													}
												>
													<div
														className={
															styles[
																'employeeActivity--container__status--details__title'
															]
														}
													>
														Approval Sent
													</div>
													<div>
														{activity.createdBy} |{' '}
														<span className="color-purple">
															{dayjs(
																activity.createdAt
															).format(
																'DD/MM/YYYY hh:mm A'
															)}
														</span>
													</div>
													<div className="color-purple">
														The updated{' '}
														{formatEntityName(
															activity.entityName
														)}{' '}
														details are sent for
														approval.
													</div>
												</div>
											</div>
										</div>
									)}
									{activity.eventType === 'APPROVED' && (
										<div
											className={
												styles[
													'employeeActivity--container'
												]
											}
										>
											<div
												className={
													styles[
														'employeeActivity--container__status'
													]
												}
											>
												<div
													className={
														styles[
															'employeeActivity--container__status--icon'
														]
													}
												>
													<CheckCircleOutlined
														style={{
															fontSize: '20px',
														}}
													/>
												</div>
												<div
													className={
														styles[
															'employeeActivity--container__status--details'
														]
													}
												>
													<div
														className={
															styles[
																'employeeActivity--container__status--details__title'
															]
														}
													>
														Approved
													</div>
													<div>
														{activity.createdBy} |{' '}
														<span className="color-purple">
															{dayjs(
																activity.createdAt
															).format(
																'DD/MM/YYYY hh:mm A'
															)}
														</span>
													</div>
													<div className="color-purple">
														The approval request of{' '}
														<span
															className="color-pink"
															dangerouslySetInnerHTML={{
																__html: formatChanges(
																	activity?.changes
																),
															}}
														>
															{/* {formatChanges(
																activity?.changes
															)} */}
														</span>{' '}
														has been approved.
													</div>
													{activity?.changes
														?.reason && (
														<div className="color-purple">
															Reason:{' '}
															<span className="color-pink">
																{
																	activity
																		?.changes
																		?.reason
																}
															</span>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
									{activity.eventType === 'REJECTED' && (
										<div
											className={
												styles[
													'employeeActivity--container'
												]
											}
										>
											<div
												className={
													styles[
														'employeeActivity--container__status'
													]
												}
											>
												<div
													className={
														styles[
															'employeeActivity--container__status--icon'
														]
													}
												>
													<CloseCircleOutlined
														style={{
															fontSize: '20px',
														}}
													/>
												</div>
												<div
													className={
														styles[
															'employeeActivity--container__status--details'
														]
													}
												>
													<div
														className={
															styles[
																'employeeActivity--container__status--details__title'
															]
														}
													>
														Rejected
													</div>
													<div>
														{activity.createdBy} |{' '}
														<span className="color-purple">
															{dayjs(
																activity.createdAt
															).format(
																'DD/MM/YYYY hh:mm A'
															)}
														</span>
													</div>
													<div className="color-purple">
														The approval request of{' '}
														<span
															className="color-pink"
															dangerouslySetInnerHTML={{
																__html: formatChanges(
																	activity?.changes
																),
															}}
														>
															{/* {formatChanges(
																activity?.changes
															)} */}
														</span>{' '}
														has been Rejected.
													</div>
													{activity?.changes
														?.reason && (
														<div className="color-purple">
															Reason:{' '}
															<span className="color-pink">
																{
																	activity
																		?.changes
																		?.reason
																}
															</span>
														</div>
													)}
												</div>
											</div>
										</div>
									)}

									{employeeActivityData.length !== index ? (
										<hr />
									) : null}
								</>
							)
						)}

						{totalRecords !== employeeActivityData.length && (
							<div
								className={
									styles['employeeActivity--show-more']
								}
							>
								<span onClick={handleShowMoreClick}>
									Show more
								</span>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default EmployeeActivityBody;
