import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import DownloadComponent from 'components/Global/Download';
import { useState } from 'react';

type Props = {
	content: any[];
	reportType: string;
};

const ReportPopOver = (props: Props) => {
	const { content, reportType } = props;
	const [visible, setVisible] = useState(false);

	const togglePopover = () => {
		setVisible(!visible);
	};

	const DisplayReports = () => {
		return (
			<>
				{content?.map((item: any) => {
					return (
						<div key={item.id}>
							<DownloadComponent
								documentUrl={item.documentUrl}
								showTooltip={true}
								downloadText={item.reportName}
								text={item.reportName}
								isLink={true}
								maxLength={50}
							/>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<Popover
			content={<DisplayReports />}
			title=""
			trigger="click"
			placement="bottom"
			open={visible}
			onOpenChange={togglePopover}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
				}}
			>
				<p style={{ marginRight: '8px' }}>{reportType} Reports</p>
				{visible ? <UpOutlined /> : <DownOutlined />}
			</div>
		</Popover>
	);
};

export default ReportPopOver;
