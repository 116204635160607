import { useEffect, useState } from 'react';

import PersonalLevyComponent from './PersonalLevyComponent';
import SkillsLevyComponent from './SkillLevyComponent';
import styles from './index.module.scss';
import { Divider } from 'antd';

const OtherComponent = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [skillsLevyDetails, setSkillsLevyDetails] = useState<any>([]);
	const [personalLevyDetails, setPersonalLevyDetails] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	return (
		<>
			<div className={styles['skillComponent']}>
				<SkillsLevyComponent
					skillsLevyData={skillsLevyDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
				<Divider
					dashed
					style={{ borderColor: '#BCBCBC', marginBottom: '4rem' }}
				></Divider>
				<PersonalLevyComponent
					personalLevyData={personalLevyDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
			</div>
		</>
	);
};

export default OtherComponent;
