import { getApi, postApi, putApi } from 'apis';

const getApprovalRequests = async (params: any) => {
	return await getApi(`/approval/employee`, params);
};

const getApprovalRequestsForNotification = async (params: any) => {
	return await getApi(`/approval`, params);
};

const acceptApprovalRequests = async (data: any) => {
	return await postApi(`/approval/accept`, data);
};

const acceptNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-accept`, data);
};

const acceptAllNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-accept-all`, data);
};

const acceptDeleteNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-delete-accept`, data);
};

const rejectApprovalRequests = async (data: any) => {
	return await postApi(`/approval/reject`, data);
};

const rejectNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-reject`, data);
};

const rejectAllNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-reject-all`, data);
};
const rejectDeleteNotificationApproval = async (data: any) => {
	return await postApi(`/approval/notification-delete-reject`, data);
};

const acceptTimeSheetApprovalRequest = async (data: any) => {
	return await postApi(`/approval/timesheet/accept`, data);
};

const rejectTimeSheetApprovalRequests = async (data: any) => {
	return await postApi(`/approval/timesheet/reject`, data);
};

const getApprovalFields = async (data: any) => {
	return await postApi(`/approval/fields`, data);
};

const updateApprovalFields = async (data: any) => {
	return await putApi(`/approval/fields`, data);
};

export const getApproveRequestApi = {
	getApprovalRequests,
	acceptApprovalRequests,
	rejectApprovalRequests,
	getApprovalFields,
	updateApprovalFields,
	acceptTimeSheetApprovalRequest,
	rejectTimeSheetApprovalRequests,
	acceptNotificationApproval,
	rejectNotificationApproval,
	acceptDeleteNotificationApproval,
	rejectDeleteNotificationApproval,
	getApprovalRequestsForNotification,
	acceptAllNotificationApproval,
	rejectAllNotificationApproval,
};
