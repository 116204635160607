import {
	DollarOutlined,
	EditOutlined,
	FileDoneOutlined,
} from '@ant-design/icons';
import { payrollApi } from 'Api/payroll';
import { Space, Spin, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import Ellipse from 'components/Global/Ellipse';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	ApprovedByAccounts,
	CrossRejectedSvg,
	DeleteActionSvg,
	EditActionSvg,
	LoanProcessing,
	PendingForApproval,
} from 'utils/svgs';
import { formatNumber, toastText } from 'utils/utils';
import AddModal from '../AddModal';
import styles from './index.module.scss';
import './index.scss';
import DownloadComponent from 'components/Global/Download';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	showDeleteModal: any;
	setDeletePayroll?: any;
	fetchAllPayrolls?: any;
};

const PayrollTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		showDeleteModal,
		setDeletePayroll,
		fetchAllPayrolls,
	} = props;

	const navigate = useNavigate();

	const context = useContext(PermissionContext);
	const isEdit = context.allowedPermissions.includes('Edit_Run_Payroll');
	const isView = context.allowedPermissions.includes('View_Run_Payroll');
	const isDelete = context.allowedPermissions.includes('Delete_Run_Payroll');
	const isAddInitiatePayment = context.allowedPermissions.includes(
		'Add_Initiate_Payment'
	);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { data } = useSelector((state: any) => state?.userProfile);

	const [bankDetailsData, setBankDetailsData] = useState([]);
	const [isBankLoading, setIsBankLoading] = useState(false);
	const [bankFileLoading, setBankFileLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [checkStatusLoading, setCheckStatusLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [selectedPayrollId, setSelectedPayrollId] = useState<string | null>(
		null
	);
	const [selectedPayrollCurrency, setSelectedPayrollCurrency] = useState<
		string | null
	>(null);

	const HandleViewModal = (id: string) => {
		navigate(`/run-payroll?payrollId=${id}&onlyView=true`);
	};

	// Function to open the modal
	const showModal = (payrollId: string) => {
		fetchCompanyBankDetails(payrollId);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		fetchAllPayrolls();
		setIsModalVisible(false);
	};

	const fetchCompanyBankDetails = async (payrollId: string) => {
		const findPayroll = tableData.find(
			(payroll: any) => payroll.id === payrollId
		);

		try {
			setIsBankLoading(true);
			const bankDetails = await payrollApi.getBankDetailsForPayroll(
				findPayroll.currency
			);
			setBankDetailsData(bankDetails.data.data);
		} catch (error: any) {
			let message = 'Something went wrong in fetching bank details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsBankLoading(false);
		}
	};

	const deleteDataHandler = (id: any) => {
		setDeletePayroll(id);
		showDeleteModal();
	};

	const handleModalOpen = (payrollId: string) => {
		showModal(payrollId);
		setSelectedPayrollId(payrollId);
	};

	const handleBankFileDownload = async (payrollId: string) => {
		try {
			setBankFileLoading((prev) => ({ ...prev, [payrollId]: true }));
			const response = await payrollApi.exportBnkCsv(payrollId);

			const payroll = tableData.find(
				(data: any) => data.id === payrollId
			);

			const fileName = `${payroll?.payPeriod}_bank_file.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const pdfUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error: any) {
			let message = 'Something went wrong in fetching bank file';

			if (error?.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setBankFileLoading((prev) => ({ ...prev, [payrollId]: false }));
		}
	};

	const handleBankDetails = async (data: any) => {
		setBankDetailsData(data);
	};

	const handleCheckStatus = async (payrollId: string) => {
		try {
			setCheckStatusLoading((prev) => ({ ...prev, [payrollId]: true }));
			const response = await payrollApi.createPayrollHistory({
				payrollId,
			});
			toastText(response.data.message, 'success');
			fetchAllPayrolls();
		} catch (error: any) {
			let message = 'Something went wrong in checking status';

			if (error?.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setCheckStatusLoading((prev) => ({ ...prev, [payrollId]: false }));
		}
	};

	return (
		<div
			className="dynamic-payroll-table"
			style={{ width: '100%', overflowX: 'auto' }}
		>
			<Table
				dataSource={tableData}
				scroll={tableData.length > 0 ? { x: 1500 } : undefined}
				// style={{ width: '100%' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					width={260}
					fixed="left"
					sorter={false}
					className="bg-white"
					render={(text, record: any) => {
						if (record.status === 'APPROVED' && isView) {
							return (
								<span
									style={{ cursor: 'pointer', color: 'blue' }}
									onClick={() => HandleViewModal(record.id)}
								>
									{text}
								</span>
							);
						} else {
							return <span>{text}</span>;
						}
					}}
				/>
				<Column
					title="Pay Group"
					dataIndex="payGroups"
					key="payGroups"
					width={200}
					sorter={false}
					className="bg-white"
					render={(text, record: any) => {
						return (
							<>
								<Ellipse
									message={record.payGroups.join(' , ')}
									maxLength={18}
									key={Math.random()}
									tooltipMessage={record.payGroups.join(
										' , '
									)}
									isTooltip={true}
								/>
								<span></span>
							</>
						);
					}}
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					width={100}
					align="center"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Combine Pay Slips"
					dataIndex="paySlips"
					key="paySlips"
					width={200}
					align="center"
					sorter={false}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.paySlipUrl ? (
								<DownloadComponent
									documentUrl={record.paySlipUrl}
									text={record.paySlipName}
									downloadText={record.paySlipName}
									showTooltip={false}
									isLink={true}
									maxLength={20}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Submitted By"
					dataIndex="createdBy"
					key="createdBy"
					width={130}
					align="center"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Total Payable"
					dataIndex="totalPayable"
					key="totalPayable"
					align="center"
					width={150}
					sorter={false}
					className="bg-white"
					render={(value: string, record: any) => {
						if (record.currency === 'USD') {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					align="center"
					key="status"
					width={150}
					className="bg-white"
					render={(value) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						switch (value) {
							case 'PENDING':
								statusText = 'Pending';
								statusClass =
									styles['status-pending-for-approval'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'APPROVED':
								statusText = 'Approved';
								statusClass =
									styles['status-approved-by-accounts'];
								StatusIcon = <ApprovedByAccounts />;
								break;
							case 'REJECTED':
								statusText = 'Rejected';
								statusClass =
									styles['status-rejected-by-accounts'];
								StatusIcon = <CrossRejectedSvg />;
								break;
							case 'DRAFT':
								statusText = 'Draft';
								statusClass = styles['status-draft'];
								StatusIcon = <LoanProcessing />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}

						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<div className={styles['statusIcon']}>
									{StatusIcon}
									<span>{statusText}</span>
								</div>
							</Tag>
						);
					}}
				/>

				<Column
					title="Bank File"
					dataIndex="bankFile"
					key="bankFile"
					width={100}
					align="center"
					sorter={false}
					className="bg-white"
					render={(_, record: any) => (
						<Tooltip
							title={
								record.status === 'APPROVED'
									? 'Click to download the bank file.'
									: 'The bank file will be available after payroll approval.'
							}
						>
							{record.status === 'APPROVED' ? (
								bankFileLoading[record.id] ? (
									// Show a loader while loading
									<Spin size="small" />
								) : (
									// Icon-only button
									<FileDoneOutlined
										style={{
											fontSize: '19px',
										}}
										onClick={() => {
											handleBankFileDownload(record.id);
										}}
									/>
								)
							) : (
								<div className="text-gray-400">N/A</div>
							)}
						</Tooltip>
					)}
				/>

				<Column
					title="Action"
					key="action"
					width={140}
					align="center"
					className="bg-white"
					render={(_: any, record: any) => (
						<Space size={20}>
							{record.status !== 'APPROVED' &&
							isEdit &&
							record.status !== 'REJECTED' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18 }}
									onClick={() =>
										navigate(
											`/run-payroll?payrollId=${record?.id}`
										)
									}
								>
									<EditOutlined />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</p>
							)}

							{record.status !== 'APPROVED' &&
							isDelete &&
							record.status !== 'REJECTED' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18, cursor: 'pointer' }}
									onClick={() => {
										deleteDataHandler(record.id); // Log before calling handler
									}}
								>
									<DeleteActionSvg />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</p>
							)}
						</Space>
					)}
				/>
				<Column
					title="Initiate Payment"
					dataIndex="initiatePayment"
					key="initiatePayment"
					width={170}
					align="center"
					sorter={false}
					className="bg-white"
					render={(_, record: any) => {
						const isDisabled =
							record.status !== 'APPROVED' ||
							!isAddInitiatePayment;
						return (
							<Tooltip title="Payment initiation is available once the payroll is approved.">
								{!isDisabled ? (
									<div
										onClick={() => {
											setSelectedPayrollCurrency(
												record.currency
											);
											handleModalOpen(record.id);
										}}
										style={{
											cursor: 'pointer',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											fontSize: '19px',
											width: '100%',
											height: '100%',
										}}
									>
										<DollarOutlined
										// style={{
										// 	color: '#584495',
										// }}
										/>
									</div>
								) : (
									<div className="text-gray-400">N/A</div>
								)}
							</Tooltip>
						);
					}}
				/>
				<Column
					title="Check Status"
					dataIndex="initiatePayment"
					key="checkStatus"
					width={200}
					align="center"
					sorter={false}
					className="bg-white"
					render={(_, record: any) => {
						const isDisabled =
							{ record }.record?.status !== 'APPROVED';

						return (
							<Tooltip title="Status can be checked after payroll is approved">
								{!isDisabled ? (
									<Buttons
										buttons={[
											{
												text: 'Check Status',
												isLoading:
													checkStatusLoading[
														record.id
													] || false,
												className: 'btn-blue',
												isSubmit: false,
												onclick: () => {
													handleCheckStatus(
														record.id
													);
												},
												fontSize: '1.5rem',
												minWidth: '7rem',
												minHeight: '2rem',
											},
										]}
									/>
								) : (
									<div className="text-gray-400">N/A</div>
								)}
							</Tooltip>
						);
					}}
				/>
			</Table>
			{selectedPayrollId && selectedPayrollCurrency && (
				<AddModal
					isOpen={isModalVisible}
					handleCancel={handleCancel}
					bankDetailsData={bankDetailsData}
					isBankLoading={isBankLoading}
					payRollId={selectedPayrollId}
					handleBankDetails={handleBankDetails}
					currency={selectedPayrollCurrency}
				/>
			)}
		</div>
	);
};

export default PayrollTable;
