import {
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	InboxOutlined,
	SearchOutlined,
	SyncOutlined,
	UploadOutlined,
	UsergroupAddOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Divider,
	Dropdown,
	Menu,
	Modal,
	Result,
	Row,
	Select,
	Space,
	Steps,
	Table,
	Tooltip,
} from 'antd';
import Upload, { UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { employeeApi } from 'Api/employee';
import { Loader } from 'components/Global';
import { PermissionContext } from 'components/Global/AuthLayout';
import SearchComponent from 'components/Global/SearchComponent';
import { useCallback, useContext, useMemo, useState } from 'react';
import { DeleteActionSvg, DownloadSvg } from 'utils/svgs';
import { convertArrayToCSV, toastText } from 'utils/utils';
import BulkSalaryImport from './BulkSalaryAddModal';
import styles from './index.module.scss';
import './index.scss';

const { Step } = Steps;

const statusOptions = [
	{
		label: 'All Status',
		value: '',
	},
	{
		label: 'Active',
		value: 'ACTIVE',
	},
	{
		label: 'Suspended',
		value: 'SUSPENDED',
	},
	{
		label: 'Redundant',
		value: 'REDUNDANT',
	},
	{
		label: 'Retired',
		value: 'RETIRED',
	},
	{
		label: 'Reassigned',
		value: 'REASSIGNED',
	},
	{
		label: 'Dismissed',
		value: 'DISMISSED',
	},
	{
		label: 'Deceased',
		value: 'DECEASED',
	},
];

const steps = [
	{
		title: 'Upload CSV',
	},
	{
		title: 'Validation Results',
	},
	{
		title: 'Correct Data',
	},
	{
		title: 'Confirmation',
	},
];

const staffCodeOptions = [
	{
		label: 'All Staff Code',
		value: '',
	},
	{
		label: 'Hourly',
		value: 'HOURLY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
];

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	handleSearchApi: (value: string) => void;
	staffCodeFilterValue: string;
	handleStaffCodeFilter: (value: string) => void;
	fetchRecords: any;
	fetchEmployees: any;
	isLoading: any;
	totalRecords: number;
};

const EmployeeHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		statusFilterValue,
		handleStatusFilter,
		handleSearchApi,
		handleStaffCodeFilter,
		staffCodeFilterValue,
		fetchEmployees,
		isLoading,
		totalRecords,
	} = props;

	const permissionContext = useContext(PermissionContext);

	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Employment');

	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isUploadLoading, setIsUploadLoading] = useState(false);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [validationResults, setValidationResults] = useState([]);

	const [finalData, setFinalData] = useState([]);

	const [isErrorModal, setIsErrorModal] = useState(false);
	const [errorData, setErrorData] = useState<any>(null);

	const [currentStep, setCurrentStep] = useState(0);
	const [isImportLoading, setIsImportLoading] = useState(false);

	const [downloadLoading, setDownloadLoading] = useState(false);

	const next = async () => {
		if (currentStep == 1) {
			const data = validationResults.filter((item: any) => item._isValid);
			setFinalData(data);
		}
		if (currentStep == 2) {
			await importEmployees(finalData);
		} else {
			setCurrentStep((prev: any) => {
				return Math.min(prev + 1, steps.length - 1);
			});
		}
	};

	const prev = () => {
		setCurrentStep((prev) => Math.max(prev - 1, 0));
	};

	const getClassName = (isValid: boolean) => {
		if (!isValid) {
			return 'required-color';
		} else {
			return '';
		}
	};

	const handleDelete = useCallback(
		(index: number) => {
			// Use slicing for immutable updates (more efficient than filter for large arrays)
			const updatedResults = [
				...validationResults.slice(0, index),
				...validationResults.slice(index + 1),
			];
			setValidationResults(updatedResults);

			if (currentStep === 2) {
				const updatedFinalData = [
					...finalData.slice(0, index),
					...finalData.slice(index + 1),
				];
				setFinalData(updatedFinalData);
			}

			toastText('Record deleted Successfully', 'success');
		},
		[validationResults, finalData, currentStep]
	);

	const columns: any = useMemo(() => {
		if (!validationResults.length) return [];
		return [
			{
				title: 'Status',
				key: '_validationStatus',
				fixed: 'left',
				width: 80,
				className: 'bg-white',
				render: (_: any, record: any) =>
					record._isValid ? (
						<div
							className="text-green-500 bg-table"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'green',
							}}
						>
							<CheckCircleOutlined />
						</div>
					) : (
						<div
							className="text-red-500 bg-table"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'red',
							}}
						>
							<CloseCircleOutlined />
						</div>
					),
			},
			{
				title: 'Validation',
				key: '_errors',
				width: 105,
				className: 'bg-white',
				fixed: 'left',
				render: (_: any, record: any) => {
					return !record._isValid ? (
						<p
							onClick={() => {
								setIsErrorModal(true);
								setErrorData(record);
							}}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'orange',
							}}
							className={`pointer ${getClassName(
								record._isValid
							)}`}
						>
							<WarningOutlined />
						</p>
					) : null;
				},
			},
			...Object.keys(validationResults[0] || {})
				.filter((key) => !key.startsWith('_'))
				.map((key) => ({
					title: key,
					dataIndex: key,
					key: key,
					width: 150,
					ellipsis: true, // Truncate if text overflows
					className: 'bg-table bg-white',
					render: (text: string, record: any) => (
						<div
							style={{ minWidth: 150 }}
							className={`${getClassName(record._isValid)}`}
						>
							{text}
						</div>
					),
				})),
			{
				title: 'Action',
				key: 'action',
				width: 100,
				fixed: 'right',
				render: (_: any, record: any, index: number) => {
					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Tooltip title="Delete">
								<p
									className="cursor-pointer"
									onClick={() => {
										handleDelete(index);
									}}
								>
									<DeleteActionSvg />
								</p>
							</Tooltip>
						</div>
					);
				},
			},
		];
	}, [handleDelete, validationResults]);

	const downloadEmployeeCsv = async () => {
		try {
			const response = await employeeApi.downloadEmployeeCsvTemplate();
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'text/csv',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `employees.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		}
	};

	const downloadEmployeeCsvWithData = async () => {
		try {
			setDownloadLoading(true);
			const response =
				await employeeApi.downloadEmployeeCsvWithDataTemplate();
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'text/csv',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `employees_data.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		} finally {
			setDownloadLoading(false);
		}
	};

	const handleUpload = async (file: any) => {
		const reader = new FileReader();
		reader.onload = async (e: any) => {
			try {
				setIsUploadLoading(true);
				let formData = new FormData();

				formData.append('documentName', 'employee_csv');
				formData.append('file', file);

				const validatedData: any =
					await employeeApi.validateDataFromAPI(formData);

				setValidationResults(validatedData.data?.data);
				setIsModalVisible(true);

				setCurrentStep(1);
			} catch (error) {
				console.error(
					'Error during file upload and validation:',
					error
				);
				setFileList([]);
				setCurrentStep(0);
				toastText('Failed to process file. Please try again', 'error');
			} finally {
				setIsUploadLoading(false);
			}
		};

		reader.onerror = () => {
			toastText('File reading failed', 'error');
		};

		reader.readAsText(file);

		// Prevent upload
		return false;
	};

	const importEmployees = async (data: any) => {
		try {
			setIsImportLoading(true);

			const csvString = convertArrayToCSV(data);

			const blob = new Blob([csvString], { type: 'text/csv' });

			const formData = new FormData();
			formData.append('file', blob, 'data.csv');

			const response = await employeeApi.importEmployeesWithFormData(
				formData
			);
			// const response = await employeeApi.importEmployees({
			// 	employees: data,
			// });
			toastText(response.data.message, 'success');
			setCurrentStep(3);
		} catch (error: any) {
			let errorMessage = '';
			if (error.response?.data?.responseStatus === 401) {
				errorMessage =
					'Something went wrong please while importing employees';
			} else {
				errorMessage = error.response?.data?.message;
			}
			toastText(errorMessage, 'error');
		} finally {
			setIsImportLoading(false);
		}
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};

	const handleImportMore = () => {
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};
	const menu = (
		<>
			<Menu>
				<p style={{ padding: '5px', color: '#584495' }}>
					<span style={{ fontSize: '18px' }}>
						<UsergroupAddOutlined />
					</span>{' '}
					Employee Action
				</p>
				<Tooltip title="Employee Action">
					{/* <Menu.Item
						style={{ backgroundColor: 'white' }}
						key="1"
						onClick={downloadEmployeeCsv}
						icon={<DownloadOutlined />}
					>
						Download Employee CSV Template
					</Menu.Item> */}
					<Menu.Item
						style={{ backgroundColor: 'white' }}
						key="2"
						onClick={() => setIsModalVisible(true)}
						icon={<UploadOutlined />}
					>
						Upload Employee CSV File
					</Menu.Item>
					<Divider style={{ marginBottom: '3px' }} />
				</Tooltip>
				<BulkSalaryImport />
			</Menu>
		</>
	);

	return (
		<>
			<div className={styles['employee-header']}>
				<Space>
					<SearchComponent
						className={styles['employee-header-item']}
						placeHolder="Search employee..."
						prefixIcon={<SearchOutlined />}
						handleChange={handleSearch}
						handleChangeApi={handleSearchApi}
						value={searchValue}
						size="large"
					/>
				</Space>
				<Space>
					<Select
						className={styles['employee-header-item']}
						value={statusFilterValue}
						options={statusOptions}
						onChange={(value) => {
							handleStatusFilter(value);
						}}
						size="large"
						placeholder="Select Status"
						style={{
							maxWidth: '200px',
						}}
					/>
				</Space>
				<Space>
					<Select
						className={styles['employee-header-item']}
						value={staffCodeFilterValue}
						options={staffCodeOptions}
						onChange={(value) => {
							handleStaffCodeFilter(value);
						}}
						size="large"
						placeholder="Select Staff Code"
						style={{
							maxWidth: '200px',
						}}
					/>
				</Space>

				<div className={styles['refresh-buttons']}>
					<Tooltip title="Refresh all the Employee Details">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={!isLoading ? fetchEmployees : undefined}
						/>
					</Tooltip>
				</div>
				{totalRecords > 0 ? (
					<div
						style={{
							marginRight: '10px',
						}}
					>
						<Tooltip title="Download CSV with employee Data">
							<p
								className={styles['csv-button']}
								onClick={
									!downloadLoading
										? downloadEmployeeCsvWithData
										: undefined
								}
							>
								{downloadLoading ? (
									<>
										<Loader />
									</>
								) : (
									<DownloadSvg />
								)}
							</p>
						</Tooltip>
					</div>
				) : null}
				{isAdd && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid grey',
							borderRadius: '5px',
						}}
					>
						<Dropdown overlay={menu} trigger={['click']}>
							<p className={styles['menu-icon']}>
								<span
									style={{
										fontSize: '3rem',
										color: 'grey',
										marginBottom: '15px',
										padding: '5px 10px',
									}}
								>
									...
								</span>
							</p>
						</Dropdown>
					</div>
				)}

				{/* <Tooltip title="Download CSV template for employees">
					<p
						className={styles['csv-button']}
						onClick={downloadEmployeeCsv}
					>
						<DownloadSvg />
					</p>
				</Tooltip>
				<Tooltip title="Upload CSV file for employees">
					<p
						className={styles['csv-button']}
						onClick={() => setIsModalVisible(true)}
					>
						<UploadSvg />
					</p>
				</Tooltip> */}
			</div>

			<Modal
				open={isModalVisible}
				onCancel={handleModalClose}
				width="1400px"
				footer={null}
				rootClassName="uploadCsvModal"
				closable={false}
				style={{
					maxHeight: 'calc(100vh - 200px)', // Fixed height for the modal
					display: 'flex',
					flexDirection: 'column',
					// overflowY: 'scroll',
				}}
			>
				<div>
					<Steps current={currentStep}>
						{steps.map((step, index) => (
							<Step key={index} title={step.title} />
						))}
					</Steps>

					<div
						style={{
							marginTop: '20px',
							marginBottom: '20px',
							padding: '20px',
							border: '1px solid #d9d9d9',
							borderRadius: '5px',
							flex: 1,
							overflow: 'hidden',
							display: 'flex',
							flexDirection: 'column',
							minHeight: '200px',
							justifyContent: 'center',
						}}
					>
						{currentStep === 0 && (
							<div className={styles['document-upload']}>
								{isUploadLoading ? (
									<Loader />
								) : (
									<>
										<Row>
											<Col
												span={24}
												style={{
													paddingBottom: '10px',
													paddingTop: '0px',
													display: 'flex',
													justifyContent: 'end',
												}}
											>
												<Tooltip title="Download Employee CSV Template">
													<p
														className={
															styles[
																'template-link'
															]
														}
														onClick={
															downloadEmployeeCsv
														}
													>
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'center',
															}}
														>
															<DownloadSvg />
														</div>
														Download Template
													</p>
												</Tooltip>
											</Col>
										</Row>
										<Dragger
											name="file"
											multiple={false}
											accept=".csv"
											fileList={fileList}
											beforeUpload={(file) => {
												const isCSV =
													file.type === 'text/csv' ||
													file.name
														.toLowerCase()
														.endsWith('.csv');

												if (!isCSV) {
													toastText(
														'You can only upload CSV files',
														'error'
													);
													return Upload.LIST_IGNORE;
												}

												handleUpload(file);
												return false;
											}}
											onChange={(info) => {
												let newFileList = [
													...info.fileList,
												];

												// Limit to one file
												newFileList =
													newFileList.slice(-1);

												setFileList(newFileList);
											}}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">
												Click or drag CSV file to this
												area to upload
											</p>
											<p className="ant-upload-hint">
												Support for a single CSV file
												upload with{' '}
												<span
													style={{ color: '#584495' }}
												>
													500 records
												</span>
											</p>
										</Dragger>
									</>
								)}
							</div>
						)}
						{currentStep === 1 && (
							<div className="validation-results">
								<Table
									columns={columns}
									dataSource={validationResults ?? []}
									scroll={{
										x: 'max-content',
										y: 'calc(100vh - 400px)',
									}}
									pagination={false}
									size="middle"
									bordered
									tableLayout="fixed"
									footer={() => (
										<div style={{ textAlign: 'right' }}>
											Total No. of Employees :{' '}
											{validationResults?.length || 0}
										</div>
									)}
								/>
							</div>
						)}
						{currentStep === 2 && (
							<div className="validation-results">
								<Table
									columns={columns.slice(0, -2)}
									dataSource={finalData ?? []}
									scroll={{
										x: 'max-content',
										y: 'calc(100vh - 400px)',
									}}
									tableLayout="fixed"
									pagination={false}
									loading={isImportLoading}
									size="middle"
									bordered
									footer={() => (
										<div style={{ textAlign: 'right' }}>
											Total No. of Employees :{' '}
											{finalData?.length || 0}
										</div>
									)}
								/>
							</div>
						)}
						{currentStep === 3 && (
							<Result
								status="success"
								title={`The process of importing ${
									finalData.length > 1
										? 'employees'
										: 'an employee'
								} has started.`}
								subTitle={`Processing ${
									finalData.length > 1
										? 'employees'
										: 'employee'
								} record. Only ${
									finalData.length
								} validated and imported. This may take a moment, Please wait....`}
								icon={
									<CheckCircleFilled
										style={{ color: '#584495' }}
									/>
								}
								extra={[
									<Button
										type="primary"
										key="console"
										onClick={handleImportMore}
									>
										Import More
									</Button>,
									<Button
										key="buy"
										onClick={handleModalClose}
									>
										Cancel
									</Button>,
								]}
							/>
						)}
					</div>

					{currentStep !== 3 && (
						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'end',
								gap: '15px',
							}}
						>
							<Button onClick={handleModalClose}>Cancel</Button>
							{currentStep !== 0 && (
								<Button
									disabled={
										currentStep === 0 ||
										isImportLoading ||
										isUploadLoading
									}
									onClick={prev}
									type="primary"
								>
									Previous
								</Button>
							)}
							{fileList.length > 0 && (
								<Button
									disabled={
										currentStep === steps.length - 1 ||
										isImportLoading ||
										isUploadLoading
									}
									type="primary"
									onClick={next}
								>
									{currentStep === 2
										? 'Import Employees'
										: 'Next'}
								</Button>
							)}
						</div>
					)}
				</div>
			</Modal>

			{errorData && isErrorModal && (
				<Modal
					open={isErrorModal}
					onCancel={() => {
						setErrorData(null);
						setIsErrorModal(false);
					}}
					width="90%"
					footer={null}
				>
					<ul
						className="error-list"
						style={{
							listStyleType: 'none',
							padding: '10px',
							borderRadius: '5px',
							border: '1px solid #d4d4d4',
							color: '#721c24',
							fontFamily: 'Arial, sans-serif',
							// height: '550px',
							overflowY: 'auto',
						}}
					>
						{Object.entries(errorData._validationErrors).map(
							([field, error]: any) => (
								<li
									key={field}
									style={{
										padding: '8px 12px',
										borderBottom: '1px solid #d4d4d4',
									}}
								>
									<span
										style={{
											fontWeight: 'bold',
											color: '#b02a2a',
										}}
									>
										{field}
									</span>{' '}
									:{' '}
									<span
										style={{
											fontStyle: 'italic',
											color: 'black',
										}}
									>
										{error}
									</span>
								</li>
							)
						)}
					</ul>
				</Modal>
			)}
		</>
	);
};

export default EmployeeHeader;
