import React from 'react';
import { Card, Spin, Result, Typography, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

interface ApproveRejectComponentProps {
	isLoading: boolean;
	status: 'idle' | 'approved' | 'rejected' | 'error' | '';
	errorMessage?: string;
}

const ApproveRejectComponent: React.FC<ApproveRejectComponentProps> = ({
	isLoading,
	status,
	errorMessage,
}) => {
	const navigate = useNavigate();
	const navigateToHome = () => {
		navigate('/');
	};
	const renderContent = () => {
		switch (status) {
			case 'idle':
				return (
					<Spin tip="Processing your request...">
						<Paragraph>
							Please wait while we process your action.
						</Paragraph>
					</Spin>
				);
			case 'approved':
				return (
					<Result
						status="success"
						title="Approved"
						subTitle="Your request has been approved successfully."
						icon={
							<CheckCircleOutlined style={{ color: '#52c41a' }} />
						}
					/>
				);
			case 'rejected':
				return (
					<Result
						status="error"
						title="Rejected"
						subTitle="Your request has been rejected."
						icon={
							<CloseCircleOutlined style={{ color: '#f5222d' }} />
						}
					/>
				);
			case 'error':
				return (
					<Result
						status="error"
						title="Error"
						subTitle={
							errorMessage ||
							'An error occurred while processing your request.'
						}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Card style={{ width: 300, margin: '20px auto' }}>
			<div>
				<Title style={{ marginLeft: '5rem' }} level={3}>
					Request Status
				</Title>
			</div>
			{renderContent()}

			{!isLoading && (
				<Button
					style={{ width: '24rem', margin: '20px auto' }}
					type="primary"
					onClick={navigateToHome}
				>
					Go to Home
				</Button>
			)}
		</Card>
	);
};

export default ApproveRejectComponent;
