import { payrollApi } from 'Api/payroll';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
import styles from './index.module.scss';
import GlobalHeader from '../../GlobalHeader';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	setTotalNetPay: any;
	setIsUniversalLoading: any;
	headerData: any;
	totalNetPay: any;
};
const NetPayTable = (props: Props) => {
	const {
		selectedPayrollId,
		setTotalNetPay,
		setIsUniversalLoading,
		headerData,
		totalNetPay,
	} = props;
	const [netPayData, setNetPayData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(31);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const { data } = useSelector((state: any) => state?.userProfile);

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const FormattedValue = (value: any) => {
		if (!usdCurrencyToggle && isUsdCurrency) {
			return formatNumber(Number(value), data?.CompanyCurrencies, true);
		} else {
			return formatNumber(
				Number(value * (conversionRate ?? 1)),
				data?.CompanyCurrencies
			);
		}
	};

	const fetchNetPay = async (payrollId: string) => {
		try {
			setIsLoading(true);
			setIsUniversalLoading(true);
			const netPay = await payrollApi.getAllEmployeesNetPay({
				payrollId: payrollId,
				page: page,
				pageSize: pageSize - 1,
			});
			const data = netPay.data.data.data;
			const _data = data;
			const companyData = netPay.data.companyTotals;
			_data.push({
				firstName: 'Cost to Company',
				lastName: '',
				totalDeductions: companyData.totalDeductions,
				totalEarnings: companyData.totalEarnings,
				netPay: companyData.totalNetPay,
				field: 'Total',
			});

			setNetPayData(data);

			const allData = [...data, ...totalNetPay];
			setTotalNetPay(allData);

			//push total to the end of the array

			setTotalRecords(netPay.data.data.totalRecords);
		} catch (err: any) {
			let message = 'Something went wrong in fetching Net Pay';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	useEffect(() => {
		fetchNetPay(selectedPayrollId);
	}, [selectedPayrollId, page, pageSize]);

	return (
		<>
			<div className="payroll-steps-table">
				<GlobalHeader
					headerData={headerData}
					toggleRequired={true}
					NetPay={true}
					// fetchAllData={() => fetchNetPay(selectedPayrollId)}
					// isLoading={isLoading}
				/>
				<Table
					dataSource={netPayData}
					scroll={{ y: 'calc(90vh - 390px)' }}
					rowClassName={(record, index) =>
						index === netPayData.length - 1 ? 'sticky-row' : ''
					}
					pagination={{
						total: totalRecords,
						current: page,
						pageSize: pageSize,
						showSizeChanger: false,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					rowKey={(record) => record.id}
					loading={isLoading}
					onChange={tableChangeHandler}
				>
					<Column
						title="Employee Name | Code"
						dataIndex="employeeCode"
						key="employeeCode"
						// sorter={true}
						width={'40%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								{record.field !== 'Total' ? (
									<>
										{' '}
										<span>
											{record.firstName} {record.lastName}
										</span>{' '}
										|{' '}
										<span className="color-purple">
											{record.employeeCode}
										</span>
									</>
								) : (
									<p className={styles['total']}>
										{record.firstName}
									</p>
								)}
							</>
						)}
					/>

					<Column
						title="Total Earnings"
						dataIndex="totalEarnings"
						key="totalEarnings"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: any, record: any) => {
							if (record.field === 'Total') {
								return (
									<p className={styles['total']}>
										{FormattedValue(value)}
									</p>
								);
							} else {
								return FormattedValue(value);
							}
						}}
					/>
					<Column
						title="Total Deductions"
						key="totalDeductions"
						dataIndex="totalDeductions"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: any, record: any) => {
							if (record.field === 'Total') {
								return (
									<p className={styles['total']}>
										{FormattedValue(value)}
									</p>
								);
							} else {
								return FormattedValue(value);
							}
						}}
					/>
					<Column
						title="Total Pay"
						dataIndex="netPay"
						key="netPay"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: string, record: any) => {
							if (record.field === 'Total') {
								return (
									<p className={styles['total']}>
										{FormattedValue(value)}
									</p>
								);
							}
							return FormattedValue(value);
						}}
					/>
				</Table>
			</div>
		</>
	);
};
export default NetPayTable;
