import { Col, Row } from 'antd';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import SelectDropdown from 'components/Global/SelectDropdown';
import { invalidText } from 'utils/utils';

type Props = {
	year: any;
	onYearChange: any;
	selectedPayPeriod: string;
	setSelectedPayPeriod: any;
	branchId: string;
	departmentId: string;
	handleChange: any;
	dropdownOptions: any;
	isLoading: boolean;
	payPeriodData: any;
	exportButton: any;
};

const AttendanceHeader = (props: Props) => {
	const {
		year,
		onYearChange,
		selectedPayPeriod,
		setSelectedPayPeriod,
		branchId,
		departmentId,
		handleChange,
		dropdownOptions,
		isLoading,
		payPeriodData,
		exportButton,
	} = props;

	return (
		<>
			<Row
				gutter={30}
				style={{
					marginBottom: '20px',
					width: '100%',
				}}
			>
				<Col span={5}>
					<DatePickerField
						name="year"
						value={year}
						label="Year"
						required={false}
						helperText="Year is Required"
						onChange={(value) => {
							onYearChange(value);
						}}
						isError={false}
						picker="year"
					/>
				</Col>
				<Col span={5}>
					<SelectDropdown
						placeholder="Pay Period Name"
						options={payPeriodData}
						value={selectedPayPeriod}
						disabled={isLoading}
						loading={isLoading}
						onChange={(value) => {
							setSelectedPayPeriod(String(value));
						}}
						size="large"
						required={false}
						helperText="Pay period name is required"
						label="Pay Period"
						isError={false}
					/>
				</Col>
				<Col span={5}>
					<SelectDropdown
						defaultOption={true}
						defaultLabel="Select Branch"
						placeholder="Select Branch"
						options={dropdownOptions?.branchCode}
						value={branchId}
						onChange={(value) => {
							handleChange(value, true);
						}}
						size="large"
						required={false}
						helperText="Branch is required"
						label="Branch"
						disabled={false}
						isError={false}
					/>
				</Col>
				<Col span={5}>
					<SelectDropdown
						defaultOption={true}
						defaultLabel="Select Department"
						placeholder="Select Department"
						options={
							branchId
								? dropdownOptions?.department?.filter(
										(item: any) =>
											item.branchId === branchId
								  )
								: []
						}
						value={departmentId}
						onChange={(value) => {
							handleChange(value, false);
						}}
						size="large"
						required={false}
						helperText="Department is required"
						label="Department"
						disabled={invalidText(branchId)}
						isError={false}
					/>
				</Col>
				<Col
					span={4}
					style={{
						marginTop: '27px',
					}}
				>
					<Buttons buttons={exportButton} />
				</Col>
			</Row>
		</>
	);
};

export default AttendanceHeader;
