import { Input } from 'antd';
import { CustomSize } from 'interfaces/global.interface';
import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect } from 'react';

type Props = {
	placeHolder: string;
	handleChange: (value: string) => void;
	handleChangeApi: (value: string) => void;
	value: string | number;
	className?: string;
	suffixIcon?: any;
	prefixIcon?: any;
	size?: CustomSize;
	minHeight?: any;
};

const SearchComponent = (props: Props) => {
	const {
		placeHolder,
		handleChange,
		handleChangeApi,
		value,
		className,
		prefixIcon,
		size = 'large',
		minHeight,
	} = props;

	const debouncedSearch = useCallback(
		debounce((value: string) => handleChangeApi(value), 2000),
		[]
	);

	// Handle input change
	const handleSearchChange = (value: string) => {
		handleChange(value);
		debouncedSearch(value);
	};

	// Cleanup the debounce effect on unmount
	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	return (
		<Input
			style={{ minHeight: minHeight }}
			className={className}
			placeholder={placeHolder}
			prefix={prefixIcon && prefixIcon}
			onChange={(e: ChangeEvent<HTMLInputElement>) =>
				handleSearchChange(e.target.value)
			}
			value={value}
			size={size}
		/>
	);
};

export default SearchComponent;
