import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CoaData, CoaTableProps } from './type';
import styles from './index.module.scss';

const CoaTable: React.FC<CoaTableProps> = (props) => {
	const {
		data,
		loading,
		totalRecords,
		currentPage,
		paginationChangeHandler,
	} = props;
	const columns: ColumnsType<CoaData> = [
		{
			title: 'Account Number',
			dataIndex: 'accountNo',
			key: 'accountNo',
			className: 'bg-white',
		},
		{
			title: 'COA Name',
			dataIndex: 'name',
			key: 'name',
			className: 'bg-white',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			className: 'bg-white',
			key: 'type',
		},
		{
			title: 'Description',
			dataIndex: 'desc',
			key: 'desc',
			className: 'bg-white',
			render: (text: string) => <span>{text ? text :
				'-' }</span>
		},
	];

	return (
		<div className={styles['dynamic-table']}>
			<Table<CoaData>
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="id"
				pagination={{
					total: totalRecords,
					current: currentPage,
					onChange: paginationChangeHandler,
					defaultPageSize: 10,
					// showSizeChanger: true,
					// pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				scroll={{ y: 'calc(100vh - 350px)' }}
			/>
		</div>
	);
};

export default CoaTable;
