import { Checkbox, Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import { useState } from 'react';
import './index.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedNotification: any;
	handleRoleSubmit: any;
	roleData: any;
	moduleName: string;
};

const RoleAssignModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedNotification,
		handleRoleSubmit,
		roleData,
		moduleName,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [roles, setRoles] = useState<any>(roleData);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			isSubmit: true,
			className: 'secondary-btn',
			fontSize: '1.6rem',
			minWidth: '90px',
			minHeight: '35px',
			onclick: () => {
				handleCancel();
			},
			disabled: isLoading,
		},
		{
			text: 'Assign',
			isSubmit: true,
			className: 'btn-blue',
			fontSize: '1.6rem',
			minWidth: '90px',
			minHeight: '35px',
			onclick: () => {
				handleRoleSubmit(selectedNotification.id, roles);
			},
			disabled: isLoading,
			isLoading: false,
		},
	];

	const handleCheckNotification = (id: string, value: boolean) => {
		const _data = roles.map((item: any) => {
			if (item.id === id) {
				item.isChecked = value;
			}
			return item;
		});
		setRoles(_data);
	};
	return (
		<Modal
			open={isOpen}
			closable={false}
			width={400}
			onCancel={handleCancel}
			className="role-assign-modal"
			footer={() => {
				return (
					<div>
						<Buttons buttons={buttons} />
					</div>
				);
			}}
		>
			<div className="role-assign-table">
				<Table
					dataSource={roles}
					pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
					rowClassName={(record: any) => {
						return record.isTotal ? 'total-row' : '';
					}}
				>
					<Column
						title=""
						dataIndex="isChecked"
						className="bg-white"
						key="isChecked"
						width="50px"
						render={(record, data: any) => {
							return (
								<Checkbox
									checked={record}
									onChange={(e: any) =>
										handleCheckNotification(
											data.id,
											e.target.checked
										)
									}
								/>
							);
						}}
					/>
					<Column
						title={
							<>
								<p>Select Role for {moduleName}</p>
							</>
						}
						dataIndex="roleName"
						key="roleName"
						className="bg-white"
					/>
				</Table>
			</div>
		</Modal>
	);
};

export default RoleAssignModal;
