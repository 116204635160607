import { UploadOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import DownloadComponent from 'components/Global/Download';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paid } from 'utils/svgs';
import UploadRecord from '../uploadTransferModel';
import styles from './index.module.scss';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchAllPayrolls: any;
};

const PayrollHistoryTable = (props: TableProps) => {
	const [uploadRecordModal, setUploadRecordModal] = useState(false);
	const [payrollHistoryId, setPayrollHistoryId] = useState<any>(null);

	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		fetchAllPayrolls,
	} = props;

	const navigate = useNavigate();
	const context = useContext(PermissionContext);

	const isView = context.allowedPermissions.includes('View_Run_Payroll');

	const HandleViewModal = (id: string) => {
		navigate(`/run-payroll?payrollId=${id}&onlyView=true`);
	};

	const openUploadRecordModal = (historyId: string) => {
		setUploadRecordModal(true);
		setPayrollHistoryId(historyId);
	};

	const closeAllModal = () => {
		setUploadRecordModal(false);
		setPayrollHistoryId(null);
	};

	return (
		<div
			className={styles['dynamic-table']}
			style={{ width: '100%', overflowX: 'auto' }}
		>
			<Table
				dataSource={tableData}
				scroll={tableData.length > 0 ? { x: 1500 } : undefined}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
					width={260}
					fixed="left"
					render={(text, record: any) => {
						if (isView) {
							return (
								<span
									style={{ cursor: 'pointer', color: 'blue' }}
									onClick={() =>
										HandleViewModal(record.payrollId)
									}
								>
									{text}
								</span>
							);
						} else {
							return <span>{text}</span>;
						}
					}}
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
					width={150}
				/>
				<Column
					title="Pay Group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
					className="bg-white"
					width={200}
				/>
				<Column
					title="Approved By"
					dataIndex="approvedOn"
					key="approvedOn"
					sorter={false}
					className="bg-white"
					width={150}
				/>
				<Column
					title="Paid On"
					dataIndex="paidOn"
					key="paidOn"
					sorter={false}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
					width={150}
				/>
				<Column
					title="Combine Pay Slip"
					dataIndex="paySlipLink"
					key="paySlipLink"
					sorter={false}
					width={200}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.paySlipLink ? (
								<DownloadComponent
									documentUrl={record.paySlipLink}
									showTooltip={true}
									text={record.paySlipName}
									isLink={true}
									downloadText={record.paySlipName}
									maxLength={20}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Cash File"
					dataIndex="cashFile"
					key="cashFile"
					sorter={false}
					width={150}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.cashFileLink ? (
								<DownloadComponent
									documentUrl={record.cashFileLink}
									showTooltip={true}
									text={record.cashFileName}
									isLink={true}
									downloadText={record.cashFileName}
									maxLength={20}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Bank File"
					dataIndex="bankFile"
					key="bankFile"
					width={150}
					sorter={false}
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record?.bankFileLink ? (
								<DownloadComponent
									documentUrl={record.bankFileLink}
									showTooltip={true}
									downloadText={record.bankFileName}
									text={record.bankFileName}
									isLink={true}
									maxLength={20}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				<Column
					title="Transfer Receipt"
					dataIndex="transferReceipt"
					key="transferReceipt"
					sorter={false}
					width={230}
					className="bg-white"
					render={(_: any, record: any) => (
						<div>
							<Button
								icon={<UploadOutlined />}
								type="text"
								style={{ color: '#584495' }}
								onClick={() => openUploadRecordModal(record.id)}
							>
								Upload file
							</Button>
							{record.transferReceiptLink ? (
								<DownloadComponent
									documentUrl={record.transferReceiptLink}
									showTooltip={true}
									downloadText={record.transferReceiptName}
									text={record.transferReceiptName}
									isLink={true}
									maxLength={20}
								/>
							) : (
								<></>
							)}
						</div>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					width={150}
					className="bg-white"
					render={(status) => {
						return (
							<Tag bordered={false} color="green">
								<div className={styles['statusIcon']}>
									<Paid />
									<span>{status}</span>
								</div>
							</Tag>
						);
					}}
				/>
			</Table>

			{uploadRecordModal && (
				<UploadRecord
					open={uploadRecordModal}
					closeUploadRecordModal={closeAllModal}
					payrollHistoryId={payrollHistoryId}
					fetchAllPayrolls={fetchAllPayrolls}
				/>
			)}
		</div>
	);
};

export default PayrollHistoryTable;
