import { useContext } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import DeductionComponent from 'components/Settings/PayrollSettings/Deduction';
import './index.scss';

export const Deduction = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Deduction_Code');

	return <div>{check && <DeductionComponent />}</div>;
};
