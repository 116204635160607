import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import ReportPopOver from '../../ReportPopOver';

type tableProps = {
	openUploadRecordModal: (compensationRecordId: string) => void;
	calculateCompensationCurrentPage: number;
	calculateCompensationPageSize: number;
	calculateCompensationTotalRecords: number;
	calculateCompensationIsLoading: boolean;
	calculateCompensationData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const TableComponent = (props: tableProps) => {
	const {
		calculateCompensationCurrentPage,
		calculateCompensationPageSize,
		calculateCompensationTotalRecords,
		calculateCompensationIsLoading,
		calculateCompensationData,
		tableChangeHandler,
	} = props;

	return (
		<Table
			dataSource={calculateCompensationData}
			rowKey={(record: any) => record.id}
			loading={calculateCompensationIsLoading}
			pagination={{
				total: calculateCompensationTotalRecords,
				current: calculateCompensationCurrentPage,
				pageSize: calculateCompensationPageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
				className="bg-white"
			/>
			{/* <Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				width={'20%'}
			/> */}
			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				render={(text: any) => text}
				width={'15%'}
				className="bg-white"
			/>
			<Column
				title="Year"
				dataIndex="year"
				key="year"
				render={(text: any) => text}
				width={'15%'}
				className="bg-white"
			/>
			<Column
				title="Reports"
				dataIndex="report"
				className="bg-white"
				key="report"
				width={'20%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.CompensationReport}
						reportType="Compensation"
					/>
				)}
			/>
		</Table>
	);
};

export default TableComponent;
