import React, { useContext } from 'react';
import './index.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import PayPeriodComponent from 'components/Settings/PayrollSettings/PayPeriod';

export const PayPeriod = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Pay_Period');

	return <>{check && <PayPeriodComponent />}</>;
};
