import { timeLogsApi } from 'Api/timeLogs';
import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import DatePickerField from 'components/Global/DatePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { minutesToTimeString, timeStringToMinutes } from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedShift: string;
	selectedDate: string;
	isView: boolean;
};

const AddModal = (props: Props) => {
	const { isOpen, handleCancel, selectedShift, selectedDate, isView } = props;

	const dispatch = useDispatch<AppDispatch>();

	const isLoading = useSelector(
		(state: any) => state.constantDropdown.isLoading
	);

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(31);
	const [date, setDate] = useState<any>(dayjs(selectedDate));
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isNightShift, setIsNightShift] = useState(false);

	const [employeeHours, setEmployeeHours] = useState<any>([]);

	const handleDate = (value: string) => {
		setDate(value);
	};

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const fetchTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				shiftId: selectedShift,
				page: currentPage,
				pageSize: pageSize - 1,
			};

			const response = await timeLogsApi.viewTimeLogsApi(query);

			setEmployeeHours(response.data.data.data);
			setIsNightShift(response.data.data?.isNightShift);
			setTotalRecords(response.data.total);
		} catch (err) {
			// toastText('error', err.message);
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		fetchTimeLogs();
	}, [date, currentPage, pageSize, selectedShift]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{
				minWidth: '1200px',
				zIndex: '99999',
			}}
			onCancel={handleCancel}
			footer={() => {
				return (
					<div className={`${styles['modal']} modal`}>
						<div className={styles['modal-header']}>
							<Title level={4}>
								<span>📋 Daily Attendance</span>
							</Title>
							<div className={styles['modal-header-filter']}>
								<DatePickerField
									name="date"
									onChange={handleDate}
									required={false}
									value={date}
									isError={false}
									disabled={isLoading || isView}
								/>
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeHours}
								scroll={{ y: 'calc(100vh - 450px)' }}
								pagination={{
									total: totalRecords,
									current: currentPage,
									pageSize: pageSize,
									showSizeChanger: false,
									pageSizeOptions: [10, 20, 50, 100, 200],
								}}
								onChange={tableChangeHandler}
								rowKey={(record) => record.employeeId}
								loading={isTimeLogsLoading}
								rowClassName={(record: any) => {
									return record.isTotal ? 'total-row' : '';
								}}
							>
								<Column
									title="Employee Name | Code"
									dataIndex="employeeName"
									key="employeeName"
									className="bg-white"
									width="24%"
								/>
								<Column
									title="Regular hours"
									dataIndex="regularHours"
									key="regularHours"
									className="bg-white"
									width="19%"
								/>
								<Column
									title="Overtime hours"
									dataIndex="overtimeHours"
									key="overtimeHours"
									className="bg-white"
									width="19%"
								/>

								{/* <Column
									title="Time shift difference"
									dataIndex="timeShiftHours"
									key="timeShiftHours"
									className="bg-white"
									width="19%"
								/> */}
								<Column
									title="Total hours"
									dataIndex="totalHours"
									key="totalHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										const totalMinutes =
											timeStringToMinutes(
												data.regularHours
											) +
											timeStringToMinutes(
												data.overtimeHours
											);
										// +
										// timeStringToMinutes(
										// 	data.timeShiftHours
										// );
										return (
											<span className="color-purple">
												{' '}
												{minutesToTimeString(
													totalMinutes
												)}
											</span>
										);
									}}
								/>
								{isNightShift && (
									<Column
										title="Time Shift Difference"
										dataIndex="timeShiftHours"
										key="timeShiftHours"
										className="bg-white"
										width="19%"
										render={(record: any, data: any) => {
											let totalMinutes =
												timeStringToMinutes(
													data.regularHours
												) +
												timeStringToMinutes(
													data.overtimeHours
												);
											return (
												<span className="color-purple">
													{minutesToTimeString(
														totalMinutes
													)}
												</span>
											);
										}}
									/>
								)}
							</Table>
						</div>
					</div>
				);
			}}
			width={600}
		></Modal>
	);
};

export default AddModal;
