import React from 'react';
import { Card, Col, Row, Empty } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { DollarCircleOutlined } from '@ant-design/icons';

type Props = {
	employeeData: any;
};

const TaxationDetails = ({ employeeData }: Props) => {
	const taxationDetails = employeeData?.TaxationDetails;

	const formatDate = (date: string) => {
		return dayjs(date).format('DD/MM/YYYY');
	};

	const displayBoolean = (value: boolean) => {
		return value ? 'Yes' : 'No';
	};

	return (
		<div className={styles['taxation-details']}>
			<Card
				title={
					<div className={styles['card-title']}>
						{' '}
						<DollarCircleOutlined style={{ marginRight: 8 }} />
						Taxation Details
					</div>
				}
				bordered={false}
				className={styles['card']}
			>
				{!taxationDetails ? (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				) : (
					<Row gutter={16}>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Employee TPIN :</b>{' '}
								{taxationDetails.employeeTPIN || '-'}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Currency :</b>{' '}
								{taxationDetails.companyCurrency.currency ||
									'-'}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Employment Type :</b>{' '}
								{taxationDetails.employmentType || '-'}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Taxation Method :</b>{' '}
								{taxationDetails.taxationMethod}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Enable Gross Up Tax :</b>{' '}
								{displayBoolean(
									taxationDetails.enableGrossUpTax
								)}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Employee with Disabilities :</b>{' '}
								{displayBoolean(taxationDetails.disabilities)}
							</p>
						</Col>
						<Col span={12}>
							<p className={styles['card-text']}>
								<b>Termination Date :</b>{' '}
								{taxationDetails.terminationDate
									? formatDate(
											taxationDetails.terminationDate
									  )
									: '-'}
							</p>
						</Col>
					</Row>
				)}
			</Card>
		</div>
	);
};

export default TaxationDetails;
