import { Sidebar } from 'components/Global';

import { PermissionContext } from 'components/Global/AuthLayout';
import { FORMDATA } from 'constants/Data';
import { FC, useContext, useEffect, useState } from 'react';
import { checkPermission } from 'utils/utils';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';

// Settings page layout
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
	const { settingsMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	const permissionContext = useContext(PermissionContext);

	const [menuItems, setMenuItems] = useState(settingsMenuItems);

	const adminSidebarItems = ['coa', 'approval', 'notification'];

	const isUserPermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Users',
		permission: ['add', 'view', 'edit', 'delete'],
	});

	const isRolePermission = checkPermission(permissionContext.permissions, {
		permissionName: 'Roles',
		permission: ['add', 'view', 'edit', 'delete'],
	});

	useEffect(() => {
		let _menuItems = settingsMenuItems;

		if (!isUserPermission) {
			_menuItems = _menuItems.filter((item) => item?.key !== 'users');
		}
		if (!isRolePermission) {
			_menuItems = _menuItems.filter((item) => item?.key !== 'roles');
		}
		if (
			!permissionContext.allowedPermissions.includes(
				'Allow_Configuration'
			)
		) {
			_menuItems = _menuItems.filter(
				(item) => item?.key !== 'configuration'
			);
		}
		// if (
		// 	!permissionContext.allowedPermissions.includes(
		// 		'Allow_Payroll_Settings'
		// 	)
		// ) {
		// 	_menuItems = _menuItems.filter(
		// 		(item) => item?.key !== 'payroll-settings'
		// 	);
		// }
		if (!permissionContext.isAdmin) {
			_menuItems = _menuItems.filter(
				(item) => !adminSidebarItems.includes(item?.key)
			);
		}

		if (permissionContext.isSuperAdmin) {
			_menuItems = _menuItems.filter(
				(item) => item.key === 'users' || item.key === 'configuration'
			);
		}

		setMenuItems(_menuItems);
	}, [
		settingsMenuItems,
		permissionContext,
		isUserPermission,
		isRolePermission,
	]);

	// JSX
	return (
		<div className={styles['settings-layout']}>
			<div className={styles['settings-layout__wrapper']}>
				<Sidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['settings-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default SettingsLayout;
