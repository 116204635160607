import { Layout, Menu } from 'antd';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import './index.scss';
import { SidebarProps } from './types';
import {
	ApprovalIcon,
	ConfigurationsSvg,
	DashboardSvg,
	EmployeeSvg,
	NotificationSvgSettings,
	PayrollSettings,
	PayrollSvg,
	ReportSvg,
	RoleSvg,
	TimeActivitiesSvg,
	UsersSvg,
	CoaIcon,
} from 'utils/svgs';

const getKeyWiseIcons = {
	users: <UsersSvg />,
	roles: <RoleSvg />,
	configuration: <ConfigurationsSvg />,
	approval: <ApprovalIcon />,
	notification: <NotificationSvgSettings />,
	'payroll-wizard': <PayrollSettings />,
	dashboard: <DashboardSvg />,
	employees: <EmployeeSvg />,
	'time-activities-main': <TimeActivitiesSvg />,
	shifts: '',
	'time-logs': '',
	'time-activities': '',
	'time-sheets': '',
	'leave-request': '',
	'run-payroll-main': <PayrollSvg />,
	'mid-month': '',
	'loan-request': '',
	'calculate-statutory-components': '',
	'run-payroll': '',
	'payments':'',
	reports: <ReportSvg />,
	coa: <CoaIcon />,
};

const getSelectedKeyWiseIcons = {
	users: <UsersSvg isSelected={true} />,
	roles: <RoleSvg isSelected={true} />,
	configuration: <ConfigurationsSvg isSelected={true} />,
	approval: <ApprovalIcon isSelected={true} />,
	notification: <NotificationSvgSettings isSelected={true} />,
	'payroll-wizard': <PayrollSettings isSelected={true} />,
	dashboard: <DashboardSvg isSelected={true} />,
	employees: <EmployeeSvg isSelected={true} />,
	'time-activities-main': <TimeActivitiesSvg isSelected={true} />,
	shifts: '',
	'time-logs': '',
	'time-activities': '',
	'time-sheets': '',
	'leave-request': '',
	'run-payroll-main': <PayrollSvg isSelected={true} />,
	'mid-month': '',
	'loan-request': '',
	'calculate-statutory-components': '',
	'run-payroll': '',
	'payments': '',
	reports: <ReportSvg isSelected={true} />,
	coa: <CoaIcon isSelected={true} />,
};

// Global sidebar
const Sidebar: FC<SidebarProps> = (props) => {
	const { Sider } = Layout;
	const { handleSidebar, items, selectedSidebar } = props;
	const [itemsList, setItemsList] = useState(items);

	useEffect(() => {
		const updatedList = items?.map((item: any) => {
			if (item.icon) {
				if (item.key === selectedSidebar) {
					return {
						...item,
						icon: getSelectedKeyWiseIcons[
							item.key as keyof typeof getSelectedKeyWiseIcons
						],
					};
				}
				return {
					...item,
					icon: getKeyWiseIcons[
						item.key as keyof typeof getKeyWiseIcons
					],
				};
			} else {
				return item;
			}
		});
		setItemsList(updatedList);
	}, [selectedSidebar, items]);

	return (
		<Sider
			style={{
				background: '#fff',
				maxHeight: '100%',
				height: '100%',
				overflow: 'auto',
			}}
			className={styles.sidebar}
		>
			<div className={styles.sidebar__wrapper}>
				<Menu
					mode="vertical"
					selectedKeys={[selectedSidebar]}
					defaultSelectedKeys={[selectedSidebar]}
					items={itemsList}
					onClick={handleSidebar}
					className="menu-item "
					style={{
						background: '#fff',
						padding: 0,
					}}
				/>
			</div>
		</Sider>
	);
};

export default Sidebar;
