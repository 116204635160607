import { SideDrawerWrapper } from 'components/Global';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import SkillLevyHeader from './Header';
import SkillsLevyTable from './SkillsLevyTable';
import AddSkillLevySideDrawer from './AddSkillLevySideDrawer';
import HistoryModal from './HistoryModal';
import { skillLevyApi } from 'Api/skillLevy';

type skillsLevyTabProps = {
	skillsLevyData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const SkillsLevyComponent = (props: skillsLevyTabProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [latestSkillsLevyDetails, setLatestSkillsLevyDetails] = useState<any>([]);
	// const [personalLevyDetails, setPersonalLevyDetails] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);
	const [allSkillLevyData, setAllSkillLevyData] = useState<any>([]);

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const sidebarDrawerOpen = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchSkillLevyData = async () => {
		try {
			
			setIsLoading(true);
			 const SkillLevyData = await skillLevyApi.getLatestSkillLevy();
			 setLatestSkillsLevyDetails(SkillLevyData.data.data);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching SkillLevy Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};
	const fetchAllSkillLevyData = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			setIsHistoryLoading(true);
			 const allData = await skillLevyApi.getSkillLevyHistory(query);
			 const historyDetails = allData?.data?.data;

			 setAllSkillLevyData(historyDetails);

			 setTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching history', 'error');
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsHistoryLoading(false);
		}
	};
	useEffect(() => {
		fetchSkillLevyData();
	}, []);

	useEffect(() => {
		fetchAllSkillLevyData();
	}, [pageSize, currentPage, sortOrder, sortField]);

	return (
		<>
			<div>
				<SkillLevyHeader
					sidebarDrawerOpen={sidebarDrawerOpen}
					SkillLevyExists={latestSkillsLevyDetails.length > 0}
					isLoading={isLoading}
					fetchSkillLevyData={fetchSkillLevyData}
				/>
				<SkillsLevyTable
					skillsLevyData={latestSkillsLevyDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={
						latestSkillsLevyDetails.length > 0
							? 'Edit Skill Levy'
							: 'Add Skill Levy'
					}
					position="right"
					width="half"
				>
					<AddSkillLevySideDrawer
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchSkillLevyData={fetchSkillLevyData}
						fetchAllSkillLevyData={fetchAllSkillLevyData}
					/>
				</SideDrawerWrapper>
			)}
			{isModalOpen && (
				<HistoryModal
					closeModal={closeModal}
					open={isModalOpen}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					allSkillLevyData={allSkillLevyData}
					isLoading={isHistoryLoading}
					fetchAllSkillLevyData={fetchAllSkillLevyData}
				/>
			)}
		</>
	);
};

export default SkillsLevyComponent;
