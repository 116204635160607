import { postApi, getApi, getApiPDF, getApiCSV } from 'apis';

const generateNapsaReport = async (data: any) => {
	return await postApi('/reports/napsa', data);
};

const getNapsaReports = async (params: any) => {
	return await getApi('/reports/napsa', params);
};

const generateNhimaReport = async (data: any) => {
	return await postApi('/reports/nhima', data);
};

const getNhimaReports = async (params: any) => {
	return await getApi('/reports/nhima', params);
};

const generatePayeReport = async (data: any) => {
	return await postApi('/reports/paye', data);
};

const getPayeReports = async (params: any) => {
	return await getApi('/reports/paye', params);
};

const generatePayrollSummaryReport = async (data: any) => {
	return await postApi('/reports/payroll-summary', data);
};

const getPayrollSummaryReports = async (params: any) => {
	return await getApi('/reports/payroll-summary', params);
};

const generateP18Report = async (data: any) => {
	return await postApi('/reports/p18', data);
};

const getP18Reports = async (params: any) => {
	return await getApi('/reports/p18', params);
};
const getAttendanceReports = async (query: any) => {
	return await getApi('/reports/attendance-report', query);
};

const getPayPeriodReports = async (moduleName: string, year: string) => {
	return await getApi('/reports/pay-period-reports', {
		moduleName,
		year,
	});
};

const getPayPeriodJEReports = async (year: string) => {
	return await getApi('/reports/pay-period-je-reports', {
		year,
	});
};

const exportAttendanceReport = async (query: any) => {
	return await getApiPDF('/reports/export-attendance-report', query);
};

const generateJournalEntryReport = async (data: any) => {
	return await postApi('/reports/create-je-report', data);
};

const getJournalEntryReports = async (params: any) => {
	return await getApi('/reports/journal-entry', params);
};

const getPayrollEarningReports = async (params: any) => {
	return await getApi('/reports/earning-report', params);
};

const downloadReport = async (reportId: string) => {
	return await getApiPDF('/reports/download', {
		reportId: reportId,
	});
};

const downloadJournalEntryReport = async (reportId: string) => {
	return await getApiPDF('/reports/download-JeReport', {
		reportId: reportId,
	});
};

const downloadJournalEntryReportCSV = async (reportId: string) => {
	return await getApiCSV('/reports/download-je-csv', {
		reportId: reportId,
	});
};

const generatePayrollSumEmpReport = async (data: any) => {
	return await postApi('/reports/payroll-summary-emp-report', data);
};

const getPayrollSumEmpReports = async (params: any) => {
	return await getApi('/reports/payroll-summary-emp-report', params);
};

const getDeductionReports = async (params: any) => {
	return await getApi('/reports/deductions', params);
};

const getEarningReports = async (params: any) => {
	return await getApi('/reports/earning-report', params);
};

const generateDeductionReport = async (data: any) => {
	return await postApi('/reports/deductions', data);
};

const generateEarningReport = async (data: any) => {
	return await postApi('/reports/earning-report', data);
};

const getNetPayReports = async (params: any) => {
	return await getApi('/reports/net-pay', params);
};

const generateNetPayReport = async (data: any) => {
	return await postApi('/reports/net-pay', data);
};

export const reportApis = {
	generateNapsaReport,
	getNapsaReports,
	generateNhimaReport,
	getNhimaReports,
	generatePayeReport,
	getPayeReports,
	generatePayrollSummaryReport,
	getPayrollSummaryReports,
	generateP18Report,
	getP18Reports,
	getPayPeriodReports,
	getAttendanceReports,
	exportAttendanceReport,
	generateJournalEntryReport,
	getJournalEntryReports,
	getPayPeriodJEReports,
	getPayrollEarningReports,
	generateEarningReport,
	downloadReport,
	generatePayrollSumEmpReport,
	getPayrollSumEmpReports,
	getDeductionReports,
	generateDeductionReport,
	getEarningReports,
	getNetPayReports,
	generateNetPayReport,
	downloadJournalEntryReport,
	downloadJournalEntryReportCSV,
};
