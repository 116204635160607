import { Table } from 'antd';
import DownloadComponent from 'components/Global/Download';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	downloadReport: (reportId: string) => Promise<void>;
	setDownloadingReportId: (id: string | null) => void;
	downloadingReportId: string | null;
};

const GlobalReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriod"
					key="payPeriod"
					width={'20%'}
					className="bg-white"
					render={(text) => text?.name}
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					width={'18%'}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					width={'15%'}
					className="bg-white"
					render={(text) => text?.fullName}
				/>
				<Column
					title="No. of Employees"
					dataIndex="noOfEmployees"
					key="noOfEmployees"
					width={'18%'}
					sorter={true}
					className="bg-white"
				/>
				<Column
					title="Report"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					render={(text, record: any) => (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'start',
							}}
						>
							{record.documentUrl ? (
								<DownloadComponent
									documentUrl={record.documentUrl}
									showTooltip={true}
									downloadText={record.documentName}
									text={record.documentName}
									isLink={true}
									maxLength={40}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default GlobalReportTable;
