import { DatePicker, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
	name: string;
	label?: string;
	value: Dayjs; // Change the type of 'value' prop to 'Dayjs'
	required: boolean;
	isError: boolean;
	onChange: (value: Dayjs) => void; // Change the type of 'onChange' callback parameter to 'Dayjs'
	placeholder?: string;
	helperText?: string;
	disabled?: boolean;
	picker?: 'date' | 'month' | 'year';
	isViewOnly?: boolean;
	startTime?: Dayjs;
};

const TimePickerField = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		required = false,
		onChange,
		disabled = false,
		helperText = 'Time is required',
		isViewOnly = false,
		startTime,
	} = props;

	const handleChange = (value: Dayjs) => {
		onChange(value);
	};

	const disabledTime = () => {
		if (!startTime) {
			return {};
		}
		return {};
		// return {
		// 	disabledHours: () => {
		// 		const startHour = startTime.hour();
		// 		// Disable hours less than or equal to the start time's hour (e.g., 12, 1, and 2 PM if startTime is 2 PM)
		// 		return Array.from(Array(startHour + 1).keys()); // Include the selected hour by adding 1
		// 	},
		// 	disabledMinutes: (selectedHour: number) => {
		// 		if (selectedHour === startTime.hour()) {
		// 			// Disable minutes less than the start time's minute if in the same hour
		// 			const startMinute = startTime.minute();
		// 			return Array.from(Array(startMinute).keys());
		// 		}
		// 		return [];
		// 	},
		// };
	};

	return (
		<div className="input-field">
			{label && (
				<p
					className="label"
					style={{ marginBottom: `${isViewOnly ? '5px' : ''}` }}
				>
					{label}{' '}
					{required && !isViewOnly && <span className="red">*</span>}
				</p>
			)}
			{isViewOnly ? (
				<p>{dayjs(value).format('hh:mm a')}</p>
			) : (
				<div>
					<TimePicker
						name={name}
						value={dayjs(value, 'hh:mm a')}
						size="large"
						onChange={handleChange}
						format={'hh:mm a'}
						disabled={disabled}
						placeholder={placeholder}
						style={{ width: '100%' }}
						status={isError ? 'error' : ''}
						allowClear={false}
						disabledTime={
							name === 'endTime' ? disabledTime : undefined
						}
					/>
					{isError && (
						<p
							className="red"
							style={{
								fontSize: '12px',
								marginLeft: '2px',
							}}
						>
							{helperText}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default TimePickerField;
