import {
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Collapse, CollapseProps, Modal, Row, Spin } from 'antd';
import { companySetup } from 'Api/companySetup';
import { deleteApiWithData, postApi } from 'apis';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import CheckBox from 'components/Global/Checkbox';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import { currencyData } from 'constants/CurrencyData';
import { useContext, useEffect, useState } from 'react';
import { CrossRejectedSvg } from 'utils/svgs';
import {
	decrypt,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.scss';
import { Loader } from 'components/Global';

type Props = {
	onCancel: () => void;
	changeTab: (key: string) => void;
};

const encryptionKey: any = process.env.REACT_APP_ENCRYPTION_KEY;
export const BankDetails = (props: Props) => {
	const { onCancel } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [bankDetails, setBankDetails] = useState<any[]>([]);
	const [accountVerifiedLoading, setAccountVerifiedLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
	const [hasError, setHasError] = useState(false);

	const [idAndAccount, setIdAndAccount] = useState<any[]>([]);
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const [currentEditId, setCurrentEditId] = useState<string | null>(null);
	const [idAndCode, setIdAndCode] = useState<{ id: string; code: string }[]>(
		[]
	);
	const [key, setKey] = useState<string | string[] | null>(null);

	const permissionContext = useContext(PermissionContext);

	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Company_Setup');

	const isDelete = permissionContext.allowedPermissions.includes(
		'Delete_Company_Setup'
	);
	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancelNew',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onCancel();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save & Next',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];
	const confirmButtons = [
		{
			text: 'Proceed',
			isLoading: isLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				handleProceed();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				handleModalCancel();
			},
		},
	];
	const [formErrors, setFormErrors] = useState<any[]>([
		{
			id: false,
			accountType: false,
			accountNumber: false,
			accountName: false,
			branchName: false,
			// branchCode: false,
			sortCode: false,
			currency: false,
			// swiftCode: false,
			bankName: false,
		},
	]);

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		//	stateName: string,
		setupId: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (name === 'bankName' && value === 'STANBIC_BANK') {
			setBankDetails((prev) =>
				prev.map((setup: any) =>
					setup.id === setupId
						? {
								...setup,
								isVerified: true,
						  }
						: setup
				)
			);
		}

		if (name === 'bankName') {
			const swiftCodeMapping: any = {
				STANBIC_BANK: 'SBICZMLX',
				FIRST_CAPITAL_BANK: 'FRCGZMLU',
			};

			const swiftCode = swiftCodeMapping[value as string] || '';

			setBankDetails((prev) =>
				prev.map((setup: any) =>
					setup.id === setupId
						? {
								...setup,
								bankName: value,
								swiftCode,
						  }
						: setup
				)
			);
		}
		// if (name === 'accountNumber') {
		// 	const _idAndAccount = idAndAccount.find(
		// 		(bank) => bank.id === setupId
		// 	);
		// 	if (_idAndAccount) {
		// 		setBankDetails((prev) => [...prev, { isVerified: false }]);
		// 	}
		// }
		setBankDetails((prev) =>
			prev.map((setup: any) =>
				setup.id === setupId
					? {
							...setup,
							[name]: value,
					  }
					: setup
			)
		);

		// OnChange(value, name, stateName, id);
		OnChange(value, name, setupId);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		//	stateName: string,
		id: string
	) => {
		setBankDetails((prev: any) =>
			prev.map((setup: any) =>
				setup.id === id
					? {
							...setup,
							[key]: value,
					  }
					: setup
			)
		);

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formErrors.find((error) => error.id === id) }
		);
		setFormErrors((prev) =>
			prev.map((error) =>
				error.id === id
					? {
							...error,
							...checkFormError,
					  }
					: error
			)
		);
	};

	const handleSubmit = async () => {
		let valid = false;

		const updatedErrors = bankDetails.map((setup) => {
			let setUpId = setup.id;

			const checkFormError = validateFormData(setup, {
				...formErrors.find((error) => {
					return error.id === setup.id;
				}),
			});
			delete setup.id;
			if (!hasFormError(checkFormError)) {
				valid = true;
			}
			setup.id = setUpId;
			return { ...checkFormError, id: setUpId };
		});
		setFormErrors(updatedErrors);

		let anyError = false;

		updatedErrors.forEach((bankError) => {
			if (Object.values(bankError).includes(true)) {
				anyError = true;
			}
		});
		let verifyError = false;
		bankDetails.forEach((setup) => {
			const currencyCode = idAndCode.find((b) => b.id === setup.id);

			if (
				setup.bankName === 'FIRST_CAPITAL_BANK' &&
				setup.isFundTransfer &&
				!setup.isVerified &&
				setup.isFundTransfer === true
			) {
				toastText('Please verify the account', 'error');
				verifyError = true;
			}
			if (
				setup.bankName === 'FIRST_CAPITAL_BANK' &&
				currencyCode?.code !== setup.currency &&
				setup.isFundTransfer === true
			) {
				toastText(
					'account and selected currency should be same',
					'error'
				);
				verifyError = true;
			}
		});

		if (verifyError) {
			return;
		}
		if (anyError) {
			return;
		}

		if (!valid) {
			return;
		}

		setIsLoading(true);
		const result: any = {
			data: bankDetails.map((setup) => ({
				id: setup.id,
				// id: setup.dbId ? setup.dbId : '',
				accountName: setup.accountName,
				accountNumber: setup.accountNumber.trim(),
				branchName: setup.branchName,
				branchCode: setup.branchCode,
				sortCode: setup.sortCode,
				currency: setup.currency,
				accountType: setup.accountType,
				swiftCode: setup.swiftCode,
				bankName: setup.bankName,
				isFundTransfer: setup.isFundTransfer,
				isVerified: setup.isVerified,
			})),
		};

		try {
			const bankResponse = await postApi(
				'/company/bank-details/',
				result
			);
			const message =
				bankResponse?.data?.message ||
				'Bank details added successfully';
			setBankDetails(bankResponse.data.data);
			toastText(message, 'success');
			props.changeTab('currency');
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in adding  bank details';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const dropdownOptions = [
		{
			label: 'Checking',
			value: 'Checking',
		},
		{ label: 'Savings', value: 'Savings' },
		{ label: 'Fix Deposit', value: 'FixDeposit' },
	];

	const bankNameOptions = [
		{ label: 'Stanbic Bank', value: 'STANBIC_BANK' },
		{ label: 'First Capital Bank', value: 'FIRST_CAPITAL_BANK' },
	];

	const addBankSetup = () => {
		// const newId = bankDetails.length + 1;
		const _id = uuidv4();
		const newBankSetup = {
			id: _id,
			isNew: true, // Generate a temporary unique ID
			accountName: '',
			accountNumber: '',
			branchName: '',
			branchCode: '',
			sortCode: '',
			currency: '',
			accountType: '',
			swiftCode: '',
			bankName: null,
			isFundTransfer: false,
			isVerified: false,
			isDisabled: false,

			// Initialize other fields as needed
		};
		const newBankIdAndAccount = {
			id: _id,
			accountNumber: null,
			isVerified: false,
		};

		setIdAndAccount([...idAndAccount, newBankIdAndAccount]);
		setBankDetails([...bankDetails, newBankSetup]);
		setFormErrors((prev) => [
			...prev,
			{
				id: _id,
				// id: false,
				accountType: false,
				accountNumber: false,
				accountName: false,
				branchName: false,
				// branchCode: false,
				sortCode: false,
				currency: false,
				// swiftCode: false,
				bankName: false,
			},
		]);
	};

	const fetchBankDetails = async () => {
		setIsFetching(true);
		try {
			const response = await companySetup.getBankDetails();
			const fetchedSetups = [];

			for (const setup of response.data.data) {
				let accountNumber;

				try {
					accountNumber = setup.accountNumber
						? decrypt(setup.accountNumber, encryptionKey)
						: '';
				} catch (error) {
					accountNumber = setup.accountNumber || '';
				}

				fetchedSetups.push({
					id: setup.id,
					dbId: setup.id,
					accountNumber: accountNumber,
					accountName: setup.accountName,
					swiftCode: setup.swiftCode,
					branchCode: setup.branchCode,
					branchName: setup.branchName,
					sortCode: setup.sortCode,
					currency: setup.currency,
					accountType: setup.accountType,
					bankName: setup.bankType,
					isFundTransfer: setup.isFundTransfer,
					isVerified: setup.isVerified,
					isDisabled: true,
				});

				if (setup.bankType === 'FIRST_CAPITAL_BANK') {
					//push the id and currency code to the idAndCode array
					const currencyCode = setup.currency;
					const id = setup.id;
					setIdAndCode((prev) => [
						...prev,
						{ id, code: currencyCode },
					]);
				}
			}

			if (fetchedSetups.length === 0) {
				// Add a default item if no bank details are fetched
				fetchedSetups.push({
					id: '1',
					// id: 1,
					dbId: '',
					accountName: '',
					accountNumber: '',
					branchName: '',
					branchCode: '',
					sortCode: '',
					currency: null,
					accountType: '',
					swiftCode: '',
					bankName: null,
					isFundTransfer: false,
					isVerified: false,
					isDisabled: false,
				});
			}

			setBankDetails(fetchedSetups);

			const bankIdAndAccount = fetchedSetups.map((setup: any) => ({
				id: setup.id,
				accountNumber: setup.accountNumber,
				isVerified: setup.isVerified,
			}));

			setIdAndAccount(bankIdAndAccount);
			setFormErrors(
				fetchedSetups.map((setup: any) => ({
					id: setup.id,
					accountType: false,
					accountNumber: false,
					accountName: false,
					branchName: false,
					// branchCode: false,
					sortCode: false,
					currency: false,
					// swiftCode: false,
					bankName: false,
				}))
			);
		} catch (error) {
			// Handle error, show message to user if needed
		} finally {
			setIsFetching(false);
		}
	};

	const handleVerifyAccount = async (id: string, accountNumber: any) => {
		const bankDetail = bankDetails.find((b) => b.id === id);
		if (!bankDetail) return;

		try {
			setAccountVerifiedLoading(true);
			const response = await companySetup.verifyAccountApi({
				accountId: bankDetail.accountNumber,
			});

			const fcbCurrencyCode = response?.data?.data?.accountCurrencyCode;
			const currencyCode = idAndCode.find((b) => b.id === id);
			if (currencyCode) {
				const _idAndCode = idAndCode.map((b) =>
					b.id === id ? { ...b, code: fcbCurrencyCode } : b
				);
				setIdAndCode(_idAndCode);
			} else {
				setIdAndCode([...idAndCode, { id, code: fcbCurrencyCode }]);
			}

			if (response?.data?.responseStatus === 200) {
				toastText('Account verified successfully', 'success');
				setBankDetails((prev) =>
					prev.map((b) =>
						b.id === id ? { ...b, isVerified: true } : b
					)
				);
				setIdAndAccount((prev) =>
					prev.map((b) =>
						b.id === id
							? {
									...b,
									isVerified: true,
									accountNumber: bankDetail.accountNumber,
							  }
							: b
					)
				);
			}
		} catch (error) {
			setBankDetails((prev) =>
				prev.map((b) => (b.id === id ? { ...b, isVerified: false } : b))
			);
			setIdAndAccount((prev) =>
				prev.map((b) =>
					b.id === id
						? {
								...b,
								isVerified: false,
								accountNumber: '',
						  }
						: b
				)
			);
			toastText('Account verification failed', 'error');
		} finally {
			setAccountVerifiedLoading(false);
		}
	};

	useEffect(() => {
		// Fetch predefined values when the modal opens
		fetchBankDetails();
	}, []);

	// const showDeleteModal = (id: string) => {
	// 	setCurrentDeleteId(id);
	// 	setIsModalOpen(true);
	// };
	const showDeleteModal = (e: any, id: string) => {
		e.stopPropagation();
		const bankDetail = bankDetails.find((b) => b.id === id);
		if (bankDetail?.isNew) {
			// Handle removal of new bank detail
			setBankDetails(bankDetails.filter((b) => b.id !== id));
		} else {
			// Proceed with the confirmation modal for existing bank details
			setIsModalOpen(true);
			setCurrentDeleteId(id);
		}
	};

	const handleOk = async () => {
		if (currentDeleteId) {
			setIsDeleteLoading(true);
			try {
				await deleteApiWithData('/company/bank-details', {
					bankId: currentDeleteId,
				});
				await fetchBankDetails();
				toastText('Bank deleted successfully', 'success');
			} catch (error) {
				toastText('Error deleting bank', 'error');
			} finally {
				setIsDeleteLoading(false);
				setIsModalOpen(false);
			}
		}
	};

	const showConfirmModal = (e: any, id: string) => {
		e.stopPropagation();
		setCurrentEditId(id);
		setIsConfirmModalVisible(true);
	};

	const handleProceed = () => {
		const _bankDetails = bankDetails.map((b) => {
			if (b.id === currentEditId) {
				return { ...b, isDisabled: false };
			}
			return b;
		});
		setBankDetails(_bankDetails);
		setKey(currentEditId || '');

		setIsConfirmModalVisible(false);
	};
	const handleModalCancel = () => {
		setIsConfirmModalVisible(false);
		setCurrentEditId(null);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const currencyOptions = Object.keys(currencyData).map((key) => ({
		label: `${key} - ${currencyData[key]}`,
		value: key,
	}));

	const items: CollapseProps['items'] = bankDetails.map(
		(setup: any, index: any) => {
			const displayName = setup.accountName
				? `Bank ${index + 1} (${setup.accountName})`
				: `Bank ${index + 1}`;
			return {
				key: setup.id,
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							fontSize: '1.8rem',
							fontWeight: 500,
						}}
						className="color-purple"
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							{/* {`Bank ${index + 1} */}
							{displayName}
							{setup.isVerified && setup.isFundTransfer && (
								<>
									<div className={styles.completedLabel}>
										<CheckCircleOutlined /> Verified
									</div>
								</>
							)}
							{!setup.isVerified && setup.isFundTransfer && (
								<>
									<>
										<div className={styles.unVerified}>
											<CrossRejectedSvg /> Not Verified
										</div>
									</>
								</>
							)}
						</div>
						<div
							style={{
								display: 'flex',
								gap: '2rem',
							}}
						>
							{setup.accountNumber && (
								<div>
									<EditOutlined
										style={{
											cursor: 'pointer',
										}}
										onClick={(e) =>
											showConfirmModal(e, setup.id)
										}
									/>
								</div>
							)}
							<div>
								{isDelete && (
									<DeleteOutlined
										style={{
											color: 'red',
											cursor: 'pointer',
										}}
										onClick={(e) =>
											showDeleteModal(e, setup.id)
										}
									/>
								)}
							</div>
						</div>
					</div>
				),
				children: (
					<div className={styles['form-container']}>
						<Row
							className={styles['form-container-card']}
							gutter={30}
						>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<SelectDropdown
									placeholder="Select Bank Name"
									disabled={setup.isDisabled}
									options={bankNameOptions}
									size="large"
									required={true}
									helperText="Bank Name is required"
									label="Bank Name"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.bankName
									}
									value={setup.bankName}
									onChange={(value: any) => {
										handleChange(
											value,
											'bankName',
											setup.id,
											true
										);
									}}
								/>
							</Col>

							<Col
								span={
									setup.isFundTransfer &&
									!idAndAccount.find(
										(bank) =>
											bank.accountNumber ===
												setup.accountNumber &&
											bank.id === setup.id
									)
										? 5
										: 8
								}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="accountNumber"
									value={setup.accountNumber}
									label="Account Number"
									required={true}
									helperText="Account number required"
									disabled={setup.isDisabled}
									onChange={(value: any) => {
										handleChange(
											value,
											'accountNumber',
											//	'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountNumber
									}
								/>
							</Col>
							{setup.isFundTransfer &&
								!idAndAccount.find(
									(bank) =>
										bank.accountNumber ===
											setup.accountNumber &&
										bank.id === setup.id
								) && (
									<>
										<Col
											span={3}
											className={`${styles['col']}`}
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '0px',
												marginTop: '38px',
											}}
										>
											<Button
												type="primary"
												loading={accountVerifiedLoading}
												onClick={() => {
													handleVerifyAccount(
														setup.id,
														setup.accountNumber
													);
												}}
												style={{
													minWidth: '13rem',
													minHeight: '4rem',
												}}
												size="middle"
											>
												{' '}
												Verify{' '}
											</Button>
										</Col>
									</>
								)}
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<SelectDropdown
									placeholder="Select Currency"
									disabled={setup.isDisabled}
									options={[
										{ label: 'Select Currency', value: '' },
										...currencyOptions,
									]}
									value={setup.currency}
									onChange={(value: any) => {
										handleChange(
											value,
											'currency',
											setup.id,
											true
										);
									}}
									size="large"
									required={true}
									helperText="Currency  is required"
									label="Currency"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.currency
									}
								/>
							</Col>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="accountName"
									value={setup.accountName}
									label="Account Name"
									required={true}
									helperText="Account name required"
									disabled={setup.isDisabled}
									onChange={(value: any) => {
										handleChange(
											value,
											'accountName',
											//	'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountName
									}
								/>
							</Col>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="branchCode"
									value={setup.branchCode}
									label="Branch Code"
									disabled={setup.isDisabled}
									required={false}
									helperText="Branch code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'branchCode',
											//	'bankDetails',
											setup.id,
											false
										);
									}}
									isError={false}
									// isError={
									// 	formErrors.find(
									// 		(error: any) =>
									// 			error.id === setup.id
									// 	)?.branchCode
									// }
								/>
							</Col>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="branchName"
									value={setup.branchName}
									disabled={setup.isDisabled}
									label="Branch Name"
									required={true}
									helperText="Branch Name required"
									onChange={(value: any) => {
										handleChange(
											value,
											'branchName',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.branchName
									}
								/>
							</Col>

							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<SelectDropdown
									placeholder="Account Type"
									options={dropdownOptions}
									disabled={setup.isDisabled}
									value={setup.accountType}
									onChange={(value: any) => {
										handleChange(
											value,
											'accountType',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									size="large"
									required={true}
									helperText="Account Type is required"
									label="Account Type"
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.accountType
									}
								/>
							</Col>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="swiftCode"
									value={setup.swiftCode}
									disabled={true}
									label="SWIFT/BIC code"
									required={false}
									helperText="Shift Code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'swiftCode',
											//			'bankDetails',
											setup.id,
											false
										);
									}}
									isError={false}
									// isError={
									// 	formErrors.find(
									// 		(error: any) =>
									// 			error.id === setup.id
									// 	)?.swiftCode
									// }
								/>
							</Col>
							<Col
								span={8}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="sortCode"
									value={setup.sortCode}
									label="Sort Code"
									required={true}
									disabled={setup.isDisabled}
									helperText="Sort code required"
									onChange={(value: any) => {
										handleChange(
											value,
											'sortCode',
											//		'bankDetails',
											setup.id,
											true
										);
									}}
									isError={
										formErrors.find(
											(error: any) =>
												error.id === setup.id
										)?.sortCode
									}
								/>
							</Col>
							{setup.bankName !== 'STANBIC_BANK' && (
								<Col
									span={24}
									className={styles['checkbox-container']}
								>
									<CheckBox
										label="Is Fund Transfer"
										disabled={setup.isDisabled}
										checked={setup.isFundTransfer}
										onChange={(value: any) => {
											handleChange(
												value,
												'isFundTransfer',
												setup.id,
												true
											);
										}}
									/>
								</Col>
							)}
						</Row>
					</div>
				),
			};
		}
	);

	return (
		// <>
		<div>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['form-container']}>
				<div className={styles['company-header']}>
					<Row className={styles['header']} gutter={20}>
						{' '}
						<Col span={24}>
							<h2>Bank Details</h2>{' '}
						</Col>{' '}
					</Row>

					{isAdd && (
						<Button
							// icon={<PlusOutlined />}
							type="primary"
							onClick={addBankSetup}
							style={{ minWidth: '12rem' }}
							size="large"
						>
							Add Bank
						</Button>
					)}
				</div>
				<div className={styles['scrollable-content']}>
					{!isFetching ? (
						<Collapse
							accordion
							activeKey={key ?? undefined}
							onChange={(key) => setKey(key as string)}
							defaultActiveKey={['1']}
							items={items}
							expandIconPosition="start"
							className={styles['bank--employeeDetails']}
							expandIcon={({ isActive }) => (
								<div
									style={{
										transform: `rotate(${
											isActive ? -90 : 0
										}deg)`,
										transition: '0.3s',
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										className="bi bi-chevron-right"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 0 .708l6 6a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708.708L2.707 4.5 1.646 5.561a.5.5 0 0 1-.708-.708z"
										/>
									</svg>
								</div>
							)}
						/>
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<Loader />
						</div>
					)}
				</div>
				<Buttons buttons={buttons} />
				<ConfirmDelete
					isModalOpen={isModalOpen}
					handleOk={handleOk}
					handleCancel={handleCancel}
					deleteHandler={handleOk}
					isLoading={isDeleteLoading}
				/>
			</div>
			<Modal
				title={
					<>
						<div className={styles['confirm-modal']}>
							<ExclamationCircleOutlined
								style={{
									color: 'red',
									alignItems: 'center',
								}}
							/>
						</div>
					</>
				}
				className={styles['confirm-modal-container']}
				open={isConfirmModalVisible}
				closable={false}
				onCancel={handleModalCancel}
				footer={[
					<div
						key={'wrapper'}
						className={styles['confirm-modal__button']}
					>
						<Buttons buttons={confirmButtons}></Buttons>
					</div>,
				]}
			>
				<p className={styles['confirm-modal-text']}>
					You are trying to Edit critical details, We would not be
					able to proceed ahead with any payrolls until these details
					are not verified & approved by Admin.<br></br> Do you want
					to proceed
				</p>
			</Modal>
		</div>
	);
};
