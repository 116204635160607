import { employeeApi } from 'Api/employee';
import { Button, Checkbox, Col, Divider, Input, Modal, Row, Table } from 'antd';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { formatNumber, invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { EarningsModalProps } from './type';
import { CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import { useSelector } from 'react-redux';
import { leaveRequestApi } from 'Api/leaveRequest';
import Ellipse from 'components/Global/Ellipse';
import Column from 'antd/es/table/Column';

const EarningsModal: React.FC<EarningsModalProps> = ({
	onDataChange,
	onLoadingChange,
	year,
	month,
	employeeId,
	activeTab,
	selectedPayPeriod,
	isUSD,
}) => {
	const [data, setData] = useState<any>();
	const [earningsData, setEarningsData] = useState<any>();
	const [showAll, setShowAll] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [leaveEncashModal, setLeaveEncashModal] = useState<boolean>(false);
	const [leaveDataLoading, setLeaveDataLoading] = useState<boolean>(false);
	const [leaveEncashmentData, setLeaveEncashmentData] = useState<any>({});
	const [otherLeaveEncashData, setOtherLeaveEncashData] = useState<any>({});
	const [leaveSaveLoading, setLeaveSaveLoading] = useState<boolean>(false);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	const { baseCurrencySymbol, usdCurrencySymbol } = profileData;

	const updateData = (newData: any) => {
		setEarningsData(
			showAll ? newData?.filter((item: any) => item.isDefault) : newData
		);
		onDataChange(newData);
	};

	const closeConfirmationModal = () => {
		setLeaveEncashModal(false);
	};

	const handleOkForModal = async () => {
		const updatedData = data.map((item: any) => {
			if (item.description === 'LEAVE PAY') {
				return {
					...item,
					amount: String(leaveEncashmentData?.leaveEncashAmount),
				};
			}
			return item;
		});

		try {
			setLeaveSaveLoading(true);
			const finalData = {
				payPeriod: selectedPayPeriod,
				employeeId,
				data: updatedData,
			};
			await employeeApi.enCashLeave(finalData);
			toastText('leave Encash SuccessFully', 'success');
			closeConfirmationModal();
			fetchEmployeeEarnings();
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		} finally {
			setLeaveSaveLoading(false);
		}
	};

	const openLeaveEncashmentModal = async () => {
		try {
			setLeaveDataLoading(true);
			const apiData = await leaveRequestApi.leaveEncashmentDetails({
				employeeId,
				payPeriodId: selectedPayPeriod,
			});
			if (apiData.data?.data) {
				const response = apiData.data?.data;
				setLeaveEncashmentData(response?.data);
				setOtherLeaveEncashData(response.otherData);
			}
			setLeaveEncashModal(true);
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		} finally {
			setLeaveDataLoading(false);
		}
	};

	const confirmModalButtons = [
		{
			text: 'Cancel',
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			isLoading: false,
			disabled: leaveDataLoading || leaveSaveLoading,
			onclick: closeConfirmationModal,
		},
		{
			text: 'Pay',
			isLoading: leaveSaveLoading,
			disabled: leaveDataLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: handleOkForModal,
		},
	];

	const handleChange = (index: string, value: string | boolean) => {
		let newData = [...data];
		if (typeof value === 'boolean') {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return { ...item, isChecked: value };
				}
				return item;
			});
		} else {
			newData = data.map((item: any) => {
				if (item.id === index) {
					return {
						...item,
						amount: isNaN(parseFloat(value))
							? parseFloat('0.00')
							: value,
					};
				}
				return item;
			});
		}
		setData(newData);
		updateData(newData);
	};

	const handleBlur = (index: number) => {
		let newData = [...data];
		newData = data.map((item: any) => {
			if (item.id === index) {
				return {
					...item,
					amount:
						isNaN(item.amount) || item.amount < 0
							? '0.00'
							: parseFloat(item.amount).toFixed(2),
				};
			}
			return item;
		});
		setData(newData);
		updateData(newData);
	};

	const fetchEmployeeEarnings = async () => {
		setIsLoading(true);
		try {
			// const selectedYear = dayjs(year).year();
			const apiData = await employeeApi.getEmployeeEarnings({
				payPeriodId: selectedPayPeriod,
				employeeId,
			});
			if (apiData?.data?.data?.length > 0) {
				setData(apiData.data.data);
			}
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		}
		setIsLoading(false);
	};

	const renderItems = (items: any) =>
		items?.map((item: any) => (
			<Col key={item.id} span={12}>
				<div className={styles.earningsFields}>
					<div className="checkbox">
						<Checkbox
							checked={item.isChecked}
							onChange={() =>
								handleChange(item?.id, !item.isChecked)
							}
						/>
					</div>
					<label className={styles.label}>
						<Ellipse
							maxLength={20}
							message={item?.description.toUpperCase()}
							isTooltip={true}
							tooltipMessage={item?.description.toUpperCase()}
							key={item?.description.toUpperCase()}
						/>
					</label>
					<div className={styles.inputContainer}>
						<Input
							value={item?.amount ?? 0}
							onChange={(e) =>
								handleChange(item?.id, e.target.value)
							}
							onBlur={() => handleBlur(item?.id)}
							type="number"
							suffix={
								isUSD ? usdCurrencySymbol : baseCurrencySymbol
							}
							disabled={item.isDisable}
							className={styles.input}
						/>

						{item.description === 'LEAVE PAY' && (
							<Button
								style={{
									border: 'none',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									background: '#584495',
									fontSize: '1.6rem',
									width: '125px',
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => openLeaveEncashmentModal()}
							>
								Leave Encash
							</Button>
						)}
					</div>
				</div>
			</Col>
		));

	const toggleShowAll = () => {
		setShowAll(!showAll);
	};

	const defaultItems = earningsData?.filter((item: any) => item.isDefault);
	const nonDefaultItems = earningsData?.filter(
		(item: any) => !item.isDefault
	);

	useEffect(() => {
		setEarningsData(
			showAll ? data?.filter((item: any) => item.isDefault) : data
		);
	}, [showAll, data]);

	useEffect(() => {
		onDataChange(data);
	}, [data]);
	useEffect(() => {
		onLoadingChange(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (activeTab === 'earnings' && !invalidText(selectedPayPeriod)) {
			fetchEmployeeEarnings();
		}
	}, [selectedPayPeriod, activeTab]);

	return (
		<>
			<div className="earningsFields">
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: 'auto',
						}}
					>
						<Loader />
					</div>
				) : (
					<>
						<div className={styles.earningItems}>
							<Row gutter={12}>{renderItems(defaultItems)}</Row>
							<Row gutter={12} className="extra-earnings">
								{!showAll && (
									<hr
										className="color-purple"
										style={{
											width: '100vw',
											marginBottom: '20px',
										}}
									/>
								)}
								{renderItems(nonDefaultItems)}
							</Row>
							{selectedPayPeriod && (
								<Row>
									<Col span={24}>
										<p style={{ float: 'right' }}>
											<Button
												style={{ border: 'none' }}
												className={styles.showMoreLink}
												onClick={toggleShowAll}
											>
												{showAll
													? 'Show More'
													: 'Show Less'}
											</Button>
										</p>
									</Col>
								</Row>
							)}
						</div>
					</>
				)}
			</div>

			{leaveEncashModal && (
				<>
					<Modal
						open={leaveEncashModal}
						onCancel={closeConfirmationModal}
						footer={null}
						closable={false}
						width={900}
						style={{
							borderRadius: '10px',
						}}
						className="confirmation-modal"
						centered
					>
						<div className={`${styles['modal']} modal`}>
							<div className={styles['modal-header']}>
								<Title level={4}>
									Leave Details of{' '}
									{otherLeaveEncashData?.empName} |{' '}
									<span
										style={{
											color: '#584495',
										}}
									>
										{otherLeaveEncashData?.empCode}
									</span>
								</Title>
								<div
									className={styles['close-icon']}
									onClick={closeConfirmationModal}
								>
									<CloseOutlined />
								</div>
							</div>
							{leaveDataLoading ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: 'auto',
									}}
								>
									<Loader />
								</div>
							) : (
								<>
									<div className={styles['modal-body']}>
										<div className={styles['modal-row']}>
											<div
												className={styles['bold-text']}
											>
												Hourly Salary Rate :{' '}
												<span className="color-purple">
													{
														otherLeaveEncashData.hourlySalary
													}
												</span>
											</div>
										</div>
										<div className="leave-table">
											<Table
												dataSource={leaveEncashmentData}
												rowKey={(record) => record.id}
												loading={isLoading}
												scroll={{
													y: 300,
												}}
												pagination={false}
											>
												<Column
													title="Leave Name"
													dataIndex="leaveManagementName"
													key="leaveManagementName"
													className="bg-white"
												/>
												<Column
													title="Total Leaves"
													dataIndex="totalLeaves"
													key="totalLeaves"
													className="bg-white"
												/>
												<Column
													title="Taken Leaves"
													dataIndex="takenLeaves"
													key="takenLeaves"
													className="bg-white"
												/>
												<Column
													title="Available Leaves"
													dataIndex="remainingLeaves"
													key="remainingLeaves"
													className="bg-white"
												/>
												<Column
													title="Amount"
													dataIndex="totalAmount"
													key="totalAmount"
													className="bg-white"
													render={(text) =>
														formatNumber(
															text,
															profileData.CompanyCurrencies,
															isUSD
														)
													}
												/>
											</Table>
										</div>
										{/* </div> */}
										{/* <div className={styles['modal-row']}>
											<div
												className={styles['bold-text']}
											>
												Leaves Taken
											</div>
											<div className="red">
												{' '}
												{
													leaveEncashmentData?.usedLeaves
												}
											</div>
										</div>
										<div className={styles['modal-row']}>
											<div
												className={styles['bold-text']}
											>
												Available Leaves
											</div>
											<div>
												{' '}
												{
													leaveEncashmentData?.remainingLeaves
												}
											</div>
										</div>
										<div className={styles['modal-row']}>
											<div
												className={styles['bold-text']}
											>
												Hourly Salary Rate
											</div>
											<div className="color-purple">
												{' '}
												{
													leaveEncashmentData?.hourlySalary
												}
											</div>
										</div> */}
										<Divider
											style={{
												borderTop: '1px solid #E0E0E0',
												margin: '10px 0',
											}}
										/>
										<div className={styles['modal-row']}>
											<div
												className={styles['bold-text']}
											>
												Total Leave encashment :{' '}
												<span className="color-purple">
													{formatNumber(
														otherLeaveEncashData.leaveEncashAmount,
														profileData.CompanyCurrencies,
														isUSD
													)}
												</span>
											</div>
										</div>
										{/* </div> */}
										<div
											className={styles['modal-buttons']}
										>
											{selectedPayPeriod && (
												<Buttons
													buttons={
														confirmModalButtons
													}
												/>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</Modal>
				</>
			)}
		</>
	);
};

export default EarningsModal;
