import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

type Props = {
	name: string;
	label?: string;
	value: string;
	required: boolean;
	isError: boolean;
	onChange: (value: string) => void;
	placeholder?: string;
	helperText?: string;
	disabled?: boolean;
	picker?: 'date' | 'month' | 'year';
	disabledBeforeDates?: any;
	disabledAfterDates?: any;
	className?: string;
};

const DateRangePickerField = (props: Props) => {
	const { RangePicker } = DatePicker;
	const {
		name,
		label,
		value,
		placeholder,
		className,
		isError = false,
		required = false,
		onChange,
		disabled = false,
		helperText = 'Date is required',
		picker = 'date',
		disabledBeforeDates,
		disabledAfterDates,
	} = props;

	const handleChange = (value: any) => {
		onChange(value);
	};

	const rangePresets: TimeRangePickerProps['presets'] = [
		{ label: 'Today', value: [dayjs(), dayjs()] },
		{
			label: 'This Week',
			value: [dayjs().startOf('week'), dayjs().endOf('week')],
		},
		{
			label: 'This Month',
			value: [dayjs().startOf('month'), dayjs().endOf('month')],
		},
		{ label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs()] },
		{ label: 'Previous Week', value: [dayjs().add(-7, 'd'), dayjs()] },
		{ label: 'Previous Month', value: [dayjs().add(-30, 'd'), dayjs()] },
	];

	return (
		<div className="input-field">
			{label && (
				<p className="label">
					{label} {required && <span className="red">*</span>}
				</p>
			)}
			<div>
				<RangePicker
					className={className}
					defaultValue={[
						dayjs('2015/01/01', 'DD/MM/YYYY'),
						dayjs('2015/01/01', 'DD/MM/YYYY'),
					]}
					format={'DD/MM/YYYY'}
					name={name}
					value={[dayjs(value[0]), dayjs(value[1])]}
					onChange={handleChange}
					presets={rangePresets}
					disabled={disabled}
					// disabledDate={(current: Dayjs) => {
					// 	return current && current > dayjs();
					// }}
					allowClear={false}
					size="large"
				/>
				{/* <DatePicker
					name={name}
					value={value}
					size="large"
					onChange={handleChange}
					format={
						picker === 'year'
							? 'YYYY'
							: picker === 'month'
							? 'MMM, YYYY'
							: 'DD/MM/YYYY'
					}
					disabled={disabled}
					placeholder={placeholder}
					style={{ width: '100%' }}
					status={isError ? 'error' : ''}
					picker={picker}
					// disabledDate={(current: Dayjs) => {
					// 	return (
					// 		(disabledBeforeDates &&
					// 			current < dayjs(disabledBeforeDates)) ||
					// 		(disabledAfterDates &&
					// 			current > dayjs(disabledAfterDates))
					// 	);
					// }}
				/> */}
				{isError && (
					<p
						className="red"
						style={{
							fontSize: '12px',
							marginLeft: '2px',
						}}
					>
						{helperText}
					</p>
				)}
			</div>
		</div>
	);
};

export default DateRangePickerField;
