import { postApi, getApi, putApi, deleteApi } from 'apis';

const createShifts = async (data: any) => {
	return await postApi('/shift', data);
};

const getShifts = async (data: any) => {
	return await getApi('/shift', data);
};

const getShiftDetails = async (id: any) => {
	return await getApi(`/shift/${id}`);
};

const updateShift = async (id: string, data: any) => {
	return await putApi(`/shift/${id}`, data);
};

const deleteShift = async (id: string) => {
	return await deleteApi(`/shift/${id}`);
};

const validateShift = async (data: any) => {
	return await postApi('/shift/validate-shift', data);
};

const getSuperVisorOptions = async () => {
	return await getApi('/shift/supervisor-options');
};

export const shiftsApi = {
	getShifts,
	createShifts,
	updateShift,
	deleteShift,
	getShiftDetails,
	validateShift,
	getSuperVisorOptions,
};
