import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
import GlobalHeader from '../../GlobalHeader';
// import styles from './index.module.scss';
// import './index.scss';
const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	setWorkHoursJSON: any;
	onlyView: boolean;
	setIsUniversalLoading: any;
	headerData: any;
};
const WorkingHoursTable = (props: Props) => {
	const {
		selectedPayrollId,
		setWorkHoursJSON,
		onlyView,
		setIsUniversalLoading,
		headerData,
	} = props;
	const [workHoursData, setWorkHoursData] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(30);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const { data } = useSelector((state: any) => state?.userProfile);

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const fetchWorkHours = async (payrollId: string) => {
		try {
			setIsLoading(true);
			setIsUniversalLoading(true);
			const workHours = await payrollApi.getAllEmployeeWorkHours({
				payrollId,
				page: page,
				pageSize: pageSize,
			});
			setWorkHoursData(workHours.data.data);
			setTotalRecords(workHours.data.totalRecords);
			setWorkHoursJSON(workHours.data.data);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	const fetchWorkHoursForView = async (payrollId: string) => {
		try {
			setIsLoading(true);

			setIsUniversalLoading(true);
			const workHours = await viewPayrollApi.getAllEmployeeWorkHours({
				payrollId,
				page: page,
				pageSize: pageSize,
			});
			setWorkHoursData(workHours.data.data);
			setTotalRecords(workHours.data.totalRecords);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
			setIsUniversalLoading(false);
		}
	};

	useEffect(() => {
		if (onlyView) {
			fetchWorkHoursForView(selectedPayrollId);
		} else {
			fetchWorkHours(selectedPayrollId);
		}
	}, [selectedPayrollId, page, pageSize]);

	return (
		<>
			<div className="payroll-steps-table ">
				<GlobalHeader
					headerData={headerData}
					toggleRequired={true}
					fetchAllData={() => fetchWorkHours(selectedPayrollId)}
					isLoading={isLoading}
				/>
				<Table
					dataSource={workHoursData}
					scroll={{ y: 'calc(90vh - 400px)' }}
					pagination={{
						total: totalRecords,
						current: page,
						pageSize: pageSize,
						showSizeChanger: false,
						// onShowSizeChange: pageSizeHandler,
						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					// pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
					onChange={tableChangeHandler}
				>
					<Column
						title="Employee Name | Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'20%'}
						className="bg-white"
						// sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record.employeeCode}
								</span>
							</>
						)}
					/>

					<Column
						title="Regular Hours"
						dataIndex="regularHours"
						key="regularHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Weekday OT"
						dataIndex="weekdaysOT"
						key="weekdaysOT"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Sunday OT"
						dataIndex="sundayOT"
						key="sundayOT"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Shift Difference"
						dataIndex="timeShiftHours"
						key="timeShiftHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
					<Column
						title="Hourly rate"
						dataIndex="hourlySalary"
						key="hourlySalary"
						width={'10%'}
						className="bg-white"
						render={(value: any) => {
							if (!usdCurrencyToggle && isUsdCurrency) {
								return formatNumber(
									Number(value),
									data?.CompanyCurrencies,
									true
								);
							} else {
								return formatNumber(
									Number(value * (conversionRate ?? 1)),
									data?.CompanyCurrencies
								);
							}
						}}
					/>
					<Column
						title="Total Hours"
						key="totalHours"
						dataIndex="totalHours"
						width={'10%'}
						className="bg-white"
						// sorter={true}
					/>
				</Table>
			</div>
		</>
	);
};
export default WorkingHoursTable;
