import React, { useContext } from 'react';

import './index.scss';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import PayrollOverview from './PayrollOverview';
import PayrollHistory from './PayrollHistory';
import { PermissionContext } from 'components/Global/AuthLayout';
import PaymentComponent from '../Payments';

const PayrollComponent = () => {
	const [activeKey, setActiveKey] = React.useState('payrollOverview');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};
	const context = useContext(PermissionContext);

	let tabItems = [
		{
			key: 'payrollOverview',
			label: 'Payroll Overview',
			children: <PayrollOverview />,
		},
		{
			key: 'payrollHistory',
			label: 'Payroll History',
			children: <PayrollHistory />,
		},
	];

	if (context.isAdmin) {
		tabItems.push({
			key: 'paymentLogs',
			label: 'Payment Logs',
			children: <PaymentComponent />,
		});
	}
	return (
		<div className={styles['payroll-components']}>
			<p>Payroll</p>

			<div className="payroll-components">
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					items={tabItems}
					destroyInactiveTabPane={true}
					onChange={(key) => changeTab(key)}
					style={{
						fontSize: '18px',
						marginBottom: '40px',
					}}
				/>
			</div>
		</div>
	);
};

export default PayrollComponent;
