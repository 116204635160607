import { ClockCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	ActiveStatusSvg,
	CompletedSvg,
	DeleteActionSvg,
	EditActionSvg,
	PlannedSvg,
} from 'utils/svgs';
import { formatDate } from 'utils/utils';
import AddLogsModal from '../AddLogsModal';
import ViewShiftModal from '../ViewShiftModal';
import styles from './index.module.scss';

type Props = {
	openCreateShiftModal: () => void;
	setEditShift: any;
	tableData: any;
	currentPage: number;
	pageSize: number;
	isLoading: boolean;
	tableChangeHandler: any;
	totalRecords: number;
	setIsShiftDeleteModalOpen: any;
	fetchShifts: any;
	openViewShiftModal: any;
	isUserSupervisor: boolean;
};

const ShiftTable = (props: Props) => {
	const {
		openCreateShiftModal,
		setEditShift,
		tableData,
		tableChangeHandler,
		currentPage,
		pageSize,
		isLoading,
		totalRecords,
		setIsShiftDeleteModalOpen,
		fetchShifts,
		openViewShiftModal,
		isUserSupervisor,
	} = props;
	const permissions = useContext(PermissionContext);

	const [isAddLogsModalOpen, setIsAddLogsModalOpen] = useState(false);
	const [selectedShift, setSelectedShift] = useState<any>({});
	const [selectedShiftDetails, setSelectedShiftDetails] = useState<any>({});
	const [shiftStartDate, setShiftStartDate] = useState<any>({});
	const [shiftEndDate, setShiftEndDate] = useState<any>({});
	const [isShiftDetailsModalOpen, setIsShiftModalOpen] = useState(false);
	const [shiftDetails, setShiftDetails] = useState(null);
	const [selectedShiftLastDate, setSelectedShiftLastDate] =
		useState<any>(null);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<div>
			<Table
				dataSource={tableData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Shift Name"
					dataIndex="shiftName"
					key="shiftName"
					sorter={true}
					className="bg-white"
					width={'20%'}
					render={(record: any, data: any) => {
						return (
							<p
								style={{ cursor: 'pointer', color: 'blue' }}
								onClick={() => {
									setIsShiftModalOpen(true);
									setShiftDetails(data);
									// setEditShift(data);
									// openViewShiftModal(true);
								}}
							>{`${record} (${formatDate(
								data.startDate,
								profileData?.companyDateFormat ?? 'DD/MM/YYYY'
							)} - ${formatDate(
								data.endDate,
								profileData?.companyDateFormat ?? 'DD/MM/YYYY'
							)})`}</p>
						);
					}}
				/>
				<Column
					title="Shift Time"
					dataIndex="startTime"
					key="startTime"
					className="bg-white"
					width={'15%'}
					// sorter={true}
					render={(record: any, data: any) => {
						return (
							<>{`${dayjs(data.startTime).format(
								'hh : mm a'
							)} - ${dayjs(data.endTime).format('hh : mm a')}`}</>
						);
					}}
				/>
				<Column
					title="Department"
					dataIndex="branch"
					key="branch"
					className="bg-white"
					// sorter={true}
				/>
				<Column
					title="Supervisor"
					dataIndex="supervisorName"
					key="supervisorName"
					className="bg-white"
					width={'12%'}
					// sorter={true}
				/>
				<Column
					title="No. of Employee"
					dataIndex="noOfEmployees"
					key="noOfEmployees"
					className="bg-white"
					width={'12%'}
					// sorter={true}
					render={(record: any, data: any) => {
						return (
							<div className="flex align-center justify-center">
								<UsergroupAddOutlined
									style={{ color: 'blue' }}
								/>
								<span
									style={{ marginLeft: '5px', color: 'blue' }}
								>
									{data?.noOfEmployees}
								</span>
							</div>
						);
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					render={(status, data: any) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						const currentDateTime = dayjs();

						// Start and end datetime of the shift
						const shiftStartDateTime = dayjs(data.startDate)
							.startOf('day')
							.add(dayjs(data.startTime).hour(), 'hour')
							.add(dayjs(data.startTime).minute(), 'minute');
						const shiftEndDateTime = dayjs(data.endDate)
							.startOf('day')
							.add(dayjs(data.endTime).hour(), 'hour')
							.add(dayjs(data.endTime).minute(), 'minute');
						if (currentDateTime.isBefore(shiftStartDateTime)) {
							status = 'PLANNED';
						} else if (currentDateTime.isAfter(shiftEndDateTime)) {
							status = 'COMPLETED';
						} else {
							status = 'ACTIVE';
						}

						// Check based on dates first
						// if (dayjs(data.startDate).isAfter(dayjs())) {
						// 	status = 'PLANNED';
						// } else if (dayjs(data.endDate).isBefore(dayjs())) {
						// 	status = 'COMPLETED';
						// } else {
						// 	status = 'ACTIVE';
						// }
						// Switch case for different status values
						switch (status) {
							case 'PLANNED':
								statusText = 'Planned';
								statusClass = styles['status-retired'];
								StatusIcon = <PlannedSvg />;
								break;
							case 'ACTIVE':
								statusText = 'Active';
								statusClass = styles['status-active'];
								StatusIcon = <ActiveStatusSvg />;
								break;
							case 'COMPLETED':
								statusText = 'Completed';
								statusClass = styles['status-reassigned'];
								StatusIcon = <CompletedSvg />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-retired'];
								StatusIcon = null;
						}

						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<div className={styles['statusIcon']}>
									{StatusIcon}
									<span>{statusText}</span>
								</div>
							</Tag>
						);
					}}
				/>

				{/* <Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					// sorter={true}
					render={(record: any, data: any) => {
						let statusText = '';
						let statusClass = '';
						let statusIcon = null;
						return (
							<>
								{dayjs(data?.startDate) > dayjs(new Date()) ? (
									<Tag
										color="blue"
										style={{
											display: 'flex',
											alignItems: 'center',
											whiteSpace: 'nowrap', // Prevents line breaking
							width: 'auto', // Fits the content size

											
										}}
									>
										{' '}
										<PlannedSvg />
									</Tag>
								) : dayjs(data.endDate) < dayjs(new Date()) ? (
									<Tag color="purple">
										{' '}
										<FileDoneOutlined />
										<span style={{ marginLeft: '5px' }}>
											Completed
										</span>
									</Tag>
								) : (
									<Tag
										color="green"
										style={{
											display: 'flex',
											alignItems: 'center',
											// padding: '0 4px', // Adjust padding for consistency with "Planned"
											fontSize: '14px', // Match font size with "Planned"
											height: '24px', // Match height with "Planned"
										}}
									>
										<ActiveStatusSvg />
										<span style={{ marginLeft: '4px' }}>
											Active
										</span>
									</Tag>
								)}
							</>
						);
					}}
				/> */}
				{(permissions.allowedPermissions.includes('Edit_Time_Shift') ||
					permissions.allowedPermissions.includes(
						'Delete_Time_Shift'
					)) && (
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						width={'10%'}
						render={(record: any, data: any) => {
							return (
								<div
									style={{
										display: 'flex',
										gap: '2rem',
										alignItems: 'center',
									}}
								>
									{permissions.allowedPermissions.includes(
										'Edit_Time_Shift'
									) ? (
										dayjs(data?.endDate) >
											dayjs(new Date()) ||
										data.timeLogsCount === 0 ? (
											<div
												className="cursor-pointer  flex align-center justify-center"
												onClick={() => {
													openCreateShiftModal();
													setEditShift(data);
												}}
											>
												<EditActionSvg />
											</div>
										) : (
											<div className="cursor-not-allowed  flex align-center justify-center">
												<EditActionSvg color="#928F8F" />
											</div>
										)
									) : null}
									{permissions.allowedPermissions.includes(
										'Delete_Time_Shift'
									) ? (
										data.timeLogsCount === 0 &&
										!isUserSupervisor ? (
											<div
												className="cursor-pointer flex align-center justify-center"
												onClick={() => {
													setEditShift(data);
													setIsShiftDeleteModalOpen(
														true
													);
												}}
											>
												<DeleteActionSvg />
											</div>
										) : (
											<div className="cursor-not-allowed flex align-center justify-center">
												<DeleteActionSvg color="#928F8F" />
											</div>
										)
									) : null}
								</div>
							);
						}}
					/>
				)}
				{(permissions.allowedPermissions.includes('Edit_Time_Logs') ||
					permissions.allowedPermissions.includes(
						'Add_Time_Logs'
					)) && (
					<Column
						title="Add Logs"
						dataIndex="addLogs"
						key="action"
						className="bg-white"
						width={'10%'}
						render={(record: any, data: any) => {
							return (
								<div
									style={{
										display: 'flex',
										width: '100%',
										textAlign: 'center',
									}}
								>
									{permissions.allowedPermissions.includes(
										'Edit_Time_Logs'
									) ||
									permissions.allowedPermissions.includes(
										'Add_Time_Logs'
									) ? (
										dayjs(data?.startDate) >
											dayjs(new Date()) &&
										dayjs(data?.endDate) >
											dayjs(new Date()) ? (
											<ClockCircleOutlined
												disabled
												style={{
													fontSize: '18px',
													color: '#928F8F',
												}}
											/>
										) : (
											<Tooltip
												title="Add Logs"
												placement="top"
											>
												<ClockCircleOutlined
													style={{
														cursor: 'pointer',
														fontSize: '18px',
													}}
													onClick={() => {
														setSelectedShift(
															data.id
														);
														setIsAddLogsModalOpen(
															true
														);
														setShiftStartDate(
															data.startDate
														);
														setShiftEndDate(
															data.endDate
														);
														setSelectedShiftDetails(
															data
														);
														setSelectedShiftLastDate(
															data.lastSubmittedDate
														);
													}}
												/>
											</Tooltip>
										)
									) : null}
								</div>
							);
						}}
					/>
				)}
			</Table>
			{isAddLogsModalOpen && (
				<AddLogsModal
					selectedShift={selectedShift}
					shiftStartDate={dayjs(shiftStartDate).startOf('day')}
					shiftEndDate={shiftEndDate}
					isOpen={isAddLogsModalOpen}
					handleCancel={() => {
						setIsAddLogsModalOpen(false);
						setSelectedShift(null);
						fetchShifts();
					}}
					fetchShifts={fetchShifts}
					selectedShiftDetails={selectedShiftDetails}
					selectedShiftLastDate={selectedShiftLastDate}
				/>
			)}
			{isShiftDetailsModalOpen && shiftDetails && (
				<ViewShiftModal
					data={shiftDetails}
					isModalOpen={isShiftDetailsModalOpen}
					closeModal={() => {
						setShiftDetails(null);
						setIsShiftModalOpen(false);
					}}
				/>
			)}
		</div>
	);
};

export default ShiftTable;
