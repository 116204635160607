import { PermissionContext } from 'components/Global/AuthLayout';
import EarningReportComponent from 'components/Report/EarningReport';
import { useContext } from 'react';
const EarningReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes(
		'Allow_Earning_and_Deduction_Reports'
	);
	return <>{check && <EarningReportComponent />}</>;
};

export default EarningReport;
