import { Table } from 'antd';
import './index.scss';

type Props = {
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	rows: any[];
	columns: any[];
};

const AttendanceReportTable = (props: Props) => {
	const {
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		rows,
		columns,
	} = props;

	return (
		<>
			<Table
				columns={columns}
				dataSource={rows}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				rowKey={(record) => record.id}
				loading={isLoading}
				onChange={tableChangeHandler}
				scroll={{ y: 'calc(100vh - 300px)' }}
				className="AttendanceReportTable"
			/>
		</>
	);
};

export default AttendanceReportTable;
