import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ChangeEvent, useState } from 'react';

type Props = {
	name: string;
	label: string;
	value: number | string;
	placeholder?: string;
	required: boolean;
	isError: boolean;
	onChange: (value: string) => void;
	helperText?: string;
	prefix?: any;
	suffix?: any;
	regex?: string;
	disabled?: boolean;
	type?: string;
	formatter?: string;
	parser?: string;
	size?: SizeType;
	showLabel?: boolean;
	style?: any;
	isViewOnly?: boolean;
	onBlur?: (formattedValue: string) => void;
};

const InputTimeLogsHoursField = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		prefix = null,
		suffix = null,
		regex,
		required = false,
		onChange,
		disabled = false,
		type = 'text',
		helperText = 'Invalid field',
		formatter,
		parser,
		size = 'large',
		showLabel = false,
		onBlur,
		style,
		isViewOnly,
	} = props;

	const [hasError, setHasError] = useState(false);

	const handleChange = (value: string) => {
		let inputValue = String(value);

		// Remove non-digit characters
		inputValue = inputValue.replace(/\D/g, '');

		// Restrict to 4 digits
		if (inputValue.length > 4) {
			inputValue = inputValue.slice(0, 4);
		}

		// Format inputValue as HH:MM
		if (inputValue.length > 2) {
			inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(2)}`;
		}

		// if (required) {0
		// 	setHasError(invalidText(value));
		// }
		onChange(inputValue);
	};

	const handleBlur = (value: string) => {
		let formattedValue = value.replace(/[^\d:]/g, '');

		// Handle cases with or without colon
		const parts = formattedValue.split(':');
		let hours = parts[0] || '00';
		let minutes = parts[1] || '00';

		// Ensure hours and minutes are in two-digit format
		hours = hours.padStart(2, '0');
		minutes = minutes.padEnd(2, '0').slice(0, 2);

		// Combine formatted hours and minutes
		formattedValue = `${hours}:${minutes}`;

		if (formattedValue === '') {
			formattedValue = '00:00';
		}

		onChange(formattedValue);

		if (onBlur) {
			onBlur(formattedValue);
		}
	};

	return (
		<div className="input-field">
			{showLabel && label && (
				<p
					className="label"
					style={{ marginBottom: `${isViewOnly ? '5px' : ''}` }}
				>
					{label}{' '}
					{required && !isViewOnly && <span className="red">*</span>}
				</p>
			)}

			{isViewOnly ? (
				<p>{value} hrs</p>
			) : (
				<div>
					<Input
						name={name}
						status={isError || hasError ? 'error' : ''}
						placeholder={placeholder}
						prefix={prefix}
						suffix={suffix}
						value={value}
						required={required}
						onChange={(e: any) => {
							handleChange(e.target.value);
						}}
						onBlur={(e: ChangeEvent<HTMLInputElement>) =>
							handleBlur(e.target.value)
						}
						// formatter={handleFormatter}
						// parser={handleParser}
						disabled={disabled}
						size={size}
						style={{ width: '100%', ...style }}
					/>
					{/* {(isError || hasError) && (
					<p
						className="red"
						style={{
							fontSize: '12px',
							marginLeft: '2px',
						}}
					>
						{helperText}
					</p>
				)} */}
				</div>
			)}
		</div>
	);
};

export default InputTimeLogsHoursField;
