import { Col, Form, Input, Modal, Row } from 'antd';
import Buttons from 'components/Global/Buttons';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { CloseSvg } from 'utils/svgs';
import {
	formatPhoneNumber,
	phoneNumberFormatHandler,
	toastText,
} from 'utils/utils';
import { UserProfileData as UserProfileDataSrc } from '../../constants/Data';
import {
	fetchProfileAction,
	updateProfileAction,
} from '../../redux/actions/profileAction';
import style from './index.module.scss';
import './index.scss';
import { UserProfileModalProps } from './types';

const UserProfileModal: FC<UserProfileModalProps> = (props) => {
	const { handleCancel, isProfileModalOpen } = props;

	const [isLoading, setIsLoading] = useState(false);

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			disabled: isLoading,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				handleCancel();
			},
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {},
		},
	];

	const dispatch = useDispatch<AppDispatch>();

	const profileData = useSelector((state: any) => state?.userProfile?.data);

	const profileImage: string[] | null = [];
	const [phoneNumberWithoutUsFormat, setPhoneNumberWithoutUsFormat] =
		useState<any>(profileData?.phone || '');
	const [phoneNumber, setPhoneNumber] = useState<any>(
		phoneNumberFormatHandler(profileData?.phone || '') || ''
	);
	const [isPhoneNumberFocused, setPhoneNumberFocused] = useState(false);

	const [userProfileData, setUserProfileData] = useState<any>({
		firstName: profileData?.firstName,
		lastName: profileData?.lastName,
		email: profileData?.email,
		phone: profileData?.phone,
		profileImg: profileImage,
		role: profileData?.roleName,
	});

	useEffect(() => {
		setUserProfileData((prev: any) => {
			const phone = prev?.phone
				? formatPhoneNumber(prev?.phone?.toString())
				: '';
			return {
				...prev,
				phone: phone,
			};
		});
	}, [profileData]);

	// For handle the phone number change
	const phoneChangeHandler = (phone: any) => {
		const normalNumber = phone.replace(/\D/g, '');
		if (normalNumber?.length <= 10) {
			const phoneNo = phoneNumberFormatHandler(phone);
			setPhoneNumberWithoutUsFormat(normalNumber);

			setPhoneNumber(phoneNo);
		}
	};

	// Handle Submit Profile
	const handleSubmit = async (values: any): Promise<void> => {
		setPhoneNumberFocused(true);
		if (phoneNumberWithoutUsFormat?.length > 0) {
			if (phoneNumberWithoutUsFormat?.length === 10) {
				const inputPhoneNumber = phoneNumber.replace(/\D/g, '');
				const profileData = new FormData();
				profileData.append('firstName', values.firstName);
				profileData.append('lastName', values.lastName);
				profileData.append('phone', inputPhoneNumber);
				profileData.append('role', values.role.roleName);
				try {
					setIsLoading(true);

					const response = await dispatch(
						updateProfileAction(profileData) as any
					);

					if (response.payload?.responseStatus === 200) {
						toastText(response?.payload?.message, 'success');
					} else {
						toastText(response?.payload?.message, 'error');
					}
					dispatch(fetchProfileAction());
					handleCancel();
				} catch (error: any) {
					toastText(error.response.data?.message, 'error');
				} finally {
					setIsLoading(false);
				}
			}
		} else {
			try {
				setIsLoading(true);

				const profileData = new FormData();
				profileData.append('firstName', values.firstName);
				profileData.append('lastName', values.lastName);
				profileData.append('role', values.role.roleName);
				const response = await dispatch(
					updateProfileAction(profileData) as any
				);

				if (response.payload?.responseStatus === 200) {
					toastText(response?.payload?.message, 'success');
				} else {
					toastText(response?.payload?.message, 'error');
				}
				dispatch(fetchProfileAction());
				handleCancel();
			} catch (error: any) {
				toastText(error.response.data?.message, 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<Modal
			open={isProfileModalOpen}
			onOk={handleSubmit}
			onCancel={handleCancel}
			okText={'Save'}
			closable={false}
			footer={null}
			className="profile__popup"
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={userProfileData}
				onFinish={handleSubmit}
				autoComplete="off"
			>
				<Row className={style['profile-modal__header']}>
					<div className="userDetailsTitle">
						<b>Profile</b>
					</div>
					<div onClick={handleCancel} className="pointer">
						<CloseSvg />
					</div>
				</Row>
				<hr />
				<div className="profile-modal__body">
					<Row gutter={24}>
						{UserProfileDataSrc?.map((item: any, index: number) => (
							<Col
								className="gutter-row fields"
								xs={24}
								sm={
									item.name === 'email' ||
									item.name === 'role'
										? 24
										: 12
								} // Updated logic to handle email and role fields
								md={
									item.name === 'email' ||
									item.name === 'role'
										? 24
										: 12
								}
								key={index}
								lg={
									item.name === 'email' ||
									item.name === 'role'
										? 24
										: 12
								}
							>
								{item.name !== 'phone' && (
									<>
										<label className="profile-form-label">
											{item?.title}{' '}
											{item?.required && (
												<span className="required-color">
													*
												</span>
											)}
										</label>
										<Form.Item
											className="formItem"
											name={item?.name}
											rules={item?.rules as []}
											wrapperCol={{ span: 24 }}
										>
											<Input
												size="large"
												disabled={item?.disabled}
												type={item?.type}
												// defaultValue={
												// 	userProfileData[item?.name]
												// }
											/>
										</Form.Item>
									</>
								)}
								{item.name === 'phone' && (
									<>
										<label className="profile-form-label">
											{item?.title}{' '}
										</label>
										<Form.Item
											className="formItem"
											// name={item?.name}
											// rules={item?.rules as []}
											wrapperCol={{ span: 24 }}
										>
											<Input
												size="large"
												// disabled={item?.disabled}
												value={phoneNumber}
												onBlur={() => {
													setPhoneNumberFocused(true);
												}}
												onChange={(e) =>
													phoneChangeHandler(
														e.target.value
													)
												}
											/>

											{isPhoneNumberFocused &&
												phoneNumberWithoutUsFormat?.length !==
													10 &&
												phoneNumberWithoutUsFormat?.length !==
													0 && (
													<p className="error-message">
														Please enter a valid
														phone number
													</p>
												)}
										</Form.Item>
									</>
								)}
							</Col>
						))}
					</Row>
				</div>
				<hr />
				<div className="profile_modal_footer">
					<Buttons buttons={myButtons}></Buttons>
				</div>
			</Form>
		</Modal>
	);
};

export default UserProfileModal;
