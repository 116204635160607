import { deleteApi, getApi, postApi } from 'apis';

const getDefaultTimeLogs = async (data: any) => {
	return await getApi('/timeLogs', data);
};

const createTimeLogs = async (data: any) => {
	return await postApi('/timeLogs', data);
};

const getTimeActivities = async (data: any) => {
	return await getApi('/timeActivities', data);
};

const getPendingTimeActivities = async (data: any) => {
	return await getApi('/timeActivities/pending', data);
};

const getMissingTimeActivities = async (data: any) => {
	return await getApi('/timeActivities/missing', data);
};

const sendEmailReminders = async (data: any) => {
	return await postApi('/timeLogs/email-reminder', data);
};

const uploadAttachment = async (data: any) => {
	return await postApi('/timeLogs/upload-attachment', data, true);
};

const deleteAttachment = async (fileName: string) => {
	return await deleteApi(`/api/attachments/${fileName}`);
};

const getTimeActivitiesById = async (data: any) => {
	return await getApi('/timeActivities/get-time-activities/${id}', data);
};

const getMonthlyTimeLogs = async (data: any) => {
	return await getApi('/timeLogs/monthly-time-logs', data);
};

const createMonthlyTimeLogs = async (data: any) => {
	return await postApi('/timeLogs/monthly-time-logs', data);
};

const viewTimeLogsApi = async (data: any) => {
	return await getApi('/shift/view-time-logs', data);
};

export const timeLogsApi = {
	getDefaultTimeLogs,
	createTimeLogs,
	getTimeActivities,
	getPendingTimeActivities,
	sendEmailReminders,
	uploadAttachment,
	deleteAttachment,
	getTimeActivitiesById,
	createMonthlyTimeLogs,
	getMonthlyTimeLogs,
	viewTimeLogsApi,
	getMissingTimeActivities,
};
