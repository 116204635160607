import { getApproveRequestApi } from 'Api/approveRequest';
import { Divider, Modal } from 'antd';
import { Loader } from 'components/Global';
import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	isDeleteNotificationModalOpen: boolean;
	handleCancel: () => void;
	approvalId: string | null;
	notificationId: string | null;
};

const AddPushNotificationModal = (props: Props) => {
	const [notificationData, setNotificationData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [displayData, setDisplayData] = useState<any[]>([]);
	const [approveIsLoading, setApproveIsLoading] = useState(false);
	const [rejectIsLoading, setRejectIsLoading] = useState(false);
	const [modalTitle, setModalTitle] = useState('');

	const {
		isDeleteNotificationModalOpen,
		handleCancel,
		approvalId,
		notificationId,
	} = props;

	const buttons = [
		{
			text: 'Reject',
			isSubmit: true,
			className: 'secondary-button',
			// onclick: rejectNotification,
			minWidth: '10rem',
			minHeight: '42px',
			onclick: () => {
				rejectApprovalRequest();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			isLoading: rejectIsLoading,
		},
		{
			text: 'Approve',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				acceptApprovalRequest();
			},
			disabled: isLoading || approveIsLoading || rejectIsLoading,
			minWidth: '60px',
			minHeight: '42px',
			isLoading: approveIsLoading,
		},
	];

	const acceptApprovalRequest = async () => {
		try {
			setApproveIsLoading(true);
			await getApproveRequestApi.acceptDeleteNotificationApproval({
				approvalId: approvalId,
				userNotificationId: notificationId,
			});
			toastText(
				`${modalTitle || 'Approval'} deleted Successfully!`,
				'success'
			);

			handleCancel();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in accepting delete approval';
			toastText(message, 'error');
		} finally {
			setApproveIsLoading(false);
		}
	};

	const rejectApprovalRequest = async () => {
		try {
			setRejectIsLoading(true);
			await getApproveRequestApi.rejectDeleteNotificationApproval({
				approvalId: approvalId,
				userNotificationId: notificationId,
			});
			toastText(
				`${modalTitle || 'Approval'} Rejected Successfully!`,
				'success'
			);

			handleCancel();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in rejecting delete approval';
			toastText(message, 'error');
		} finally {
			setRejectIsLoading(false);
		}
	};

	const fetchApprovalRequest = async () => {
		try {
			setIsLoading(true);
			const response =
				await getApproveRequestApi.getApprovalRequestsForNotification({
					approvalId: approvalId,
				});
			const _data = response.data.data;

			setNotificationData(_data.data);
			setDisplayData(_data.displayData);
			setModalTitle(_data.modalTitle);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isDeleteNotificationModalOpen) {
			fetchApprovalRequest();
		}
	}, [isDeleteNotificationModalOpen]);

	const renderNotificationData = () => {
		if (isLoading) {
			return (
				<div className={styles['loader-container']}>
					<Loader />
				</div>
			);
		}

		if (!notificationData?.length) {
			return (
				<div
					className={styles['delete-notification-modal--icon__text']}
				>
					No approval request present!
				</div>
			);
		}

		const titles = Object.keys(displayData);

		const content = Object.values(displayData);

		return (
			<div className={styles['delete-notification-modal__list']}>
				{titles.map((title, index) => (
					<div
						key={index}
						className={
							styles['delete-notification-modal__list--item']
						}
					>
						<div
							style={{
								width: '30%',
								fontWeight: 'bold',
								fontSize: '14px',
							}}
						>
							{title}
						</div>
						<div>
							{' '}
							{invalidText(content[index])
								? '- '
								: content[index]}{' '}
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<Modal
			open={isDeleteNotificationModalOpen}
			onCancel={handleCancel}
			okText="Save"
			closable={false}
			width={600}
			footer={() => (
				<div className={styles.footerButtons}>
					<Buttons buttons={buttons} />
				</div>
			)}
		>
			{!isLoading && (
				<>
					<div className={styles['delete-notification-modal']}>
						<p
							className={
								styles['delete-notification-modal__title']
							}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									marginBottom: '10px',
								}}
							>
								{/* <ExclamationCircleOutlined
									style={{
										fontSize: '40px',
										color: 'red',
										marginBottom: '10px',
									}}
								/> */}
							</div>
							<h3
								className={
									styles['delete-notification-modal__title']
								}
							>
								Are you sure want to Delete{' '}
								<span
									style={{
										color: '#FF0000',
									}}
								>
									{modalTitle}
								</span>
							?
							</h3>
						</p>
						{!isLoading && (
							<Divider
								style={{
									margin: '1rem 0 1rem 0',
								}}
							/>
						)}
					</div>
				</>
			)}
			{renderNotificationData()}
		</Modal>
	);
};

export default AddPushNotificationModal;
