import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { invalidText } from 'utils/utils';

type Props = {
	name: string;
	label: string;
	value: string | number;
	placeholder?: string;
	required: boolean;
	isError: boolean;
	onChange: (e: any) => void;
	helperText?: string;
	prefix?: any;
	suffix?: any;
	regex?: string;
	disabled?: boolean;
	type?: string;
};

const TextareaFieldInput = (props: Props) => {
	const {
		name,
		label,
		value,
		placeholder,
		isError = false,
		prefix = null,
		suffix = null,
		regex,
		required = false,
		onChange,
		disabled = false,
		type = 'text',
		helperText = 'Invalid field',
	} = props;

	const [hasError, setHasError] = useState(false);

	const handleChange = (event: any) => {
		const value = event;
		if (required) {
			setHasError(invalidText(value));
		}

		// if (regex) {
		// 	const _regex = new RegExp(regex);
		// 	setHasError(!_regex.test(value));
		// }

		onChange(event);
	};

	const handleBlur = (value: string) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
	};

	return (
		<div className="input-field">
			{label && (
				<p className="label">
					{label} {required && <span className="red">*</span>}
				</p>
			)}
			<div>
				<TextArea
					name={name}
					status={isError || hasError ? 'error' : ''}
					placeholder={placeholder}
					prefix={prefix}
					value={value}
					required={required}
					onChange={(e: any) => handleChange(e.target.value)}
					onBlur={(e: any) => handleBlur(e.target.value)}
					size="large"
					disabled={disabled}
				/>
				{(isError || hasError) && (
					<p
						className="red"
						style={{
							fontSize: '12px',
							marginLeft: '2px',
						}}
					>
						{helperText}
					</p>
				)}
			</div>
		</div>
	);
};

export default TextareaFieldInput;
