import GlobalHeader from '../GlobalHeader';
import TotalDeductionTable from './Table';
import styles from './index.module.scss';

type totalDeductionProps = {
	selectedPayrollId: string;
	setDeductionJSON: any;
	onlyView: boolean;
	headerData: any;
	setIsUniversalLoading: any;
};
const TotalDeductionComponent = (props: totalDeductionProps) => {
	const {
		selectedPayrollId,
		setDeductionJSON,
		onlyView,
		headerData,
		setIsUniversalLoading,
	} = props;

	return (
		<div className={styles['total-deduction__table']}>
			<TotalDeductionTable
				setDeductionJSON={setDeductionJSON}
				selectedPayrollId={selectedPayrollId}
				onlyView={onlyView}
				setIsUniversalLoading={setIsUniversalLoading}
				headerData={headerData}
			/>
		</div>
	);
};

export default TotalDeductionComponent;
