import { postApi } from 'apis';
// import axios from 'axios';
import { PermissionContext } from 'components/Global/AuthLayout';
import { LoginLayoutBody } from 'components/Login';
import { FORMDATA } from 'constants/Data';
import { jwtDecode } from 'jwt-decode';
import { LoginLayout } from 'layouts/Login';
import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { invalidText, toastText } from 'utils/utils';
import { fetchProfileAction } from '../../redux/actions/profileAction';

// Login page
const Login = () => {
	// React Hooks

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const recaptchaRef = useRef<any>(null);

	const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordValidate, setIsPasswordValidate] = useState(false);

	const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

	const context = useContext(PermissionContext);

	const onSubmit = async (values: any) => {
		if (isPasswordValidate) {
			if (invalidText(recaptchaValue)) {
				toastText('Please verify the reCAPTCHA', 'error');
				return;
			}

			setIsLoading(true);
			try {
				const _values = values;
				_values.rememberMe = isRememberMe;
				const res = await postApi(`/auth/login`, {
					...values,
					recaptchaValue,
				});
				const userData: User = jwtDecode(
					res.data.accessToken as string
				);
				localStorage.setItem('accessToken', res.data.accessToken);
				const response = await dispatch(fetchProfileAction());
				// const response = await getApi('/auth/fetch-profile');
				context.permissionHandler(
					response?.payload?.data?.permissions,
					userData?.companyId,
					userData.id,
					userData.isSuperAdmin,
					userData?.isAdmin
				);
				setIsLoading(false);
				navigate('/');
				// navigate('/settings/users');
			} catch (error: any) {
				if (recaptchaRef && recaptchaRef.current) {
					recaptchaRef.current.reset();
					setRecaptchaValue('');
				}
				toastText(error?.response?.data?.message, 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	// JSX for the Login page
	return (
		<LoginLayout>
			<LoginLayoutBody
				title="Log in"
				formData={FORMDATA.loginFields}
				buttonTitle={'Sign in'}
				redirectUrl="/forgot-password"
				redirectText="Forgot password?"
				onSubmit={onSubmit}
				isLoading={isLoading}
				setIsPasswordValidate={setIsPasswordValidate}
				handleRecaptcha={(value: string) => {
					setRecaptchaValue(value);
				}}
				isRememberMe={isRememberMe}
				setIsRememberMe={setIsRememberMe}
				recaptchaRef={recaptchaRef}
			></LoginLayoutBody>
		</LoginLayout>
	);
};

export default Login;
interface User {
	email: string;
	exp: number;
	iat: number;
	id: string;
	isAdmin: boolean;
	type: string;
	permissions: any;
	isSuperAdmin: boolean;
	companyId: string;
}
