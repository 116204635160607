import NapsaReportComponent from 'components/Report/NapsaReport';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

const NapsaReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return <>{check && <NapsaReportComponent />}</>;
};

export default NapsaReport;
