import React, { useState } from 'react';
import { fileSystemService } from 'Api/fileSystem';
import { toastText } from 'utils/utils';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Ellipse from '../Ellipse';

interface DownloadComponentProps {
	downloadText: string;
	documentUrl: string;
	text?: string;
	icon?: React.ReactNode;
	showTooltip?: boolean;
	tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
	className?: string;
	tooltipText?: string;
	isLocalFile?: boolean;
	localFile?: File | null;
	isLink?: boolean;
	maxLength?: number;
}

const DownloadComponent: React.FC<DownloadComponentProps> = ({
	documentUrl,
	text,
	icon,
	showTooltip = false,
	tooltipPlacement = 'top',
	className = '',
	tooltipText = '',
	isLocalFile = false,
	localFile = null,
	isLink = false,
	maxLength,
	downloadText,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleLocalFileDownload = (file: File) => {
		try {
			const url = window.URL.createObjectURL(file);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', downloadText);

			document.body.appendChild(link);
			link.click();

			setTimeout(() => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}, 100);

			toastText('success', 'File downloaded successfully');
		} catch (error) {
			console.error('Error downloading local file:', error);
			toastText('error', 'Error downloading local file');
		}
	};

	const downloadFile = async () => {
		if (isLocalFile && localFile) {
			handleLocalFileDownload(localFile);
			return;
		}

		if (!documentUrl) {
			console.log('documentUrl: ', documentUrl);
			toastText('error', 'No file available for download');
			return;
		}
		console.log('Sfsdfsdfds');
		setIsLoading(true);

		try {
			const response: any = await fileSystemService.downloadDocument({
				documentUrl: documentUrl,
			});

			if (response && response.data) {
				const contentType = response.headers['content-type'] || '';

				// Determine file extension from content type or URL
				let extension = '';
				if (contentType.includes('spreadsheet')) extension = '.xlsx';
				else if (contentType.includes('pdf')) extension = '.pdf';
				else if (contentType.includes('text/plain')) extension = '.txt';
				else if (documentUrl.includes('.'))
					extension = documentUrl.split('.').pop()
						? `.${documentUrl.split('.').pop()}`
						: '';

				// Use text prop for filename and append detected extension
				const filename = `${downloadText}${extension}`;

				const blob = new Blob([response.data], { type: contentType });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename);

				document.body.appendChild(link);
				link.click();
				setTimeout(() => {
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				}, 100);

				toastText('success', 'File downloaded successfully');
			} else {
				toastText('error', 'Error in downloading file');
			}
		} catch (error: any) {
			const errorText = await error?.response?.data.text();
			// Parse text as JSON
			const errorJson = errorText && JSON.parse(errorText);

			let errorMessage = 'Error in downloading file';
			if (errorText && errorJson?.message) {
				errorMessage = errorJson?.message;
			}
			toastText(errorMessage, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const renderContent = () => {
		return (
			<span
				className={`${className}`}
				onClick={downloadFile}
				style={{ cursor: 'pointer' }}
			>
				{isLoading ? (
					<LoadingOutlined />
				) : (
					<>
						{icon && icon}
						{text && (
							<Ellipse
								message={text}
								isLink={isLink}
								maxLength={maxLength ?? 10}
								isTooltip={true}
								tooltipMessage={text}
							/>
						)}
					</>
				)}
			</span>
		);
	};

	return showTooltip ? (
		<Tooltip title={tooltipText} placement={tooltipPlacement}>
			{renderContent()}
		</Tooltip>
	) : (
		renderContent()
	);
};

export default DownloadComponent;
