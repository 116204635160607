import { Space, Tooltip } from 'antd';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { SyncOutlined } from '@ant-design/icons';

type Props = {
	changeYear: (value: any) => void;
	selectedYear: string;
	button: any;
	isLoading: any;
	fetchSalary: any;
};

const SalaryDetailHeader = (props: Props) => {
	const { button, changeYear, selectedYear, isLoading, fetchSalary } = props;

	const permissionsContext = useContext(PermissionContext);

	const isAdd = permissionsContext.allowedPermissions.includes('Add_Salary');

	return (
		<div className={styles['employee-header']}>
			<Space className={styles['employee-header-left']}>
				Salary Details
			</Space>

			<Space className={styles['employee-header-right']}>
				<div className={styles['refresh-buttons']}>
					<Tooltip title="Refresh all the Salary">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={!isLoading ? fetchSalary : undefined}
						/>
					</Tooltip>
				</div>
				<DatePickerField
					label=""
					name="year"
					onChange={changeYear}
					value={selectedYear}
					disabled={false}
					placeholder="Year"
					required={false}
					isError={false}
					picker="year"
				/>
				{isAdd && <Buttons buttons={button} />}
			</Space>
		</div>
	);
};

export default SalaryDetailHeader;
