import { PermissionContext } from 'components/Global/AuthLayout';
import PayrollSummEmpReportComponent from 'components/Report/PayrollSummEmpReport';
import { useContext } from 'react';

const PayrollSummaryEmployeeReport = () => {
	// const context = useContext(PermissionContext);
	// const check = context.allowedPermissions.includes('Allow_Payroll_Reports');
	return (
		<>
			{/* {check &&  */}
			<PayrollSummEmpReportComponent />
			{/* } */}
		</>
	);
};

export default PayrollSummaryEmployeeReport;
