import React from 'react';
import { Checkbox } from 'antd';
import './index.scss';

type CheckBoxProps = {
	label: string;
	onChange: (checked: boolean) => void;
	checked: boolean;
	disabled?: boolean;
};

const CheckBox = ({ label, onChange, checked, disabled }: CheckBoxProps) => (
	<div className="checkbox-container">
		<Checkbox
			onChange={(e) => onChange(e.target.checked)}
			checked={checked}
			disabled={disabled}
		>
			<p className="checkbox-container-label">{label}</p>
		</Checkbox>
	</div>
);

export default CheckBox;
