import { PermissionContext } from 'components/Global/AuthLayout';
import ConfigurationSidebar from 'components/Global/ConfigurationSidebar';
import { FORMDATA } from 'constants/Data';
import { FC, useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';

// Settings page layout
const ReportLayout: FC<SettingsLayoutProps> = (props) => {
	const { ReportMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	const permissionContext = useContext(PermissionContext);
	const { allowedPermissions } = permissionContext;
	const [menuItems, setMenuItems] = useState(ReportMenuItems);

	useEffect(() => {
		let _menuItems = ReportMenuItems;

		if (!allowedPermissions.includes('Allow_Payroll_Reports')) {
			_menuItems = _menuItems.filter(
				(item) =>
					item?.key !== 'napsa' &&
					item?.key !== 'nhima' &&
					item?.key !== 'paye' &&
					item?.key !== 'payroll-summary' &&
					item?.key !== 'p-18' &&
					item?.key !== 'journal-entry' &&
					item?.key !== 'payroll-summary-employee'
			);
		}
		if (!allowedPermissions.includes('Allow_Attendance_Report')) {
			_menuItems = _menuItems.filter(
				(item) => item?.key !== 'attendance-report'
			);
		}
		if (
			!allowedPermissions.includes('Allow_Earning_and_Deduction_Reports')
		) {
			_menuItems = _menuItems.filter(
				(item) => item?.key !== 'earnings' && item?.key !== 'deductions'
			);
		}

		setMenuItems(_menuItems);
	}, [ReportMenuItems, permissionContext]);

	// JSX
	return (
		<div className={styles['configuration-layout']}>
			<div className={styles['configuration-layout__wrapper']}>
				<ConfigurationSidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['configuration-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ReportLayout;
