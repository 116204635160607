import { getApi } from 'apis';

const getAllEmployeeWorkHours = async (query: any) => {
	return await getApi('/payroll/view/employeeWorkHours', query);
};

const getAllEmployeeDeductions = async (query: any) => {
	return await getApi('/payroll/view/employee-deductions', query);
};
const getAllEmployeeEarnings = async (query: any) => {
	return await getApi('/payroll//view/employee-earnings', query);
};
const getAllEmployeeLeaves = async (query: any) => {
	return await getApi('/payroll/view/employee-leaves', query);
};
export const viewPayrollApi = {
	getAllEmployeeWorkHours,
	getAllEmployeeDeductions,
	getAllEmployeeEarnings,
	getAllEmployeeLeaves,
};
