import { Space, Table } from 'antd';
import DownloadComponent from 'components/Global/Download';
import AddModal from 'components/TimeLogs/AddModal';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate, minutesToTimeString } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	// fetchRecords: any;
	isLoading: boolean;
};

const TimeLogsTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		// showModal,
		isLoading,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedShift, setSelectedShift] = useState('');
	const [selectedDate, setSelectedDate] = useState('');
	const [isView, setIsView] = useState(false);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const getFileNameFromUrl = (url: string) => {
		const parts = url.split('/');
		return parts[parts.length - 1].split('?')[0];
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				// scroll={{ y: 'calc(100vh - 440px)' }}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					sorter={true}
					className="bg-white"
					render={(value, data: any) => {
						return (
							<span
								className="cursor-pointer"
								style={{ cursor: 'pointer', color: 'blue' }}
								onClick={() => {
									setIsView(true);
									showModal();
									setSelectedShift(data.shiftId);
									setSelectedDate(data.date);
								}}
							>
								{minutesToTimeString(value)} Hours
							</span>
						);
					}}
				/>
				<Column
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start',
							}}
						>
							Attendance <br />{' '}
							<span
								style={{
									color: 'gray',
									fontWeight: 300,
									fontSize: '10px',
								}}
							>
								{' '}
								Present | Absent
							</span>
						</div>
					}
					dataIndex="attendance"
					key="attendance"
					className="bg-white"
					render={(value, record: any) => {
						return (
							<Space size="middle">
								<span style={{ color: 'green' }}>
									{record.present}
								</span>{' '}
								|
								<span style={{ color: 'red' }}>
									{record.absent}
								</span>
							</Space>
						);
					}}
					// sorter={true}
				/>
				<Column
					title="Shift Name"
					key="submittedBy"
					// sorter={true}
					className="bg-white"
					width={'25%'}
					render={(value, record: any) => {
						return `${record?.shiftName} (${dayjs(
							record.shiftStartDate
						).format('DD/MM/YYYY')} - ${dayjs(
							record.shiftEndDate
						).format('DD/MM/YYYY')})`;
					}}
				/>
				<Column
					title="Submitted By"
					dataIndex="submittedBy"
					key="submittedBy"
					// sorter={true}
					className="bg-white"
				/>
				<Column
					title="Submitted On"
					dataIndex="submittedOn"
					key="submittedOn"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Attachment"
					dataIndex="attachment"
					key="attachment"
					// sorter={true}
					className="bg-white"
					render={(value, data: any) => {
						return (
							<DownloadComponent
								documentUrl={data.attachmentUrl}
								downloadText={data.attachmentName}
								showTooltip={false}
								text={data.attachmentName}
								isLink={true}
								maxLength={20}
							/>
						);
					}}
				/>
			</Table>
			{isModalOpen && selectedShift && (
				<AddModal
					isOpen={isModalOpen}
					handleCancel={handleCancel}
					selectedShift={selectedShift}
					selectedDate={selectedDate}
					isView={isView}
				/>
			)}
		</div>
	);
};

export default TimeLogsTable;
