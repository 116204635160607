import { Card, Col, Row } from 'antd';
import React from 'react';
import styles from './index.module.scss';

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	companyData: any;
};

const ViewCompanyDetails = ({ companyData }: Props) => {
	const getValue = (value: any) => (value ? value : '-');

	return (
		<div className={styles['scrollable-content']}>
			<Row gutter={16}>
				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Company Information
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>Company Name:</b> {getValue(companyData.name)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Email:</b> {getValue(companyData.email)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Tpin:</b> {getValue(companyData.tpin)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Napsa:</b> {getValue(companyData?.CompanyDetails?.napsa)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Nhima:</b> {getValue(companyData?.CompanyDetails?.nihma)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Financial Year:</b> {getValue(companyData?.CompanyDetails?.financialYear)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Date Format:</b> {getValue(companyData?.CompanyDetails?.dateFormat)}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Company Location
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>Address 1:</b> {getValue(companyData?.CompanyDetails?.address1)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Address 2:</b> {getValue(companyData?.CompanyDetails?.address2)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>City:</b> {getValue(companyData?.CompanyDetails?.city)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>State:</b> {getValue(companyData?.CompanyDetails?.state)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Country:</b> {getValue(companyData?.CompanyDetails?.country)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Postal Code:</b> {getValue(companyData?.CompanyDetails?.postalCode)}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col span={24}>
					<Card
						title={
							<div className={styles['ant-card-head-title']}>
								Company Contact Person Detail
							</div>
						}
						bordered={false}
						className={styles['card']}
					>
						<Row gutter={16}>
							<Col span={12}>
								<p>
									<b>Name:</b> {getValue(companyData?.ContactDetails[0]?.name)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Title:</b> {getValue(companyData?.ContactDetails[0]?.title)}
								</p>
							</Col>
							<Col span={12}>
								<p>
									<b>Phone:</b> {getValue(companyData?.ContactDetails[0]?.phone)}
								</p>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ViewCompanyDetails;
