import GlobalPayPeriod from 'components/Global/PayPeriod';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EarningsModal from './EarningsBody';
import styles from './index.module.scss';

const Earnings = (props: any) => {
	const { year, month, employeeId, onYearChange, activeTab, isUsd } = props;

	const [earningsAmount, setEarningsAmount] = useState<number>(0);
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');
	const [data, setData] = useState<any>([]);
	const [isFetchDataLoading, setIsFetchDataLoading] =
		useState<boolean>(false);

	const { data: payPeriodData } = useSelector(
		(state: any) => state.payPeriod
	);
	const { baseCurrencySymbol, usdCurrencySymbol } = useSelector(
		(state: any) => state.userProfile.data
	);
	// Calculate Total Earnings
	const handleDataChange = useCallback((updatedData: any) => {
		setData(updatedData);
		const total = updatedData?.reduce(
			(sum: number, item: any) =>
				item.isChecked ? sum + parseFloat(item?.amount || '0.00') : sum,
			0
		);
		setEarningsAmount(total);
	}, []);

	useEffect(() => {
		if (payPeriodData?.length > 1) {
			setSelectedPayPeriod(payPeriodData[1].value);
		}
	}, [year, payPeriodData]);

	return (
		<div>
			<div className={styles.earningContainer}>
				<div className={styles.headerBox}>
					<div className={styles.headerContent}>
						{/* Header Section */}
						<div className={styles.titleContainer}>
							<div className={styles.titleText}>Earnings</div>
							<div className={styles.separation}></div>
							<div className={styles.earningsCount}>
								<div className={styles.titleEarnings}>
									Total Earnings
								</div>
								<div className={styles.earningsAmount}>
									{isUsd
										? usdCurrencySymbol
										: baseCurrencySymbol}
									{!isFetchDataLoading &&
										earningsAmount.toFixed(2)}
								</div>
							</div>
						</div>
						{/* Pay Period Selection */}
						<div className={styles.yearMonthContent}>
							<GlobalPayPeriod
								selectedYear={year}
								handleSelectedYear={onYearChange}
								selectedPayPeriod={selectedPayPeriod}
								handlePayPeriod={setSelectedPayPeriod}
								isRow={true}
							/>
						</div>
					</div>
				</div>
				{/* Earnings Modal */}
				<EarningsModal
					month={month}
					year={year}
					employeeId={employeeId}
					activeTab={activeTab}
					selectedPayPeriod={selectedPayPeriod}
					isUSD={isUsd}
					onDataChange={handleDataChange}
					onLoadingChange={setIsFetchDataLoading}
				/>
				{/* Buttons */}
			</div>
		</div>
	);
};

export default Earnings;
