import { Col, Row } from 'antd';
import { NetPayImage } from 'utils/svgs';
import GlobalHeader from '../GlobalHeader';
import NetPayTable from './Table';
import styles from './index.module.scss';

type NetPayProps = {
	selectedPayrollId: string;
	headerData: any;
	setTotalNetPay: any;
	setIsUniversalLoading: any;
	totalNetPay: any;
};

const NetPayComponent = (props: NetPayProps) => {
	const {
		selectedPayrollId,
		headerData,
		setTotalNetPay,
		setIsUniversalLoading,
		totalNetPay,
	} = props;
	return (
		<Row>
			<Col span={15}>
				<div className={styles['work-hours__table']}>
					<NetPayTable
						selectedPayrollId={selectedPayrollId}
						setTotalNetPay={setTotalNetPay}
						setIsUniversalLoading={setIsUniversalLoading}
						headerData={headerData}
						totalNetPay={totalNetPay}
					/>
				</div>
			</Col>
			<Col span={1}></Col>
			<Col span={8}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<NetPayImage />
				</div>
			</Col>
		</Row>
	);
};

export default NetPayComponent;
