import { useEffect, useState } from 'react';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { payrollApi } from 'Api/payroll';
import { toastText } from 'utils/utils';
import PayrollHistoryTable from './Table';
import styles from './index.module.scss';

const PayrollHistory = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [sortOrder, setSortOrder] = useState('');
	const [totalRecords, setTotalRecords] = useState(0);
	const [payrollData, setPayrollData] = useState([]);

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchAllPayrolls = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const payrollData = await payrollApi.getPayrollsHistory(query);
			setPayrollData(payrollData.data.paylist);
			setTotalRecords(payrollData.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching payrolls request';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchAllPayrolls();
	}, [currentPage, pageSize, sortOrder, sortField]);
	return (
		<>
			<div className={styles['history-container']}>
				<PayrollHistoryTable
					tableData={payrollData}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					fetchAllPayrolls={fetchAllPayrolls}
				/>
			</div>
		</>
	);
};

export default PayrollHistory;
