import { getApi, postApi, putApi } from 'apis';

const getNotificationConfig = async () => {
	return await getApi('/notification/config');
};

const getNotificationListing = async (params: any) => {
	return await getApi('/notification/list', params);
};

const updateNotificationConfig = async (data: any) => {
	return await putApi('/notification/config', data);
};

const getNotificationLength = async () => {
	return await getApi(`/notification/notificationlength`);
};

const dismissNotification = async (data: any) => {
	return await postApi(`/notification/dismiss-notification`, data);
};

const dismissAllNotification = async () => {
	return await postApi(`/notification/dismiss-all-notification`);
};

export const notificationApi = {
	getNotificationConfig,
	updateNotificationConfig,
	getNotificationLength,
	getNotificationListing,
	dismissNotification,
	dismissAllNotification,
};
