import { Table, Tag } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
	Paid,
	PaymentUnderProcessWithText,
	PendingForApproval,
	RejectedByAccounts,
} from 'utils/svgs';
import { formatDate, invalidText } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	fetchRecords: any;
	bankType: string;
};
const PaymentTable = (props: Props) => {
	// const permissionContext = useContext(PermissionContext);
	// const isEdit =
	// 	permissionContext.allowedPermissions.includes('Edit_Loan_Request');
	// const isDelete = permissionContext.allowedPermissions.includes(
	// 	'Delete_Loan_Request'
	// );
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		bankType,
	} = props;

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name | Code"
					dataIndex="employeeName"
					key="employeeName"
					width={'15%'}
					className="bg-white"
					render={(text: string, record: any) => (
						<span>{`${text} | ${record.employeeCode}`}</span>
					)}
				/>

				<Column
					title="Employee Account Number"
					dataIndex=" bankAccountNumber"
					key="bankAccountNumber"
					className="bg-white"
					width={'15%'}
					render={(value: string, record: any) => {
						return !invalidText(record.bankAccountNumber)
							? record.bankAccountNumber
							: '';
					}}
				/>

				<Column
					title="Message"
					dataIndex="message"
					key="message"
					className="bg-white"
					width={'15%'}
					render={(text) => (
						<Ellipse
							message={text}
							maxLength={20}
							key={text}
							tooltipMessage={text}
							isTooltip={true}
						/>
					)}
				/>
				{bankType === 'STANBIC' && (
					<Column
						title="File Type"
						dataIndex="fileType"
						key="fileType"
						className="bg-white"
						width={'15%'}
					/>
				)}
				<Column
					title="Payment Date"
					dataIndex="createdAt"
					key="createdAt"
					width={'15%'}
					className="bg-white"
					render={(value) => (
						<div>
							{value
								? formatDate(
										value,
										profileData.companyDateFormat ??
											'DD/MM/YYYY'
								  )
								: '-'}
						</div>
					)}
				/>
				<Column
					title="Payment Time"
					dataIndex="createdAt"
					key="createdAt"
					width={'15%'}
					className="bg-white"
					render={(value) => (
						<div>
							{value ? dayjs(value).format('hh:mm A') : '-'}
						</div>
					)}
				/>

				<Column
					title="Status"
					dataIndex="status"
					key="status"
					width={'15%'}
					className={styles['statusColumn'] + ' bg-white'}
					render={(value: string, record: any) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						const currentStatus = value;

						switch (currentStatus) {
							case 'PENDING':
								statusText = 'Pending';
								statusClass = styles['status-pending'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'FAILED':
								statusText = 'Failed';
								statusClass = styles['status-rejected'];
								StatusIcon = <RejectedByAccounts />;
								break;
							case 'SUCCESS':
								statusText = 'Success';
								statusClass = styles['status-success'];
								StatusIcon = <Paid />;
								break;
							case 'PROCESSING':
								statusText = 'Processing';
								statusClass = styles['status-success'];
								StatusIcon = <PaymentUnderProcessWithText />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}
						return (
							<div className={styles['statusContainer']}>
								<div className={styles['statusDisplay']}>
									<div className={styles['statusAndEdit']}>
										<Tag
											className={`${styles['statusTag']} ${statusClass}`}
											bordered={false}
										>
											<span
												className={styles['statusIcon']}
											>
												{StatusIcon}
											</span>
											<span>{statusText}</span>
										</Tag>
									</div>
								</div>
							</div>
						);
					}}
				/>
			</Table>
		</div>
	);
};

export default PaymentTable;
