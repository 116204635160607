import { PermissionContext } from 'components/Global/AuthLayout';
import NotificationSettings from 'components/NotificationSettings';
import React, { useContext } from 'react';

const Notification = () => {
	const context = useContext(PermissionContext);
	const check = context.isAdmin;
	return <>{check && <NotificationSettings />}</>;
};

export default Notification;
