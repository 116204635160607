import ReCAPTCHA from 'react-google-recaptcha';

type Props = {
	handleCaptchaChange: any;
	recaptchaRef:any
};

const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const ReCaptchaComponent = (props: Props) => {
	const handleCaptchaChange = props.handleCaptchaChange;

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginTop: '10px',
				marginBottom: '20px',
			}}
		>
			<ReCAPTCHA
				sitekey={GOOGLE_RECAPTCHA_SITE_KEY ?? ''} // Replace with your actual Site Key
				onChange={handleCaptchaChange}
				ref={props.recaptchaRef}
			/>
		</div>
	);
};

export default ReCaptchaComponent;
