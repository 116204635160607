import { PermissionContext } from 'components/Global/AuthLayout';
import UsersTable from 'components/Settings/User';
import { useContext } from 'react';
import './index.scss';

export const Users = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Users');

	return <div>{check && <UsersTable />}</div>;
};
