import {
	CalendarOutlined,
	DollarOutlined,
	ExclamationCircleOutlined,
	GiftOutlined,
	MoonOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { timeLogsApi } from 'Api/timeLogs';
import {
	Button,
	Modal,
	Switch,
	Table,
	Tag,
	Tooltip,
	Upload,
	UploadFile,
} from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import { getApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import DownloadComponent from 'components/Global/Download';
import InputHoursField from 'components/InputShiftHoursField';
import InputTimeLogsHoursField from 'components/InputTimeLogsHoursField';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import {
	invalidText,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
dayjs.extend(isSameOrAfter);

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	selectedShift: string;
	selectedShiftDetails: any;
	fetchShifts: any;
	shiftStartDate: any;
	shiftEndDate: string;
	selectedShiftLastDate: any;
};

const AddLogsModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		selectedShift,
		selectedShiftDetails,
		fetchShifts,
		shiftStartDate,
		shiftEndDate,
		selectedShiftLastDate,
	} = props;

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(31);

	const [date, setDate] = useState<any>();

	// const isSunday = checkSunday(date);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [isSaveNextLoading, setIsSaveNextLoading] = useState(false);

	const [employeeHours, setEmployeeHours] = useState<any>([]);
	const [uploadedFile, setUploadedFile] = useState<any>(null);
	const [fileDocUrl, setFileDocUrl] = useState<any>(null);

	const [isSundayRegularHours, setIsSundayRegularHours] = useState(false);

	const [isHoliday, setIsHoliday] = useState(false);
	const [holiday, setHoliday] = useState('');
	const [isSunday, setIsSunday] = useState(false);
	const [isNightShift, setIsNightShift] = useState(false);

	const [fetchedFile, setFetchedFile] = useState<any>(null);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [pendingToggleState, setPendingToggleState] = useState(false);
	const [lastSubmitLogDate, setLastSubmitLogDate] = useState(null);

	const handleDate = (value: string) => {
		setDate(value);
	};

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		let regularHours = timeStringToMinutes('00:00');
		let overtimeHours = timeStringToMinutes('00:00');
		let timeShiftHours = timeStringToMinutes('00:00');
		let totalHours = timeStringToMinutes('00:00');

		let _data = employeeHours;

		const totalRow = employeeHours.pop();
		_data = _data.map((log: any, index: number) => {
			if (
				newSelectedRowKeys.includes(log.employeeId) &&
				log.employeeName !== 'Total'
			) {
				return {
					...log,
					attendanceStatus: 'PRESENT',
					regularHours:
						log.regularHours === '00:00'
							? log.isHalfDay
								? '04:30'
								: minutesToTimeString(
										selectedShiftDetails.defaultRegularHours
								  )
							: log.regularHours,
					overtimeHours:
						log.overtimeHours === '00:00'
							? minutesToTimeString(
									selectedShiftDetails.defaultOverTimeHours
							  )
							: log.overtimeHours,
					timeShiftHours:
						log.timeShiftHours === '00:00'
							? '00:00'
							: log.timeShiftHours,
					totalHours:
						log.totalHours === '00:00'
							? log.isHalfDay
								? '04:30'
								: minutesToTimeString(
										selectedShiftDetails.defaultRegularHours +
											selectedShiftDetails.defaultOverTimeHours
								  )
							: log.totalHours,
				};
			} else if (log.employeeName !== 'Total') {
				if (regularHours < 0) {
					regularHours = 0;
				}

				return {
					...log,
					attendanceStatus: 'ABSENT',
					regularHours: '00:00',
					overtimeHours: '00:00',
					timeShiftHours: '00:00',
					totalHours: '00:00',
				};
			}
		});

		_data.forEach((item: any) => {
			regularHours += timeStringToMinutes(item.regularHours);
			overtimeHours += timeStringToMinutes(item.overtimeHours);
			timeShiftHours += timeStringToMinutes(item.timeShiftHours);
			totalHours += timeStringToMinutes(item.totalHours);
		});

		totalRow.regularHours = minutesToTimeString(regularHours);
		totalRow.overtimeHours = minutesToTimeString(overtimeHours);
		totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
		totalRow.totalHours = minutesToTimeString(totalHours);

		_data.push(totalRow);
		// setDefaultHours(_data);
		setEmployeeHours(_data);

		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record: any) => ({
			disabled:
				record.isTotal ||
				(record.isOnLeave && !record.isHalfDay) ||
				record.isPayrollRun,
		}),
	};

	const handleToggleChange = (checked: any) => {
		setPendingToggleState(checked);
		setShowConfirmModal(true);
	};

	const handleLogChange = (id: string, name: string, value: any) => {
		let regularHours = 0;
		let timeShiftHours = 0;
		let overtimeHours = 0;
		let totalHours = 0;

		const _data = employeeHours.map((item: any, index: number) => {
			if (item.employeeId === id) {
				regularHours += timeStringToMinutes(
					name === 'regularHours' ? value : item.regularHours
				);
				timeShiftHours += timeStringToMinutes(
					name === 'timeShiftHours' ? value : item.timeShiftHours
				);
				overtimeHours += timeStringToMinutes(
					name === 'overtimeHours' ? value : item.overtimeHours
				);
				totalHours += timeStringToMinutes(
					name === 'totalHours' ? value : item.totalHours
				);
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					[name]: value,
				};
			} else if (index === employeeHours.length - 1) {
				return {
					...item,
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					regularHours:
						isSunday && !isSundayRegularHours
							? '00:00'
							: minutesToTimeString(regularHours),
					timeShiftHours: minutesToTimeString(timeShiftHours),
					overtimeHours: minutesToTimeString(overtimeHours),
					totalHours: minutesToTimeString(totalHours),
				};
			} else {
				regularHours +=
					isSunday && !isSundayRegularHours
						? 0
						: timeStringToMinutes(item.regularHours);
				timeShiftHours += timeStringToMinutes(item.timeShiftHours);
				overtimeHours += timeStringToMinutes(item.overtimeHours);
				totalHours += timeStringToMinutes(item.totalHours);
				return {
					invalidRegularHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					...item,
				};
			}
		});

		setEmployeeHours(_data);
	};

	const fetchTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				page: currentPage,
				pageSize: pageSize - 1,
				shiftId: selectedShift,
			};

			const response = await timeLogsApi.getDefaultTimeLogs(query);
			const defaultSelectedKeys = response.data.data.data
				.filter(
					(item: any) =>
						item.attendanceStatus === 'PRESENT' &&
						(!item.isOnLeave || item.isHalfDay)
				)
				.map((item: any) => item.employeeId);

			setSelectedRowKeys(defaultSelectedKeys);

			setEmployeeHours(response.data.data?.data);
			setLastSubmitLogDate(response.data.lastSubmitLogDate);
			setIsSundayRegularHours(response.data.data?.isSundayRegularHours);
			setTotalRecords(response.data.total);
			setIsNightShift(response.data.data?.isNightShift);
			setIsSunday(response.data.data?.isSunday);
			setIsHoliday(response.data.data?.isHoliday);
			setHoliday(response.data.data?.holidayName);
		} catch (err) {
			//error
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	const handleSubmit = async () => {
		try {
			setIsUpdateLoading(true);

			const data = {
				date: dayjs(date).format('YYYY-MM-DD'),
				isRegularHours: isSundayRegularHours,
				shiftId: selectedShift,
				employeeHoursData: employeeHours
					.filter((item: any) => item.employeeName !== 'Total')
					.map((log: any) => ({
						employeeId: log.employeeId,
						regularHours:
							isSunday && !isSundayRegularHours
								? 0
								: timeStringToMinutes(log.regularHours),
						timeShiftHours: timeStringToMinutes(log.timeShiftHours),
						overtimeHours: timeStringToMinutes(log.overtimeHours),
						attendanceStatus: log.attendanceStatus,
					})),
			};

			await timeLogsApi.createTimeLogs(data);

			// Check if the file needs to be uploaded
			if (
				uploadedFile[0] &&
				(!fetchedFile || uploadedFile[0].name !== fetchedFile.name)
			) {
				const formData = new FormData();

				formData.append('date', data.date);
				formData.append('shiftId', data.shiftId);
				formData.append('name', uploadedFile[0].name);
				formData.append('moduleName', 'TIMELOGS');
				formData.append('file', uploadedFile[0]);
				await timeLogsApi.uploadAttachment(formData);
			}
			toastText('Daily Attendance updated successfully', 'success');
			handleCancel();
			fetchShifts();
		} catch (error: any) {
			let message = 'Something went wrong in submitting Daily Attendance';

			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsUpdateLoading(false);
		}
	};

	const handleSaveAndNext = async () => {
		try {
			setIsSaveNextLoading(true);

			const data = {
				date: dayjs(date).format('YYYY-MM-DD'),
				isRegularHours: isSundayRegularHours,
				shiftId: selectedShift,
				employeeHoursData: employeeHours
					.filter((item: any) => item.employeeName !== 'Total')
					.map((log: any) => ({
						employeeId: log.employeeId,
						regularHours:
							isSunday && !isSundayRegularHours
								? 0
								: timeStringToMinutes(log.regularHours),
						timeShiftHours: timeStringToMinutes(log.timeShiftHours),
						overtimeHours: timeStringToMinutes(log.overtimeHours),
						attendanceStatus: log.attendanceStatus,
					})),
			};
			await timeLogsApi.createTimeLogs(data);

			// Check if the file needs to be uploaded
			if (
				uploadedFile[0] &&
				(!fetchedFile || uploadedFile[0].name !== fetchedFile.name)
			) {
				const formData = new FormData();

				formData.append('date', data.date);
				formData.append('shiftId', data.shiftId);
				formData.append('name', uploadedFile[0].name);
				formData.append('moduleName', 'TIMELOGS');
				formData.append('file', uploadedFile[0]);
				await timeLogsApi.uploadAttachment(formData);
				fetchShifts();
			}

			toastText('Daily Attendance updated successfully', 'success');
		} catch (error: any) {
			let message = 'Something went wrong in submitting Daily Attendance';

			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsSaveNextLoading(false);
		}
	};

	const fetchUploadedFile = async () => {
		try {
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				shiftId: selectedShift,
			};
			const response = await getApi('/timeLogs/attachment', query);

			if (response?.data?.data?.name) {
				const file = {
					name: response.data.data.name,
					status: 'done',
				};

				setFileDocUrl(response.data.data.docUrl);
				setUploadedFile([file]);
				setFetchedFile(file); // Update fetched file details
			} else {
				setUploadedFile([]);
				setFetchedFile(null); // Clear fetched file details
			}
		} catch (err) {
			//error
		}
	};

	const uploadFile = async (options: any) => {
		const { onSuccess, onError, file } = options;

		const formattedDate = dayjs(date).format('YYYY-MM-DD');
		const fmData = new FormData();

		fmData.append('date', formattedDate);
		fmData.append('shiftId', selectedShift);
		fmData.append('moduleName', 'TIMELOGS');
		fmData.append('file', file);

		try {
			setUploadedFile([file]);
			onSuccess(null, file);
			toastText(`file uploaded successfully`, 'success');
		} catch (error) {
			onError(error);
			toastText(`file upload failed`, 'error');
		}
	};

	const uploadProps: any = {
		customRequest: uploadFile,
		showUploadList: {
			showRemoveIcon: false,
			showPreviewIcon: true,
			showDownloadIcon: false,
		},
		fileList: uploadedFile,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}
			const isValidType =
				file.type &&
				(file.type === 'application/pdf' ||
					file?.type.startsWith('image/'));
			if (!isValidType) {
				toastText('Only PDF and image files are allowed!', 'error');
				return Upload.LIST_IGNORE;
			}
			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setUploadedFile([file]);
			return false;
		},
		onChange(info: any) {
			if (info.file.status !== 'uploading') {
				if (info.file.status === 'done') {
					setUploadedFile([info.file]);
				} else if (info.file.status === 'error') {
					// Handle error, if needed
				}
			}
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 3,
			format: (percent: number) =>
				percent && `${parseFloat(percent.toFixed(2))}%`,
		},
	};

	const confirmToggle = () => {
		setIsSundayRegularHours(pendingToggleState);
		if (pendingToggleState) {
			let regularHours = 0;
			let overtimeHours = 0;
			let timeShiftHours = 0;
			let totalHours = 0;

			const _data = employeeHours.map((item: any) => {
				if (item.attendanceStatus === 'PRESENT') {
					const updatedItem = {
						...item,
						regularHours: minutesToTimeString(
							selectedShiftDetails.defaultRegularHours
						),
						overtimeHours: '00:00',
					};

					regularHours += timeStringToMinutes(
						updatedItem.regularHours
					);

					timeShiftHours += timeStringToMinutes(
						updatedItem.timeShiftHours
					);
					totalHours += timeStringToMinutes(updatedItem.totalHours);
					return updatedItem;
				} else {
					// regularHours += timeStringToMinutes(item.regularHours);
					// timeShiftHours += timeStringToMinutes(item.timeShiftHours);
					// totalHours += timeStringToMinutes(item.totalHours);
					return item;
				}
			});

			const totalRow = _data.find(
				(item: any) => item.employeeName === 'Total'
			);
			if (totalRow) {
				totalRow.regularHours = minutesToTimeString(regularHours);

				totalRow.overtimeHours = minutesToTimeString(overtimeHours);

				totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);

				totalRow.totalHours = minutesToTimeString(totalHours);
			}

			setEmployeeHours(_data);
		} else {
			let regularHours = 0;
			let overtimeHours = 0;
			let timeShiftHours = 0;
			let totalHours = 0;

			const _data = employeeHours.map((item: any) => {
				if (item.attendanceStatus === 'PRESENT') {
					const updatedItem = {
						...item,
						overtimeHours: minutesToTimeString(
							selectedShiftDetails.defaultOverTimeHours
						),
						regularHours: '00:00',
					};

					overtimeHours += timeStringToMinutes(
						updatedItem.overtimeHours
					);
					timeShiftHours += timeStringToMinutes(
						updatedItem.timeShiftHours
					);
					totalHours += timeStringToMinutes(updatedItem.totalHours);
					return updatedItem;
				} else {
					return item;
				}
			});

			const totalRow = _data.find(
				(item: any) => item.employeeName === 'Total'
			);
			if (totalRow) {
				totalRow.regularHours = minutesToTimeString(regularHours);
				totalRow.overtimeHours = minutesToTimeString(overtimeHours);
				totalRow.timeShiftHours = minutesToTimeString(timeShiftHours);
				totalRow.totalHours = minutesToTimeString(totalHours);
			}

			setEmployeeHours(_data);
		}
		setShowConfirmModal(false);
	};

	const handleRegularHoursBlur = (
		formattedValue: string,
		employeeId: string
	) => {
		const currentLogs = employeeHours.find(
			(item: any) => item.employeeId === employeeId
		);

		const maxRegularMinutes = selectedShiftDetails.defaultRegularHours;
		let updatedRegularMinutes = timeStringToMinutes(formattedValue);
		let updatedOverTimeMinutes = timeStringToMinutes(
			currentLogs.overtimeHours
		);

		if (updatedRegularMinutes > maxRegularMinutes) {
			updatedOverTimeMinutes =
				updatedRegularMinutes -
				maxRegularMinutes +
				timeStringToMinutes(currentLogs.overtimeHours);
			updatedRegularMinutes = maxRegularMinutes;
		}

		const totalHours = updatedRegularMinutes + updatedOverTimeMinutes;

		const _data = employeeHours.map((item: any) => {
			if (item.employeeId === employeeId) {
				return {
					...item,
					regularHours: minutesToTimeString(updatedRegularMinutes),
					overtimeHours: minutesToTimeString(updatedOverTimeMinutes),
					totalHours: minutesToTimeString(totalHours),
					timeShiftHours: minutesToTimeString(totalHours),
				};
			} else {
				return item;
			}
		});

		let totalRegularHours = 0;
		let totalOvertimeHours = 0;
		let totalTimeShiftHours = 0;
		let totalTotalHours = 0;

		_data.forEach((item: any) => {
			// 	let totalMinutes =
			// 		timeStringToMinutes(item.regularHours) +
			// 		timeStringToMinutes(item.overtimeHours);
			// 	item.totalHours = minutesToTimeString(totalMinutes);
			// }

			if (item.employeeName !== 'Total') {
				totalRegularHours += timeStringToMinutes(item.regularHours);
				totalOvertimeHours += timeStringToMinutes(item.overtimeHours);
				totalTimeShiftHours += timeStringToMinutes(item.timeShiftHours);
				totalTotalHours += timeStringToMinutes(item.totalHours);
			}
		});

		const totalRow = _data.find(
			(item: any) => item.employeeName === 'Total'
		);
		if (totalRow) {
			totalRow.regularHours = minutesToTimeString(totalRegularHours);
			totalRow.overtimeHours = minutesToTimeString(totalOvertimeHours);
			totalRow.timeShiftHours = minutesToTimeString(totalTimeShiftHours);
			totalRow.totalHours = minutesToTimeString(totalTotalHours);
		}
		setEmployeeHours(_data);
	};

	const cancelToggle = () => {
		setShowConfirmModal(false);
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleCancel();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
		{
			text: 'Save & Close',
			isLoading: isUpdateLoading,
			className: 'btn-blue',
			isSubmit: false,

			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '90px',
			minHeight: '42px',
		},
		{
			text: 'Save',
			isLoading: isSaveNextLoading,
			className: 'btn-cancel',
			isSubmit: false,

			disabled: isTimeLogsLoading,
			onclick: () => {
				handleSaveAndNext();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
	];

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				cancelToggle();
			},
		},
		{
			isLoading: false,
			text: 'Confirm',
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				confirmToggle();
			},
		},
	];

	useEffect(() => {
		if (date) {
			fetchTimeLogs();
		}
	}, [date, pageSize, currentPage]);

	useEffect(() => {
		if (isOpen) {
			fetchUploadedFile();
		}
	}, [isOpen, date]);

	useEffect(() => {
		let _date;
		if (
			selectedShiftLastDate &&
			dayjs(selectedShiftLastDate).isSame(dayjs(shiftEndDate), 'day')
		) {
			//add one day
			_date = dayjs(shiftEndDate);
		} else if (selectedShiftLastDate) {
			//add one day in selected date
			_date = dayjs(selectedShiftLastDate).add(1, 'day');
		} else {
			_date = dayjs(shiftStartDate);
		}
		setDate(_date);
	}, [selectedShiftLastDate, shiftEndDate, shiftStartDate]);

	return (
		<Modal
			open={isOpen}
			centered
			closable={false}
			style={{
				minWidth: '1200px',
			}}
			onCancel={handleCancel}
			footer={null} // Footer is handled in JSX for sticky behavior
			rootClassName="addLogsModalRoot"
		>
			<div className="modal">
				<div className={styles['modal-header']}>
					<Title level={4} className="attendance-log-title">
						<span className="modal-title">
							<i className="attendance-icon">📋</i>
							{selectedShiftDetails?.shiftName}'s Attendance
						</span>
					</Title>

					<div className={styles['modal-header-filter']}>
						{isSunday && (
							<Tooltip title="All hours on Sunday are considered overtime">
								<Tag
									style={{
										cursor: 'pointer',
										fontSize: '14px',
										padding: '8px',
									}}
									color="#584495"
								>
									<div
										style={{
											display: 'flex',
											gap: '7px',
										}}
									>
										<span>
											<CalendarOutlined />
										</span>
										<span>Sunday</span>
									</div>
								</Tag>
							</Tooltip>
						)}
						{isHoliday && holiday && (
							<Tooltip title="All hours on Holiday are considered overtime">
								<Tag
									style={{
										cursor: 'pointer',
										fontSize: '14px',
										padding: '8px',
									}}
									color="#584495"
								>
									<div
										style={{
											display: 'flex',
											gap: '7px',
										}}
									>
										<span>
											<GiftOutlined />
										</span>
										<span>{holiday}</span>
										{/* <Ellipse
											message={holiday}
											maxLength={10}
											key={holiday}
											tooltipMessage={holiday}
											isTooltip={true}
										/> */}
									</div>
								</Tag>
							</Tooltip>
						)}
						{isNightShift && (
							<Tooltip title="">
								<Tag
									style={{
										cursor: 'pointer',
										fontSize: '14px',
										padding: '8px',
									}}
									color="#584495"
								>
									<div
										style={{
											display: 'flex',
											gap: '7px',
										}}
									>
										<span>
											<MoonOutlined />
										</span>
										<span>Night Shift</span>
									</div>
								</Tag>
							</Tooltip>
						)}
						{isSunday && !holiday && (
							<Tooltip title="Sunday calculation details">
								<div className={styles['header-toggle']}>
									<Switch
										checked={isSundayRegularHours}
										onChange={handleToggleChange}
										disabled={isTimeLogsLoading}
									/>
									Sunday as Regular Hours
								</div>
							</Tooltip>
						)}
						<DatePickerField
							name="date"
							onChange={handleDate}
							required={false}
							value={date}
							isError={false}
							disabled={isTimeLogsLoading}
							disabledBeforeDates={dayjs(shiftStartDate)}
							disabledAfterDates={dayjs(shiftEndDate)}
							placeholder="Select Date"
						/>
					</div>
				</div>
				{/* Modal Body */}
				<div className={styles['modal-body']}>
					<div className="timelogs-scrollable-content">
						<Table
							dataSource={employeeHours}
							scroll={{ y: 'calc(100vh - 450px)' }}
							pagination={{
								total: totalRecords,
								current: currentPage,
								pageSize: pageSize,
								showSizeChanger: false,
								pageSizeOptions: [10, 20, 50, 100, 200],
							}}
							onChange={tableChangeHandler}
							rowKey={(record) => record.employeeId}
							loading={isTimeLogsLoading}
							rowSelection={rowSelection}
							rowClassName={(record) =>
								record.isTotal ? 'total-row' : ''
							}
						>
							<Column
								title="Employee Name | Code"
								dataIndex="employeeName"
								key="employeeName"
								className="bg-white"
								width="24%"
								render={(text: string, record: any) => (
									<Tooltip
										title={
											record.isHalfDay
												? 'On Half Day leave'
												: record.isOnLeave
												? 'On Full Day Leave'
												: ''
										}
									>
										<span
											style={{
												color: record.isOnLeave
													? 'red'
													: 'inherit',
												display: 'flex',
												alignItems: 'center',
												gap: '0.5rem',
											}}
										>
											{/* Conditionally show the icon based on payroll status */}
											{record.isPayrollRun &&
												record?.payrollStatus && (
													<Tooltip
														title={
															record.payrollStatus ===
																'APPROVED' ||
															record.payrollStatus ===
																'FINALIZE'
																? 'Payroll is Approved/Finalized'
																: record.payrollStatus ===
																  'DRAFT'
																? 'Payroll is in Draft'
																: 'Payroll is Pending'
														}
													>
														<DollarOutlined
															style={{
																color:
																	record.payrollStatus ===
																		'APPROVED' ||
																	record.payrollStatus ===
																		'FINALIZE'
																		? 'green'
																		: record.payrollStatus ===
																		  'DRAFT'
																		? 'orange'
																		: 'grey',
															}}
														/>
													</Tooltip>
												)}
											{text}
										</span>
									</Tooltip>
								)}
							/>

							<Column
								title="Regular Hours"
								dataIndex="regularHours"
								key="regularHours"
								className="bg-white"
								width="19%"
								render={(record: any, data: any) => {
									return data.isTotal ? (
										(isSunday && !isSundayRegularHours) ||
										isHoliday ||
										data.isOnLeave ? (
											'00:00'
										) : (
											record
										)
									) : (
										<InputTimeLogsHoursField
											value={
												(isSunday &&
													!isSundayRegularHours) ||
												isHoliday ||
												(data.isOnLeave &&
													!data.isHalfDay)
													? '00:00'
													: record
											}
											onChange={(value) => {
												handleLogChange(
													data.employeeId,
													'regularHours',
													value
												);
											}}
											onBlur={(value) => {
												handleRegularHoursBlur(
													value,
													data.employeeId
												);
											}}
											disabled={
												data.attendanceStatus ===
													'ABSENT' ||
												(data.isOnLeave &&
													!data.isHalfDay) ||
												data.isPayrollRun ||
												(isSunday &&
													!isSundayRegularHours) ||
												isHoliday
											}
											label="Regular hours"
											required={true}
											name="regularHours"
											isError={data.invalidRegularHours}
											showLabel={false}
											size="middle"
											style={{
												width: '100px',
											}}
										/>
									);
								}}
							/>
							<Column
								title="Overtime Hours"
								dataIndex="overtimeHours"
								key="overtimeHours"
								className="bg-white"
								width="19%"
								render={(record: any, data: any) => {
									return data.isTotal ? (
										data.isOnLeave ? (
											'00:00'
										) : (
											record
										)
									) : (
										<InputHoursField
											value={
												data.isOnLeave
													? '00:00'
													: record
											}
											onChange={(value) => {
												handleLogChange(
													data.employeeId,
													'overtimeHours',
													value
												);
											}}
											disabled={
												(data.isOnLeave &&
													data.isHalfDay) ||
												//		shouldDisableLogs ||
												data.attendanceStatus ===
													'ABSENT' ||
												data.isPayrollRun
											}
											label="Overtime hours"
											required={true}
											name="overtimeHours"
											isError={data.invalidOvertimeHours}
											showLabel={false}
											size="middle"
											style={{
												width: '100px',
											}}
										/>
									);
								}}
							/>

							<Column
								title="Total Hours"
								dataIndex="totalHours"
								key="totalHours"
								className="bg-white"
								width="19%"
								render={(record: any, data: any) => {
									const totalMinutes =
										timeStringToMinutes(
											isHoliday
												? '00:00'
												: data.regularHours
										) +
										timeStringToMinutes(data.overtimeHours);
									return (
										<span className="color-purple">
											{minutesToTimeString(totalMinutes)}
										</span>
									);
								}}
							/>
							{isNightShift && (
								<Column
									title="Time Shift Difference"
									dataIndex="timeShiftHours"
									key="timeShiftHours"
									className="bg-white"
									width="19%"
									render={(record: any, data: any) => {
										const totalMinutes = data.isOnLeave
											? timeStringToMinutes('00:00')
											: timeStringToMinutes(
													isHoliday
														? '00:00'
														: data.regularHours
											  ) +
											  timeStringToMinutes(
													data.overtimeHours
											  );
										return (
											<span className="color-purple">
												{minutesToTimeString(
													totalMinutes
												)}
											</span>
										);
									}}
								/>
							)}
						</Table>
					</div>
				</div>

				{/* Modal Footer */}
				<div className={styles['modal-footer']}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Buttons buttons={buttons} />
						<div
							style={{
								display: 'flex',
								justifyContent: 'end',
								alignItems: 'center',
							}}
						>
							{uploadedFile &&
								uploadedFile[0] &&
								uploadedFile[0]?.name && (
									<DownloadComponent
										documentUrl={fileDocUrl}
										icon={
											<FaFilePdf
												color="#d32f2f"
												size={20}
											/>
										}
										showTooltip={true}
										downloadText="Time Logs"
										tooltipPlacement="top"
										tooltipText={uploadedFile[0]?.name}
										isLocalFile={invalidText(fileDocUrl)}
										localFile={
											invalidText(fileDocUrl)
												? uploadedFile[0]
												: null
										}
									/>
								)}
							<Upload {...uploadProps} showUploadList={false}>
								<Tooltip
									title="Allow uploading manual time logs in PDF or image format (Max: 5MB)."
									placement="top"
								>
									<Button
										icon={<UploadOutlined />}
										disabled={isUpdateLoading}
									>
										Click to Upload
									</Button>
								</Tooltip>
							</Upload>
						</div>
					</div>
				</div>
			</div>
			<Modal
				title={
					<>
						<div className={styles['confirm-modal']}>
							<ExclamationCircleOutlined
								style={{
									color: 'red',
									alignItems: 'center',
								}}
							/>
						</div>
					</>
				}
				className={styles['confirm-modal-container']}
				open={showConfirmModal}
				closable={false}
				footer={[
					<div
						key={'wrapper'}
						className={styles['confirm-modal__button']}
					>
						<Buttons buttons={myButtons}></Buttons>
					</div>,
				]}
			>
				<p className={styles['confirm-modal-text']}>
					{pendingToggleState ? (
						<p>
							Changing Sunday hours setting will impact
							calculation of hours, <b>overtime hours</b> will be
							set to <b>regular hours</b>.
						</p>
					) : (
						<p>
							Changing Sunday hours setting will impact
							calculation of hours, <b>regular hours</b> will be
							set to <b>overtime hours</b>.
						</p>
					)}
					<p>Are you sure you want to proceed with this change?</p>
				</p>
			</Modal>
		</Modal>
	);
};

export default AddLogsModal;
