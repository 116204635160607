import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Table, Typography } from 'antd';
import { postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import { fetchCompanyWizard } from '../../../../../redux/actions/payrollSettingWizardAction';
import styles from './index.module.scss';
import './index.scss';

interface AddModalProps {
	isModalOpen: boolean;
	onClose: () => void;
	currency: any | null;
	branches: any[];
	departments: any[];
	totalEmployees: number;
	employees: any[];
	onRemoveEmployee: (employeeId: string) => void;
	payGroupName: string;
	fetchPayGroup: any;
	closeDrawerByAnimation: () => void;
}

const AddModal: React.FC<AddModalProps> = ({
	isModalOpen,
	onClose,
	currency,
	branches,
	departments,
	totalEmployees,
	employees,
	onRemoveEmployee,
	payGroupName,
	fetchPayGroup,
	closeDrawerByAnimation,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				onClose();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Add Pay Group',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: totalEmployees === 0 || isLoading,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const currencyId = currency?.value || '';
			const branchIds = branches.map((branch) => branch.value);
			const departmentIds = departments.map(
				(department) => department.value
			);
			const employeeIds = employees.map((employee) => employee.id);

			const data = {
				name: payGroupName,
				branchIds,
				departmentIds,
				employeeIds,
				companyCurrencyId: currencyId,
			};

			const response = await postApi('/payGroup/', data);
			dispatch(fetchCompanyWizard());
			onClose();
			closeDrawerByAnimation();
			fetchPayGroup();
			let message =
				response?.data?.message || 'Pay group created successfully';
			dispatch(fetchCompanyWizard());
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to pay group';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Value',
			dataIndex: 'value',
			key: 'value',
		},
	];

	const dataSource = [
		{
			label: 'Currency',
			value: currency?.label || 'N/A',
		},
		{
			label: 'Branches',
			value:
				branches.length > 0
					? branches.map((branch) => branch.label).join(', ')
					: 'No branches available',
		},
		{
			label: 'Departments',
			value:
				departments.length > 0
					? departments
							.map((department) => department.label)
							.join(', ')
					: 'No departments available',
		},
	];

	return (
		<Modal
			open={isModalOpen}
			footer={null}
			closable={false}
			width={1000}
			rootClassName="addEmployeePayGroupModal"
			centered
		>
			<>
				<div className={styles['scrollable-content']}>
					<div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '12px',
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: '12px',
									marginTop: '0px',
								}}
							>
								<Typography.Title
									level={3}
									style={{ margin: '0', padding: '0' }}
								>
									<span>Pay group : {payGroupName}</span>
								</Typography.Title>
								<Button
									icon={<CloseOutlined />}
									onClick={onClose}
								/>
							</div>
							<Table
								columns={columns}
								dataSource={dataSource}
								className="table-pay-group"
								pagination={false}
								bordered={false} // Remove table borders
								style={{
									marginTop: '0px',
									marginBottom: '16px',
									border: 'none',
									maxHeight: '120px', // Set max height to show only 3 rows
								}}
								showHeader={false} // Hide table header
								rowKey={(record) => record.label}
								size="middle" // Optional: Adjust row height
							/>
							<Col span={24}>
								{' '}
								<p
									style={{
										fontSize: '14px',
										fontWeight: 'bold',
									}}
								>
									Total number of Employees:{' '}
									{totalEmployees > 9
										? totalEmployees
										: `0${totalEmployees}`}
								</p>
							</Col>
							<Col span={24}>
								<Typography.Title
									level={5}
									style={{
										marginBottom: '8px',
										marginTop: '15px',
									}}
								>
									Employee Name
								</Typography.Title>
								<List
									dataSource={employees}
									renderItem={(employee) => (
										<List.Item
											actions={[
												<Button
													type="text"
													icon={
														<CloseOutlined
															style={{
																color: '#d9d9d9',
															}}
														/>
													}
													onClick={() =>
														onRemoveEmployee(
															employee.employeeId
														)
													}
												/>,
											]}
										>
											{employee.firstName}{' '}
											{employee.lastName} -{' '}
											<span
												style={{
													color: '#584495',
												}}
											>
												{employee.employeeId}
											</span>
										</List.Item>
									)}
									style={{
										maxHeight: '160px',
										overflowY: 'auto',
										// border: '1px solid #f0f0f0',
										padding: '10px',
									}}
								/>
							</Col>
							{/* </Row> */}
						</div>
					</div>

					{/* </div> */}
					<div style={{ marginTop: '16px' }}>
						{' '}
						<Buttons buttons={buttons} />
					</div>
				</div>
			</>
		</Modal>
	);
};

export default AddModal;
