/* eslint-disable react-hooks/exhaustive-deps */
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import React, { useContext, useEffect, useState } from 'react';
import { handleDownload, handleDownloadXlsx, toastText } from 'utils/utils';
import Styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import JournalEntryReportTable from './JournalEntryTable';
import GenerateJournalEntryModal from './GenerateJEModel';
import { reportApis } from 'Api/reports';
import { PermissionContext } from 'components/Global/AuthLayout';
import { Typography } from 'antd';

const JournalEntryReportComponent = () => {
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [journalEntryData, setJournalEntryData] = useState<any[]>([]);
	const [downloadingReportId, setDownloadingReportId] = useState<
		string | null
	>(null);

	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Add_Payroll_Reports');

	const { Text } = Typography;

	const buttons = [
		{
			text: 'Generate',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setModalOpen(true);
			},
			disabled: isLoading,
		},
	];

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const cancelModal = () => {
		setModalOpen(false);
	};

	const fetchJournalEntryReports = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const journalEntryData = await reportApis.getJournalEntryReports(
				query
			);
			setJournalEntryData(journalEntryData.data.data);
			setTotalRecords(journalEntryData.data.total);
		} catch (err: any) {
			let message =
				'Something went wrong in fetching Journal Entry Reports';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const downloadReport = async (reportId: string) => {
		const reportName = journalEntryData.find(
			(data: any) => data.id === reportId
		)?.pdfDocName;
		return await handleDownload(
			reportId,
			reportApis.downloadJournalEntryReport,
			setDownloadingReportId,
			reportName
		);
	};

	const downloadReportCSV = async (reportId: string) => {
		const reportName = journalEntryData.find(
			(data: any) => data.id === reportId
		)?.csvDocName;
		return await handleDownloadXlsx(
			reportId,
			reportApis.downloadJournalEntryReportCSV,
			setDownloadingReportId,
			reportName
		);
	};

	useEffect(() => {
		fetchJournalEntryReports();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<div className={Styles['container']}>
				<div className={Styles['journal-entry-header']}>
					<p>Journal Entry</p>
					<span className={Styles['divider']}></span>
					<Text style={{ margin: 0, color: '#444' }}>
						Journal Entry Report. This report provides details of
						all journal entries made in the system for accounting
						and auditing purposes.
					</Text>
					{check && <Buttons buttons={buttons} />}
				</div>

				<JournalEntryReportTable
					ReportData={journalEntryData}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					downloadReport={downloadReport}
					downloadingReportId={downloadingReportId}
					setDownloadingReportId={setDownloadingReportId}
					downloadReportCSV={downloadReportCSV}
				/>
			</div>
			{modalOpen && (
				<>
					<GenerateJournalEntryModal
						open={modalOpen}
						cancelGenerateModal={cancelModal}
						fetchJournalEntryDetails={fetchJournalEntryReports}
					/>
				</>
			)}
		</>
	);
};

export default JournalEntryReportComponent;
