import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchPayPeriodAction } from '../../../redux/actions/payPeriodAction';
import DatePickerField from '../DatePicker';
import SelectDropdown from '../SelectDropdown';

type Props = {
	handlePayPeriod: (value: string) => void;
	selectedPayPeriod: string;
	isRow: boolean;
	selectedYear: any;
	handleSelectedYear: (value: any) => void;
	payPeriodError?: boolean;
	rowStyle?: any;
	colStyle?: any;
	colSpan?: number;
	isPayPeriodDisabled?: boolean;
	isPayPeriodRequired?: boolean;
	isYearRequired?: boolean;
	yearError?: boolean;
	isYearLoading?: boolean;
	isPayPeriodLoading?: boolean;
	isPayrollOverview?: boolean;
	employeeId?: string;
};

const GlobalPayPeriod = (props: Props) => {
	const {
		handlePayPeriod,
		selectedPayPeriod,
		rowStyle,
		colStyle,
		isRow = true,
		handleSelectedYear,
		payPeriodError = false,
		isPayPeriodRequired = false,
		isYearRequired = false,
		yearError = false,
		isPayPeriodDisabled,
		selectedYear,
		colSpan,
		isPayPeriodLoading,
		isYearLoading,
		isPayrollOverview,
		employeeId,
	} = props;

	const dispatch = useDispatch<AppDispatch>();

	// const [selectedYear, setSelectedYear] = useState<any>(dayjs());

	const { data: payPeriodData, isLoading } = useSelector(
		(state: any) => state.payPeriod
	);

	useEffect(() => {
		const params: any = {
			year: selectedYear['$y'],
		};
		if (employeeId) {
			params['employeeId'] = employeeId;
		}
		dispatch(fetchPayPeriodAction(params));
	}, [selectedYear]);

	return isRow ? (
		<Row gutter={50} style={rowStyle}>
			<Col style={colStyle} span={colSpan ? colSpan : 12}>
				<DatePickerField
					name="year"
					value={selectedYear}
					label="Year"
					required={isYearRequired}
					helperText="Year is Required"
					onChange={(value) => {
						handleSelectedYear(value);
					}}
					isError={yearError}
					picker="year"
				/>
			</Col>
			<Col style={colStyle} span={colSpan ? colSpan : 12}>
				<SelectDropdown
					placeholder="Pay Period Name"
					options={payPeriodData}
					value={selectedPayPeriod}
					disabled={isLoading || isPayPeriodDisabled}
					loading={isLoading || isPayPeriodLoading}
					onChange={(value) => {
						handlePayPeriod(String(value));
					}}
					size="large"
					required={isPayPeriodRequired}
					helperText="Pay period name is required"
					label="Pay Period"
					isError={payPeriodError}
				/>
			</Col>
		</Row>
	) : (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				gap: '4px',
				...rowStyle,
			}}
		>
			<Col style={colStyle} span={12}>
				<DatePickerField
					name="year"
					value={selectedYear}
					label={isPayrollOverview ? undefined : 'Year'}
					required={isYearRequired}
					helperText="Year is Required"
					onChange={(value) => {
						handleSelectedYear(value);
					}}
					isError={false}
					picker="year"
				/>
			</Col>
			<Col style={colStyle} span={12}>
				<SelectDropdown
					placeholder="Pay Period Name"
					options={payPeriodData}
					value={selectedPayPeriod}
					loading={isLoading}
					disabled={isLoading || isPayPeriodDisabled}
					onChange={(value) => {
						handlePayPeriod(String(value));
					}}
					size="large"
					required={isPayPeriodRequired}
					helperText="Pay period name is required"
					label={isPayrollOverview ? undefined : 'Pay Period'}
					isError={payPeriodError ? true : false}
				/>
			</Col>
		</div>
	);
};

export default GlobalPayPeriod;
