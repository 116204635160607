import Buttons from "components/Global/Buttons";
import React from "react";
import styles from "./index.module.scss";
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import { SyncOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

type headerProps = {
	sidebarDrawerOpen: () => void;
	PayeExist: boolean;
	isLoading: boolean;
	fetchLatestPayeData:any;
};

const PayeTabHeader = ({
	sidebarDrawerOpen,
	PayeExist,
	isLoading,
	fetchLatestPayeData,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const buttonTitle = PayeExist ? 'Edit PAYE' : 'Add PAYE';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['paye-header']}>
			<p>PAYE</p>
			{isAdd && (
				<div className={styles['paye-header__buttons']}>
					<Tooltip title="Refresh all the PAYE">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={
								!isLoading ? fetchLatestPayeData : undefined
							}
						/>
					</Tooltip>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default PayeTabHeader;
