import { HistoryOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Table, Tag } from 'antd';
import { midMonthPayConfigApi } from 'Api/midMonthPayConfig';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { ActiveRule, CloseSvg, InactiveRule } from 'utils/svgs';
import { formatDate, invalidText, toastText } from 'utils/utils';
import { fetchCompanyWizard } from '../../../../redux/actions/payrollSettingWizardAction';
import Header from './Header';
import HistoryModal from './HistoryModal';
import styles from './index.module.scss';
import './index.scss';
const { Option } = Select;
const { TextArea } = Input;

interface RecordType {
	id: string;
	day: number;
	updatedBy: string;
	createDate: string;
	comment: string;
	status: string;
}

interface HistoryType {
	day: number;
	updatedBy: string;
	comment: string;
	createDate: string;
}

const MidMonthPayConfig = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [historyModalVisible, setHistoryModalVisible] = useState(false);

	const [isModalLoading, isSetModalLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [historyData, setHistoryData] = useState<HistoryType[]>([]);
	const [historyLoading, setHistoryLoading] = useState<boolean>(false);

	const dispatch = useDispatch<AppDispatch>();

	const getDayOrdinal = (day: number) => {
		if (day === 1 || day === 21 || day === 31) {
			return 'st of Month';
		} else if (day === 2 || day === 22) {
			return 'nd of Month';
		} else if (day === 3 || day === 23) {
			return 'rd of Month';
		} else {
			return 'th of Month';
		}
	};

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);


	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchMostRecentData = async () => {
		try {
			setIsLoading(true);
			const response = await midMonthPayConfigApi.getMostRecentEntry({});

			const latestData = response.data.data;

			setData(latestData);
		} catch (error) {
			toastText('error', 'Failed to Fetch Mid-Month Data');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchHistoryData = async () => {
		try {
			setHistoryLoading(true);
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			const response = await midMonthPayConfigApi.getAllMidMonthPay(
				query
			);

			const entries: RecordType[] = response.data.data;
			setHistoryData(entries);
			setTotalRecords(response.data.total);
		} catch (error) {
			toastText('Failed to Fetch History Data', 'error');
		} finally {
			setHistoryLoading(false);
		}
	};
		// const fetchHistoryData = async () => {
		// 	try {
		// 		const query = {
		// 			page: currentPage,
		// 			sortBy: sortField,
		// 			sortOrder: sortOrder,
		// 			pageSize: pageSize,
		// 		};

		// 		setHistoryLoading(true);
		// 		const allData = await midMonthPayConfigApi.getAllMidMonthPay;
		// 		const historyDetails: any = allData.data.data;
		// 		setHistoryData(historyDetails);
		// 		setTotalRecords(allData.data.total);
		// 	} catch (error) {
		// 		toastText('Failed to Fetch History Data!', 'error');
		// 	} finally {
		// 		setHistoryLoading(false);
		// 	}
		// };

	const showModal = () => {
		if (data.length > 0) {
			const latestEntry = data[0];
			form.setFieldsValue({
				day: latestEntry.day,
				comment: latestEntry.comment,
			});
		} else {
			form.resetFields();
		}
		setIsModalVisible(true);
	};

	const handleSubmit = async () => {
		try {
			isSetModalLoading(true);
			const values = await form.validateFields();

			const existingData = data.find(
				(entry: RecordType) =>
					entry.day === values.day && entry.comment === values.comment
			);

			if (existingData) {
				toastText(
					'Data with Same Day and Comment Already Exists',
					'error'
				);
				setIsModalVisible(false);
				return;
			}

			const formData = {
				day: values.day,
				comment: values.comment,
				status: true,
			};
			await midMonthPayConfigApi.createMidMonthPay(formData);
			fetchHistoryData();
			fetchMostRecentData();
			
			dispatch(fetchCompanyWizard());
			// const createdData = response.data.data;

			// const combinedTableData: any = {
			// 	id: createdData.id,
			// 	day: createdData.day,
			// 	updatedBy: createdData.updatedBy.fullName,
			// 	createDate: createdData.createdAt,
			// 	comment: createdData.comment,
			// 	status: createdData.status,
			// };

			// setData([combinedTableData]);
			toastText(
				'Mid-Month Date setup has been completed successfully',
				'success'
			);

			fetchMostRecentData();
			setIsModalVisible(false);
		} catch (error) {
			toastText('Failed to Save Mid-Month Pay', 'error');
		} finally {
			isSetModalLoading(false);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const handleHistoryClick = async () => {
		setHistoryModalVisible(true);
	};

	useEffect(() => {
		fetchMostRecentData();
	}, []);

	useEffect(() => {
		fetchHistoryData();
	}, [currentPage, pageSize, sortField, sortOrder]);

	const columns = [
		{
			title: 'Mid Month Day',
			dataIndex: 'day',
			key: 'day',
			className: 'bg-white',
			render: (day: number) => (
				<>
					{day}
					{getDayOrdinal(day)}
				</>
			),
		},
		{
			title: 'Updated By',
			dataIndex: 'updatedBy',
			key: 'updatedBy',
			className: 'bg-white',
			render: (text: any, record: RecordType) => text?.fullName,
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			sorter: true,
			key: 'createdAt',
			className: 'bg-white',
			render: (value: any) =>
				formatDate(
					value,
					profileData.companyDateFormat ?? 'DD/MM/YYYY'
				),
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			className: 'bg-white',
			render: (text: string) =>
				invalidText(text) ? (
					'-'
				) : (
					<Ellipse
						message={text}
						maxLength={20}
						key={text}
						tooltipMessage={text}
						isTooltip={true}
					/>
				),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: 'bg-white',
			render: (status: boolean) => {
				let statusText = '';
				let statusClass = '';
				let StatusIcon = null;

				if (status !== false) {
					statusText = 'Active';
					statusClass = styles['status-active'];
					StatusIcon = <ActiveRule />;
				} else {
					statusText = 'Inactive';
					statusClass = styles['status-inactive'];
					StatusIcon = <InactiveRule />;
				}

				return (
					<Tag
						bordered={false}
						className={`${styles['statusTag']} ${statusClass}`}
					>
						<div className={styles['statusIcon']}>
							{StatusIcon}
							<span>{statusText}</span>
						</div>
					</Tag>
				);
			},
		},
	];

	if (totalRecords > 0) {
		columns.push({
			title: 'History',
			dataIndex: 'action',
			key: 'action',
			className: 'bg-white',
			render: (text: string, record: RecordType) => (
				<Space size="middle">
					<Button
						type="link"
						icon={<HistoryOutlined />}
						onClick={() => handleHistoryClick()}
					></Button>
				</Space>
			),
		});
	}

	return (
		<div>
			<Header showModal={showModal} isLoading={isLoading} />{' '}
			<Table
				columns={columns}
				pagination={false}
				dataSource={data}
				rowKey="key"
				loading={isLoading || historyLoading}
			/>
			<Modal
				className="setUpModal"
				title={
					<div>
						<h3>{data.length > 0 ? 'Edit' : 'Create'}</h3>
						<div onClick={handleCancel}>
							<CloseSvg />
						</div>
					</div>
				}
				closable={false}
				open={isModalVisible}
				footer={[
					<Button
						key="cancel"
						onClick={handleCancel}
						disabled={isModalLoading}
					>
						Cancel
					</Button>,
					<Button
						className="setupButton"
						loading={isModalLoading}
						key="save"
						type="primary"
						onClick={handleSubmit}
					>
						{data.length > 0 ? 'Edit' : 'Create'}
					</Button>,
				]}
				style={{ position: 'absolute', top: 200, right: 20 }}
			>
				<Form form={form} layout="vertical" requiredMark="optional">
					<Form.Item
						name="day"
						label={
							<span>
								Mid Month Day{' '}
								<span style={{ color: 'red' }}>*</span>
							</span>
						}
						rules={[
							{ required: true, message: 'Please select a day' },
						]}
					>
						<Select placeholder="Select a day">
							{Array.from({ length: 31 }, (_, i) => i + 1).map(
								(day) => (
									<Option key={day} value={day}>
										{day} {getDayOrdinal(day)}
									</Option>
								)
							)}
						</Select>
					</Form.Item>
					<Form.Item
						name="comment"
						label={
							<span>
								Comment <span style={{ color: 'red' }}>*</span>
							</span>
						}
						rules={[
							{
								required: true,
								message: 'Please provide a reason',
							},
						]}
					>
						<TextArea rows={4} />
					</Form.Item>
				</Form>
			</Modal>
			<HistoryModal
				isVisible={historyModalVisible}
				onClose={() => setHistoryModalVisible(false)}
				historyData={historyData}
				page={currentPage}
				loading={historyLoading}
				pageSize={pageSize}
				tableChangeHandler={tableChangeHandler}
				totalRecords={totalRecords}
			/>
		</div>
	);
};

export default MidMonthPayConfig;
