import { Header } from 'components/Global';
import { PermissionContext } from 'components/Global/AuthLayout';
import SettingsLayout from 'layouts/Settings';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
	keyWiseConfigurationRouteMapping,
	keyWisePayrollSettingsRouteMapping,
	keyWiseSettingsRouteMapping,
} from 'utils/mappings';
import { getAllowedModule } from 'utils/utils';

interface SelectedValue {
	key: string;
}

export default function Settings() {
	const configurationKeys = [
		'cost-center',
		'department',
		'job-grade',
		'job-title',
		'pay-point',
		'category-code',
		'branch-code',
		'company',
		// 'earning',
		// 'deduction',
		'holidays',
		'leave-management',
	];
	const PayrollSettingsKeys = [
		'company-setup',
		'statutory-components',
		'pay-period',
		'pay-group',
		'payroll-history',
		'mid-month-pay',
		'deduction-code',
		'earning-code',
	];
	const approvalKeys = ['employee', 'time-sheet', 'payroll'];
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];

	const [selectedSidebar, setSelectedSidebar] = useState<string>();

	// Function to handle sidebar changes
	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	useEffect(() => {
		const allowedSettingsModule = getAllowedModule(context.permissions, [
			'Users',
			'Roles',
		]);
		const allowedConfiguration = getAllowedModule(context.permissions, [
			'Company',
			'Branch Code',
			'Category Code',
			'Job Title',
			'Pay Point',
			'Departments',
			'Cost Center',
			'Job Grade',
			// 'Earning',
			// 'Deduction',
			'Leave Management',
			'Holidays',
		]);
		const allowedPayrollSettings = getAllowedModule(context.permissions, [
			'Company Setup',
			'Statutory Components',
			'Pay Period',
			'Pay Group',
			'Mid-Month Pay Setup',
			'Payroll History',
			'Deduction Code',
			'Earnings Code',
		]);
		if (lastSegment === 'settings') {
			if (allowedSettingsModule) {
				const _key =
					keyWiseSettingsRouteMapping[
						allowedSettingsModule.toLowerCase() as keyof typeof keyWiseSettingsRouteMapping
					];

				navigate(`/settings/${_key}`);
			} else if (
				context.allowedPermissions.includes('Allow_Configuration')
			) {
				const key =
					keyWiseConfigurationRouteMapping[
						allowedConfiguration?.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
					];
				navigate(`/settings/configuration/${key}`);
			} else if (
				context.allowedPermissions.includes('Allow_Payroll_Settings')
			) {
				const key =
					keyWisePayrollSettingsRouteMapping[
						allowedPayrollSettings?.toLowerCase() as keyof typeof keyWisePayrollSettingsRouteMapping
					];
				navigate(`/settings/payroll-settings/${key}`);
			} else {
				navigate(`/`);
			}
		}

		setSelectedSidebar(
			configurationKeys.includes(lastSegment)
				? 'configuration'
				: approvalKeys.includes(lastSegment)
				? 'approval'
				: PayrollSettingsKeys.includes(lastSegment)
				? 'payroll-wizard'
				: lastSegment
		);
	}, [lastSegment]);

	return (
		<>
			<Header />
			<SettingsLayout
				onSideBarChange={sideBarChangeHandler}
				selectedSidebar={selectedSidebar}
			>
				<Outlet />
			</SettingsLayout>
		</>
	);
}
