import { HistoryOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import DownloadComponent from 'components/Global/Download';
import Ellipse from 'components/Global/Ellipse';
import { useSelector } from 'react-redux';
import { formatDate, invalidText } from 'utils/utils';
import './index.scss';
const { Column } = Table;

type Props = {
	skillsLevyData: any;
	isLoading: boolean;
	showModal: () => void;
	totalRecords: number;
};

const SkillsLevyTable = (props: Props) => {
	const { skillsLevyData, isLoading, showModal, totalRecords } = props;

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<div className="skillLevy-table">
			<Table
				dataSource={skillsLevyData}
				rowKey={(record) => record.id}
				loading={isLoading}
				pagination={false}
				size="small"
			>
				<Column
					title="Effective Date"
					dataIndex="effectiveDate"
					key="effectiveDate"
					className="bg-white"
					width={'15%'}
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
				/>
				<Column
					title="Percentage of Total Gross  "
					dataIndex="grossPercentage"
					key="grossPercentage"
					className="bg-white"
					render={(value) => `${value}%`}
					width={'20%'}
				/>
				<Column
					title="Updated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					width={'15%'}
					render={(text) => text?.fullName}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					key="notes"
					className="bg-white"
					width={'20%'}
					render={(text) =>
						invalidText(text) ? (
							'-'
						) : (
							<Ellipse
								message={text}
								maxLength={20}
								key={text}
								tooltipMessage={text}
								isTooltip={true}
							/>
						)
					}
				/>
				<Column
					title="Attachment"
					dataIndex="attachment"
					key="attachment"
					className="bg-white"
					width={'18%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<div>
									<DownloadComponent
										documentUrl={record.documentUrl}
										showTooltip={true}
										downloadText={record.documentName}
										text={record.documentName}
										isLink={true}
										maxLength={20}
									/>
								</div>
							) : (
								'-'
							)}
						</div>
					)}
				/>
				{totalRecords >= 1 && (
					<Column
						title="History"
						dataIndex="history"
						key="history"
						className="bg-white"
						width={'10%'}
						render={() => (
							<Button
								type="link"
								icon={<HistoryOutlined />}
								onClick={showModal}
							/>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default SkillsLevyTable;
