import { Space } from 'antd';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';

type Props = {
	button: any;
};

const SalaryDetailHeader = (props: Props) => {
	const {  button } = props;

	return (
		<div className={styles['employee-header']}>
			<Space className={styles['employee-header-left']}>
				Documents
			</Space>
			<Space className={styles['employee-header-right']}>
				<Buttons buttons={button} />
			</Space>
		</div>
	);
};

export default SalaryDetailHeader;
