import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanyWizard } from '../actions/payrollSettingWizardAction';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const payrollSettingWizardSlice = createSlice({
	name: 'payrollSettingWizard',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCompanyWizard.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(fetchCompanyWizard.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(fetchCompanyWizard.rejected, (state, action: any) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default payrollSettingWizardSlice;
