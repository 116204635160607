import { Col, Row } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import FormulaInput from 'components/Global/FormulaField';
import InputField from 'components/Global/InputField';
import InputNumberField from 'components/Global/InputNumberField';
import { FC, useEffect, useState } from 'react';
import {
	allowedVariablesForEarningsFormula,
	hasFormError,
	invalidText,
	removeEmptyValues,
	toastText,
	validateFormData,
	validateFormula,
} from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { earningsFormulaMapping } from 'constants/Data';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchCompanyWizard } from '../../../../../redux/actions/payrollSettingWizardAction';

const AddEarningBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchEarnings, editSelected } = props;
	const [hasError, setHasError] = useState(false);
	const dispatch = useDispatch<AppDispatch>();

	const [earningsDetails, setEarningsDetails] = useState<any>({
		displayName: '',
		amount: 0,
		formula: '',
	});

	const { baseCurrencySymbol } = useSelector(
		(state: any) => state.userProfile.data
	);

	const [formError, setFormError] = useState<any>({
		displayName: false,
		amount: false,
		formula: false,
	});

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
		{
			text: `Save`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
		},
	];

	useEffect(() => {
		if (editSelected) {
			const object = removeEmptyValues(editSelected);
			setEarningsDetails({ ...object });
		}
	}, [editSelected]);

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...earningsDetails },
			{ ...formError }
		);
		checkFormError = {
			...checkFormError,
			formula: earningsDetails?.formula
				? !validateFormula(
						earningsDetails.formula as string,
						allowedVariablesForEarningsFormula
				  )
				: false,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			if (editSelected) {
				let data: any = {
					earningCodeId: editSelected?.id,
					...earningsDetails,
					amount: Number(earningsDetails.amount),
					displayName: earningsDetails.displayName,
					percent: Number(earningsDetails.percent),
					formula: earningsDetails?.formula ?? null,
					function: earningsDetails?.function ?? null,
				};

				await updateEarning(data);
			} else {
				let data: any = {
					...earningsDetails,
					amount: Number(earningsDetails.amount),
					percent: Number(earningsDetails.percent),
				};
				await addEarning(data);
				dispatch(fetchCompanyWizard());
			}
		}
	};

	const updateEarning = async (earningData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/earning', earningData);
			fetchEarnings();
			closeDrawerByAnimation();
			let message =
				response?.data?.message || 'Earning updated successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong in updating earning';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addEarning = async (finalData: any) => {
		try {
			setLoading(true);
			const response = await postApi('/earning', finalData);
			fetchEarnings();
			closeDrawerByAnimation();
			let message =
				response?.data?.message ||
				'Earninig details saved successfully';
			dispatch(fetchCompanyWizard());

			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating earning';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}
		if (
			typeof value === 'string' &&
			name === 'formula' &&
			!validateFormula(
				value as string,
				allowedVariablesForEarningsFormula
			)
		) {
			setHasError(true);
		}

		OnChange(value, name);
	};

	// const OnChange = (value: string | number | null, key: string) => {
	// 	setEarningsDetails((prev: any) => {
	// 		return {
	// 			...prev,
	// 			[key]: value,
	// 		};
	// 	});

	// 			const checkFormError = validateFormData(
	// 				{ [key]: value },
	// 				{ ...formError }
	// 			);
	// 			setFormError(checkFormError);
	// };

	const OnChange = (value: string | number | null, key: string) => {
		setEarningsDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const isAmountFilled =
			key === 'amount' ? !!value : !!earningsDetails.amount;
		const isFormulaFilled =
			key === 'formula' ? !!value : !!earningsDetails.formula;

		let mutualExclusionError = null;

		if (isAmountFilled && isFormulaFilled) {
			mutualExclusionError = 'Only one field can be filled at a time.';
		} else if (!isAmountFilled && !isFormulaFilled) {
			mutualExclusionError = 'At least one field must be filled.';
		}
		const updatedFormError = {
			...formError,
			mutualExclusion: mutualExclusionError,
		};

		const checkFormError = validateFormData(
			{ [key]: value },
			updatedFormError
		);

		setFormError(checkFormError);
	};

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['side-drawer-body']}>
				{/* <Row gutter={5}> */}
				{/* Render the InputField only if editSelected exists */}
				{/* {editSelected && (
						<Col span={24}>
							<InputField
								name="code"
								value={earningsDetails.code}
								label="Earning Code"
								required={true}
								helperText="Earning code is required"
								onChange={() => {}} // Disabled field, no onChange needed
								isError={false}
								disabled={true} // Disable the field
							/>
						</Col>
					)} */}
				{/* </Row> */}
				{/* <Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputField
							name="acctNo"
							value={earningsDetails.acctNo}
							label="Acct Number"
							required={true}
							helperText="Acct. Number is required"
							onChange={(value) => {
								handleChange(value, 'acctNo', true);
							}}
							isError={formError.acctNo}
						/>
					</Col>
				</Row> */}
				{/* {!editSelected && (
					<Row gutter={5} className="margin-top-10">
						<Col span={24}>
							<InputField
								name="description"
								value={earningsDetails.description}
								label="Earning"
								required={true}
								helperText="Earning is required"
								// onChange={(value) => {
								// 	handleChange(value, 'description', true);
								// }}
								onChange={(value) => {
									handleChange(
										value?.toUpperCase(),
										'description',
										true
									);
								}}
								isError={formError.description}
							/>
						</Col>
					</Row>
				)} */}
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputField
							name="displayName"
							value={earningsDetails.displayName}
							label="Display Name"
							required={true}
							helperText="Display Name is required"
							onChange={(value) => {
								handleChange(value, 'displayName', true);
							}}
							isError={formError.displayName}
						/>
					</Col>
				</Row>
				{/* <Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputField
							name="seqNo"
							value={earningsDetails.seqNo}
							label="Sequence Number"
							required={true}
							helperText="Sequence Number is required"
							onChange={(value) => {
								handleChange(value, 'seqNo', true);
							}}
							isError={formError.seqNo}
						/>
					</Col>
				</Row> */}
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputNumberField
							name="amount"
							value={earningsDetails.amount}
							label="Amount"
							suffix={baseCurrencySymbol}
							required={false}
							helperText={
								formError.mutualExclusion ||
								(formError.amount ? 'Amount is required' : '')
							}
							onChange={(value) => {
								handleChange(value, 'amount', false);
							}}
							isError={!!formError.mutualExclusion}
						/>
					</Col>
				</Row>
				{/* <Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputNumberField
							name="percent"
							value={earningsDetails.percent}
							label="Percent"
							required={false}
							helperText="Percent is required"
							onChange={(value) => {
								handleChange(value, 'percent', true);
							}}
							isError={formError.percent}
						/>
					</Col>
				</Row> */}
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<FormulaInput
							name="formula"
							value={earningsDetails?.formula}
							placeholder={'SAL*OTH*1.5'}
							label="Formula"
							required={false}
							helperText={
								formError.mutualExclusion ||
								(formError.formula
									? 'Enter a valid formula'
									: '')
							}
							onChange={(value) => {
								handleChange(value, 'formula', false);
							}}
							isError={!!formError.mutualExclusion}
							labelSuffix={<InfoCircleOutlined />}
							labelSuffixContent={
								<div>
									<p
										style={{
											fontWeight: 500,
											fontSize: '18px',
										}}
									>
										Formula Variables
									</p>
									<ul>
										{Object.entries(
											earningsFormulaMapping
										).map(([key, value]) => (
											<ul key={key}>
												<strong>{key}:</strong> {value}
											</ul>
										))}
									</ul>
								</div>
							}
						/>
					</Col>
				</Row>
				{/* <Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputField
							name="function"
							value={earningsDetails?.function}
							label="Function"
							required={false}
							helperText="Function is required"
							onChange={(value) => {
								handleChange(value, 'function', true);
							}}
							isError={formError?.function}
						/>
					</Col>
				</Row> */}
				<div className={styles['buttons']}>
					<Buttons buttons={buttons} />
				</div>
			</div>
		</>
	);
};

export default AddEarningBody;
