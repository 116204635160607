import { Button, Form } from 'antd';
import InputWithLabelAndSvg from 'components/Global/InputWithLabel';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { LoginLayoutBodyProps } from './types';
import ReCaptchaComponent from 'components/Global/Recaptcha';
import CheckBox from 'components/Global/Checkbox';

// Login layout body designing
const LoginLayoutBody: FC<LoginLayoutBodyProps> = (props) => {
	// Inits
	const {
		title,
		description,
		formData: loginFields,
		buttonTitle,
		redirectText,
		redirectUrl,
		onSubmit,
		isLoading,
		setIsPasswordValidate,
		handleRecaptcha,
		isRememberMe,
		setIsRememberMe,
		recaptchaRef,
	} = props;

	// Initialize navigation
	const navigate = useNavigate();
	// JSX
	return (
		<div className={styles['login-body']}>
			<Form
				name="basic"
				onFinish={onSubmit}
				// onFinishFailed={onFinishFailed}
			>
				<div className={styles['login-body__top']}>
					<h4 className={styles['login-body__top--title']}>
						{title}
					</h4>
					{description && (
						<div className={styles['login-body__top--description']}>
							<p
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							/>
						</div>
					)}
				</div>
				<div className={styles['login-body__center']}>
					{loginFields.map((singleUserInput, key) => {
						return (
							<InputWithLabelAndSvg
								key={key}
								singleUserInput={singleUserInput}
								setIsPasswordValidate={setIsPasswordValidate}
							/>
						);
					})}
				</div>
				{title === 'Log in' && (
					<ReCaptchaComponent
						handleCaptchaChange={handleRecaptcha}
						recaptchaRef={recaptchaRef}
					/>
				)}

				<div className={styles['login-body__remberme-forgot']}>
					{title === 'Log in' && (
						<div className={styles['checkbox']}>
							<CheckBox
								checked={isRememberMe}
								onChange={() => setIsRememberMe(!isRememberMe)}
								label="Remember Me"
							/>
						</div>
					)}
					<p
						className={
							styles[
								'login-body__remberme-forgot--forgot-password'
							]
						}
						onClick={() => navigate(`${redirectUrl}`)}
					>
						{redirectText}
					</p>
				</div>

				<div className={styles['login-body__end']}>
					<Button
						type="primary"
						className={`${styles['login-body__end--button']} ${
							isLoading && 'pointer-event-none'
						}`}
						size="large"
						htmlType="submit"
						loading={isLoading}
						disabled={isLoading}
					>
						{buttonTitle}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default LoginLayoutBody;
