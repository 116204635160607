import { Checkbox, Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { shiftsApi } from 'Api/shifts';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import TimePickerField from 'components/Global/TimePicker';
import InputHoursField from 'components/InputShiftHoursField';
import dayjs, { Dayjs } from 'dayjs';
import { ShiftDetailsInterface } from 'interfaces/employee.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { employeeApi } from 'Api/employee';
import InputShiftHoursField from 'components/InputShiftHoursField';
import AddLogsModal from '../AddLogsModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
	closeModal: () => void;
	isModalOpen: boolean;
	editShift: any;
	fetchShifts: () => void;
	isViewOnly: boolean;
	isUserSupervisor: boolean;
};

const CreateShiftModal = (props: Props) => {
	const {
		isModalOpen,
		closeModal,
		editShift,
		fetchShifts,
		isViewOnly = false,
		isUserSupervisor,
	} = props;

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [shiftDetails, setShiftDetails] = useState<ShiftDetailsInterface>({
		startDate: '',
		endDate: '',
		startTime: dayjs(new Date()),
		endTime: dayjs(new Date()),
		branchId: null,
		shiftName: '',
		employeeIds: [],
		supervisorId: null,
		notes: null,
		defaultRegularHours: '08:00',
		defaultOverTimeHours: '00:00',
		isForeMan: null,
	});

	const [hasError, setHasError] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);
	const [isNightShift, setIsNightShift] = useState(false);
	const [filteredEmployees, setFilteredEmployees] = useState<any>([]);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedShiftDetails, setSelectedShiftDetails] = useState<any>(null);
	const [selectedShift, setSelectedShift] = useState<any>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [missingDates, setMissingDates] = useState<any>();
	const [superVisorOptions, setSuperVisorOptions] = useState<any>([]);
	const [isSupervisorLoading, setIsSupervisorLoading] = useState(false);

	const [formError, setFormError] = useState<any>({
		startDate: false,
		endDate: false,
		startTime: false,
		endTime: false,
		branchId: false,
		shiftName: false,
		employeeIds: false,
		supervisorId: false,
		notes: false,
		isForeMan: false,
	});

	const handleCloseModal = async () => {
		setShiftDetails({
			startDate: '',
			endDate: '',
			startTime: dayjs(new Date()),
			endTime: dayjs(new Date()),
			branchId: null,
			shiftName: '',
			employeeIds: [],
			supervisorId: null,
			notes: null,
			defaultRegularHours: '08:00',
			defaultOverTimeHours: '00:00',
			isForeMan: null,
		});
		setFormError({
			startDate: false,
			endDate: false,
			startTime: false,
			endTime: false,
			branchId: false,
			shiftName: false,
			employeeIds: false,
			supervisorId: false,
			notes: false,
			defaultRegularHours: false,
			isForeMan: false,
			// defaultOverTimeHours: false,
		});
		setHasError(false);
		closeModal();
	};

	const handleOk = () => {
		handleSubmit();
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleChange = (
		value: string | number | null | string[] | Dayjs | any,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		//in this for employeeIds if all is Selected then add all the employees
		if (name === 'employeeIds' && value.includes('all')) {
			const allEmployees = filteredEmployees
				.filter((emp: any) => emp.value !== 'all')
				.map((emp: any) => emp.value);
			const selectedEmployees = shiftDetails.employeeIds;
			if (selectedEmployees.length === allEmployees.length) {
				value = [];
			} else value = allEmployees;
		}

		if (name === 'branchId') {
			setShiftDetails((prev) => {
				return {
					...prev,
					employeeIds: [],
				};
			});
		}

		if (name === 'startDate') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev) => {
				return {
					...prev,
					startTime: dayjs(value as string),
				};
			});
		}

		if (name === 'endDate') {
			setShiftDetails((prev: any) => {
				const updatedEndTime = prev.startTime
					? prev.startTime.add(2, 'hour')
					: null;
				return {
					...prev,
					endDate: value,
					endTime: updatedEndTime,
				};
			});
		}

		if (name === 'defaultRegularHours') {
			// Only update the state without validation
			setShiftDetails((prev: any) => ({
				...prev,
				defaultRegularHours: value,
			}));

			OnChange(value, name);
			return;
		}

		if (name === 'defaultOverTimeHours') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev: any) => {
				return {
					...prev,
					defaultOverTimeHours: value,
				};
			});
		}

		if (name === 'isForeMan') {
			setShiftDetails((prev: any) => {
				const maxRegularHours = value === 'true' ? '12:00' : '08:00';
				return {
					...prev,
					employeeIds: [],
					defaultRegularHours: maxRegularHours,
					defaultOverTimeHours: '00:00',
				};
			});
		}

		if (name !== 'defaultRegularHours') {
			OnChange(value, name);
		}
	};

	const OnChange = (
		value: string | number | null | string[] | Dayjs,
		key: string
	) => {
		setShiftDetails((prev) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleShiftValidation = async () => {
		let checkFormError = validateFormData(
			{
				...shiftDetails,
			},
			{ ...formError }
		);

		checkFormError = {
			...checkFormError,
			employeeIds: shiftDetails.employeeIds.length < 1 ? true : false,
			notes: false,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			let _data: any = {
				...shiftDetails,
				startDate: formatToDateOnly(shiftDetails.startDate),
				endDate: formatToDateOnly(shiftDetails.endDate),
				defaultRegularHours: timeStringToMinutes(
					shiftDetails.defaultRegularHours
				),
				defaultOverTimeHours: timeStringToMinutes(
					shiftDetails.defaultOverTimeHours
				),
				isNightShift,
				isForeManShift:
					shiftDetails.isForeMan === 'true' ? true : false,
			};
			delete _data['isForeMan'];

			if (!_data.notes) {
				delete _data['notes'];
			}

			const response = await shiftsApi.validateShift(_data);
			const dateNotValidated = response.data.data.isSame === false;
			if (dateNotValidated) {
				setMissingDates(response.data.data.endDate);
				setIsModalVisible(true);
			} else {
				handleSubmit();
			}

			return response;
		}
	};

	const handleRegularHoursBlur = (formattedValue: string) => {
		let maxRegularHours = '08:00';
		if (shiftDetails.isForeMan === 'true') {
			maxRegularHours = '12:00';
		} else if (shiftDetails.isForeMan === null) {
			return;
		}

		const valueMinutes = timeStringToMinutes(formattedValue);
		const maxMinutes = timeStringToMinutes(maxRegularHours);
		let updatedRegularHours = formattedValue;
		let updatedOvertimeHours = shiftDetails.defaultOverTimeHours;

		if (valueMinutes > maxMinutes) {
			// If entered value exceeds max regular hours
			updatedRegularHours = maxRegularHours;

			// Calculate overtime hours
			const overtimeMinutes = valueMinutes - maxMinutes;
			updatedOvertimeHours = minutesToTimeString(overtimeMinutes);
		}

		// Update state with validated values
		setShiftDetails((prev: any) => ({
			...prev,
			defaultRegularHours: updatedRegularHours,
			defaultOverTimeHours: updatedOvertimeHours,
		}));

		// Trigger OnChange with updated values
		OnChange(updatedRegularHours, 'defaultRegularHours');
		OnChange(updatedOvertimeHours, 'defaultOverTimeHours');
	};

	const checkStartDate = (isSupervisor: boolean) => {
		const startDate = dayjs(shiftDetails.startDate);
		const currentDate = dayjs();

		if (isSupervisor) {
			return false;
		}

		if (startDate.isAfter(currentDate, 'day')) {
			return false;
		}

		return true;
	};

	const handleSubmit = async () => {
		// let checkFormError = validateFormData(
		// 	{
		// 		...shiftDetails,
		// 	},
		// 	{ ...formError }
		// );
		// checkFormError = {
		// 	...checkFormError,
		// 	employeeIds: shiftDetails.employeeIds.length < 1 ? true : false,
		// 	notes: false,
		// };

		// setFormError(checkFormError);

		// if (hasFormError(checkFormError)) {
		// 	return;
		// } else {

		let _data: any = {
			...shiftDetails,
			startDate: formatToDateOnly(shiftDetails.startDate),
			endDate: formatToDateOnly(shiftDetails.endDate),
			defaultRegularHours: timeStringToMinutes(
				shiftDetails.defaultRegularHours
			),
			defaultOverTimeHours: timeStringToMinutes(
				shiftDetails.defaultOverTimeHours
			),
			isNightShift,
			isForeManShift: shiftDetails.isForeMan === 'true' ? true : false,
		};

		delete _data['isForeMan'];

		if (!_data.notes) {
			delete _data['notes'];
		}

		if (!editShift) {
			try {
				setIsSubmitLoading(true);

				const apiRes = await shiftsApi.createShifts(_data);

				const successMessage =
					apiRes?.data?.message || 'Shift created successfully';

				const isSuperVisor = apiRes?.data?.isSupervisor;

				fetchShifts();
				const checkStartDateValue = checkStartDate(isSuperVisor);

				checkStartDateValue && setIsPopupVisible(true);
				setSelectedShiftDetails(apiRes?.data?.data);
				setSelectedShift(apiRes?.data?.data?.id);
				toastText(successMessage, 'success');
				isSuperVisor && handleCloseModal();
			} catch (error: any) {
				let message =
					'Something went wrong in fetching employee details';

				if (error.response.data.message) {
					message = error.response.data.message;
				}
				toastText(message, 'error');
			} finally {
				setIsSubmitLoading(false);
			}
		} else {
			try {
				setIsSubmitLoading(true);
				const apiRes = await shiftsApi.updateShift(editShift.id, _data);

				const successMessage =
					apiRes?.data?.message || 'Shift edit successfully';
				const isSuperVisor = apiRes?.data?.isSupervisor;

				fetchShifts();
				const checkStartDateValue = checkStartDate(isSuperVisor);

				checkStartDateValue && setIsPopupVisible(true);
				// setSelectedShiftDetails(apiRes?.data?.data);
				setSelectedShift(apiRes?.data?.data?.id);
				toastText(successMessage, 'success');
				isSuperVisor && handleCloseModal();
			} catch (error: any) {
				// handleCloseModal();
				const message =
					error?.response?.data?.message ||
					'Something went wrong in editing shift';
				toastText(message, 'error');
			} finally {
				setIsSubmitLoading(false);
			}
		}
		// }
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleCloseModal();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: `Save`,
			isLoading: isSubmitLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleShiftValidation();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];
	const confirmButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleCancelConfirmModal();
			},
			fontSize: '1.4rem',
			minWidth: '1.5rem',
			minHeight: '3rem',
		},
		{
			text: `Confirm`,
			isLoading: isSubmitLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleConfirmModal();
			},
			fontSize: '1.4rem',
			minWidth: '1.5rem',
			minHeight: '3rem',
		},
	];

	const missingDatesButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleCancel();
			},
			fontSize: '1.4rem',
			minWidth: '1.5rem',
			minHeight: '3rem',
		},
		{
			text: `Ok`,
			isLoading: isSubmitLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleOk();
				// editShift ? handleEditOk() : handleOk();
			},
			fontSize: '1.4rem',
			minWidth: '1.5rem',
			minHeight: '3rem',
		},
	];

	const fetchingAllHourlyEmployee = async () => {
		try {
			const response = await employeeApi.getHourlyEmployeeOptions({});

			if (response.data && Array.isArray(response.data.data)) {
				const formattedOptions = response.data.data.map((emp: any) => ({
					label: `${emp.employeeId} | ${emp.employeeName}`,
					value: emp.id,
					employeeBranchId: emp.employeeBranchId,
					isForeMan: emp.isForeMan,
				}));
				setEmployeeOptions(formattedOptions);
			} else {
				setEmployeeOptions([]);
			}
		} catch (error) {}
	};

	const handleCancelConfirmModal = () => {
		fetchShifts();
		closeModal();
		setIsPopupVisible(false);
	};

	const startDateMinusOne = dayjs(shiftDetails.startDate).subtract(1, 'day');

	const handleConfirmModal = () => {
		setIsOpen(true);
		closeModal();
		setIsPopupVisible(false);
	};

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	useEffect(() => {
		if (isModalOpen) {
			if (editShift) {
				setShiftDetails({
					...editShift,
					startDate: dayjs(editShift.startDate),
					endDate: dayjs(editShift.endDate),
					startTime: dayjs(editShift.startTime),
					endTime: dayjs(editShift.endTime),
					defaultRegularHours: minutesToTimeString(
						editShift.defaultRegularHours
					),
					defaultOverTimeHours: minutesToTimeString(
						editShift.defaultOverTimeHours
					),
					isForeMan: String(editShift.isForeMan),
				});
				setIsNightShift(editShift.isNightShift);
			} else {
				setShiftDetails({
					startDate: '',
					endDate: '',
					startTime: dayjs(new Date()),
					endTime: dayjs(new Date()),
					branchId: null,
					shiftName: '',
					employeeIds: [],
					supervisorId: null,
					notes: null,
					defaultRegularHours: '08:00',
					defaultOverTimeHours: '00:00',
					isForeMan: null,
				});
				setIsNightShift(false);
			}
			setFormError({
				startDate: false,
				endDate: false,
				startTime: false,
				endTime: false,
				branchId: false,
				shiftName: false,
				employeeIds: false,
				supervisorId: false,
				notes: false,
				defaultRegularHours: false,
				isForeMan: false,
				// defaultOverTimeHours: false,
			});
		}
		setIsDisabled(false);
		setHasError(false);
	}, [isModalOpen]);

	useEffect(() => {
		if (isModalOpen) fetchingAllHourlyEmployee();
	}, [isModalOpen]);

	useEffect(() => {
		if (!employeeOptions?.length) return;
		if (employeeOptions && employeeOptions.length > 0) {
			const filteredOptions =
				shiftDetails.branchId && shiftDetails.isForeMan !== null
					? employeeOptions.filter(
							(option: any) =>
								option.employeeBranchId ===
									shiftDetails.branchId &&
								option.isForeMan ===
									(shiftDetails.isForeMan === 'true')
					  )
					: [];

			if (filteredOptions.length > 0) {
				filteredOptions.unshift({
					label: 'Select All Employees',
					value: 'all',
					employeeBranchId: shiftDetails.branchId,
					isForeMan: shiftDetails.isForeMan === 'true',
				});
			}
			setFilteredEmployees(filteredOptions);
		}
	}, [shiftDetails.branchId, shiftDetails.isForeMan, employeeOptions]);

	const fetchSupervisorOptions = async () => {
		try {
			setIsSupervisorLoading(true);
			const response = await shiftsApi.getSuperVisorOptions();
			setSuperVisorOptions(response.data.data);
		} catch (error) {
			console.error('error: ', error);
		} finally {
			setIsSupervisorLoading(false);
		}
	};

	useEffect(() => {
		if (isModalOpen) fetchSupervisorOptions();
	}, [isModalOpen]);

	return (
		<>
			<Modal
				open={isModalOpen}
				// onOk={handleSubmit}
				onCancel={handleCloseModal}
				okText={'Save'}
				closable={false}
				footer={null}
				className="document__popup"
				width="1000px"
				maskClosable={true}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>
							{editShift && isViewOnly ? (
								<span>{`Shift Details`}</span>
							) : (
								<span>
									<strong>
										{' '}
										{`${editShift ? 'Edit' : 'Add'} Shift`}
									</strong>
								</span>
							)}
						</Title>
						<div className={styles['modal-header-filter']}></div>
					</div>
					<div>
						<div className={styles['shift-scrollable-content']}>
							<Row
								className={styles['form-container-card']}
								gutter={50}
							>
								{isViewOnly && (
									<hr
										style={{
											width: '100%',
											marginBottom: '17px',
										}}
									/>
								)}
								<Col span={24} className={styles['col']}>
									<InputField
										name="shiftName"
										value={shiftDetails.shiftName}
										label="Shift Name"
										required={true}
										helperText="Shift name required"
										onChange={(value) => {
											handleChange(
												value,
												'shiftName',
												true
											);
										}}
										isError={formError.shiftName}
										disabled={isDisabled}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<DatePickerField
										name="startDate"
										value={shiftDetails.startDate}
										label="Shift Start Date"
										required={true}
										helperText="Shift start date required"
										onChange={(value) => {
											handleChange(
												value,
												'startDate',
												true
											);
										}}
										isError={formError.startDate}
										disabled={isDisabled}
										// disabledBeforeDates={dayjs().startOf(
										// 	'day'
										// )}
										disabledAfterDates={
											shiftDetails?.endDate
												? shiftDetails.endDate
												: ''
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<DatePickerField
										name="endDate"
										value={shiftDetails.endDate}
										label="Shift End Date"
										required={true}
										helperText="Shift end date required"
										onChange={(value) => {
											handleChange(
												value,
												'endDate',
												true
											);
										}}
										isError={formError.endDate}
										disabled={
											shiftDetails.startDate
												? false
												: true
										}
										disabledBeforeDates={
											shiftDetails.startDate
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<TimePickerField
										name="startTime"
										value={dayjs(shiftDetails.startTime)}
										label="Shift Schedule Start Time"
										required={true}
										helperText="Shift schedule start time required"
										onChange={(value) => {
											handleChange(
												dayjs(value),
												'startTime',
												true
											);
										}}
										isError={formError.startTime}
										disabled={
											!shiftDetails.startDate ||
											isDisabled
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<TimePickerField
										name="endTime"
										value={dayjs(shiftDetails.endTime)}
										label="Shift Schedule End Time"
										required={true}
										helperText="Shift schedule end time required"
										onChange={(value) => {
											handleChange(
												dayjs(value),
												'endTime',
												true
											);
										}}
										isError={formError.endTime}
										disabled={
											!shiftDetails.endDate || isDisabled
										}
										isViewOnly={isViewOnly}
										startTime={dayjs(
											shiftDetails.startTime
										)}
										placeholder=""
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Branch"
										options={
											constantDropdownOptions?.branchCode
										}
										value={shiftDetails.branchId}
										onChange={(value: any) => {
											setFilteredEmployees([]);
											handleChange(
												value,
												'branchId',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Branch is required"
										label="Branch"
										disabled={
											isDisabled ||
											(isUserSupervisor && editShift)
										}
										isError={formError.branchId}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Job Grade"
										options={[
											{
												label: 'Security Guard',
												value: 'true',
											},
											{
												label: 'Other Job Grades',
												value: 'false',
											},
										]}
										value={shiftDetails.isForeMan}
										onChange={(value: any) => {
											setFilteredEmployees([]);
											handleChange(
												value,
												'isForeMan',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Job grade is required"
										label="Job Grade"
										disabled={isDisabled}
										isError={formError.isForeMan}
										isViewOnly={isViewOnly}
									/>
								</Col>

								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Employee"
										// options={
										// 	shiftDetails.branchId
										// 		? constantDropdownOptions?.employees.filter(
										// 				(item: any) =>
										// 					item.employeeBranchId ===
										// 					shiftDetails.branchId
										// 		  )
										// 		: []
										// }
										options={filteredEmployees}
										// options={[]}
										mode="multiple"
										value={shiftDetails.employeeIds}
										onChange={(value: any) => {
											handleChange(
												value,
												'employeeIds',
												true
											);
										}}
										size="large"
										required={true}
										helperText="At least 1 employee required"
										label="Employees"
										disabled={isDisabled}
										isError={formError.employeeIds}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Supervisor"
										options={superVisorOptions}
										value={shiftDetails.supervisorId}
										onChange={(value: any) => {
											handleChange(
												value,
												'supervisorId',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Supervisor required"
										label="Supervisor"
										disabled={isDisabled}
										isError={formError.supervisorId}
										loading={isSupervisorLoading}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputShiftHoursField
										value={shiftDetails.defaultRegularHours}
										onChange={(value) => {
											handleChange(
												value,
												'defaultRegularHours',
												true
											);
										}}
										disabled={
											shiftDetails.isForeMan === null
										}
										onBlur={handleRegularHoursBlur}
										label="Standard Regular Hours"
										showLabel={true}
										required={true}
										name="defaultRegularHours"
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputHoursField
										value={
											shiftDetails.defaultOverTimeHours
										}
										onChange={(value) => {
											handleChange(
												value,
												'defaultOverTimeHours',
												false
											);
										}}
										disabled={
											shiftDetails.isForeMan === null
										}
										label="Standard Over Time Hours"
										showLabel={true}
										required={false}
										name="defaultOverTimeHours"
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputHoursField
										value={minutesToTimeString(
											timeStringToMinutes(
												shiftDetails.defaultRegularHours
											) +
												timeStringToMinutes(
													shiftDetails.defaultOverTimeHours
												)
										)}
										onChange={(value) => {}}
										disabled={true}
										label="Standard Total Hours"
										showLabel={true}
										required={false}
										name=""
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={24} className={styles['col']}>
									<Checkbox
										checked={isNightShift}
										onChange={(e) => {
											setIsNightShift(e.target.checked);
										}}
										className="night-shift-checkbox"
									>
										<p className="checkbox-container-label">
											Night Shift
										</p>
									</Checkbox>
								</Col>
								<Col span={24} className={styles['col']}>
									<InputField
										name="notes"
										value={shiftDetails.notes as string}
										label="Notes"
										required={false}
										helperText="Notes required"
										onChange={(value) => {
											handleChange(value, 'notes', false);
										}}
										isError={formError.notes}
										disabled={isDisabled}
										rows={2}
										isViewOnly={isViewOnly}
									/>
								</Col>
							</Row>
						</div>
						{!isViewOnly && (
							<Row className={styles['modal-buttons']}>
								<Buttons buttons={buttons} />
							</Row>
						)}
					</div>
				</div>
			</Modal>
			<Modal
				title={
					<>
						<ExclamationCircleOutlined
							style={{ color: 'red', marginRight: 8 }}
						/>
						Confirm Submission
					</>
				}
				open={isPopupVisible}
				closable={false}
				footer={null}
			>
				<p className={styles['confirm-modal-text']}>
					Do you want to add logs for shift{' '}
					<span style={{ color: '#584495' }}>
						"
						{selectedShiftDetails?.shiftName ||
							shiftDetails.shiftName}
						"
					</span>{' '}
					now?
				</p>
				<div className={styles['confirm-modal-buttons']}>
					<Buttons buttons={confirmButtons} />
				</div>
			</Modal>
			{isOpen && (
				<AddLogsModal
					selectedShift={selectedShift}
					shiftStartDate={dayjs(shiftDetails.startDate).startOf(
						'day'
					)}
					shiftEndDate={shiftDetails.endDate}
					isOpen={isOpen}
					handleCancel={() => {
						setIsOpen(false);
						setSelectedShift(null);
						fetchShifts();
					}}
					fetchShifts={fetchShifts}
					selectedShiftDetails={selectedShiftDetails}
					selectedShiftLastDate={
						selectedShiftDetails?.lastSubmittedDate
					}
				/>
			)}

			<Modal
				title={
					<>
						<ExclamationCircleOutlined
							style={{ color: 'red', marginRight: 8 }}
						/>
						Missing Shift Dates
					</>
				}
				open={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				closable={false}
				footer={null}
			>
				<p style={{ fontSize: '15px', marginTop: '10px' }}>
					Shifts for the following{' '}
					{formatToDateOnly(dayjs(missingDates)) ===
					formatToDateOnly(dayjs(startDateMinusOne))
						? 'date'
						: 'dates'}{' '}
					have not been created:{' '}
					<span style={{ color: '#584495' }}>
						{`${formatToDateOnly(dayjs(missingDates))}${
							formatToDateOnly(dayjs(missingDates)) ===
							formatToDateOnly(dayjs(startDateMinusOne))
								? ''
								: ` to ${formatToDateOnly(startDateMinusOne)}`
						}`}
					</span>
					. Do you still want to continue? If yes, you will not be
					able to add logs for these days unless a shift is created.
				</p>

				<div className={styles['confirm-modal-buttons']}>
					<Buttons buttons={missingDatesButtons} />
				</div>
			</Modal>
		</>
	);
};
export default CreateShiftModal;
