import { Table } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import { useSelector } from 'react-redux';
import { formatDate, formatWorkWeek } from 'utils/utils';
const { Column } = Table;

type Props = {
	payPeriodLatestData: any;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	handleEdit: any;
};

const PayPeriodTable = (props: Props) => {
	const { payPeriodLatestData, isLoading, tableChangeHandler, handleEdit } =
		props;

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<div>
			<Table
				dataSource={payPeriodLatestData}
				pagination={false}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				scroll={{ y: 'calc(100vh - 320px)' }}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					width={'25%'}
				/>

				<Column
					title="Working Days"
					dataIndex="workWeek"
					key="workWeek"
					className="bg-white"
					width={'15%'}
					render={(text: string[]) => {
						const formattedWorkWeek = formatWorkWeek(text);
						return (
							<Ellipse
								message={formattedWorkWeek}
								maxLength={20}
								key={formattedWorkWeek}
								tooltipMessage={formattedWorkWeek}
								isTooltip={true}
							/>
						);
					}}
				/>

				<Column
					title="Start Date"
					dataIndex="startDate"
					key="startDate"
					className="bg-white"
					width={'15%'}
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
					sorter={true}
				/>
				<Column
					title="End Date"
					dataIndex="endDate"
					key="endDate"
					className="bg-white"
					width={'15%'}
					render={(value) => {
						return formatDate(
							value,
							profileData.companyDateFormat ?? 'DD/MM/YYYY'
						);
					}}
					sorter={true}
				/>

				<Column
					title="Year"
					dataIndex="year"
					key="year"
					className="bg-white"
					width={'15%'}
				/>
				{/* {permissions.allowedPermissions.includes('Edit_Pay_Period') && (
					<Column
						title="Edit"
						key="action"
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								{permissions.allowedPermissions.includes(
									'Edit_Pay_Period'
								) && !data.isFinalize ? (
									<div
										className="cursor-pointer  flex align-center justify-center"
										onClick={() => {
											handleEdit(data);
										}}
									>
										<EditActionSvg />
									</div>
								) : (
									<div className="cursor-not-allowed  flex align-center justify-center">
										<EditActionSvg color="#928F8F" />
									</div>
								)}
							</Space>
						)}
					/>
				)} */}
			</Table>
		</div>
	);
};

export default PayPeriodTable;
