import {
	deleteApi,
	getApi,
	getApiCSV,
	getApiExcel,
	getApiPDF,
	postApi,
} from 'apis';

const getCompanyWizard = async () => {
	return await getApi('/payroll/payrollSettingWizard');
};

const getCompletedPayrollModules = async () => {
	return await getApi('/payroll/complete-payrollModules');
};

const getRemainingPayGroup = async (query: {
	payPeriodId: string;
	onlyView: boolean;
}) => {
	return await getApi('/payroll/remaining-payGroup', query);
};

const getRemainingEmployeeList = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-employeeList', query);
};

const payGroupSetup = async () => {
	return await getApi('/payroll/payGroup-setup');
};

const getPayrollsHistory = async (params: any) => {
	return await getApi('/payroll/payrollHistory', params);
};

const uploadTransferReceipt = async (data: any) => {
	return await postApi('/payroll/payrollHistoryTransferFile', data, true);
};

const getPayslips = async (id: string) => {
	return await getApi('/payroll/employee-pay-slips', {
		payrollId: id,
	});
};

const getEmployeesLeaves = async (params: any) => {
	return await getApi('/payroll/getApprovedLeaves', params);
};

const savePayGroup = async (data: any) => {
	return await postApi('/payroll/payGroup-setup', data);
};

const payrollOverview = async (query: any) => {
	return await getApi('/payroll/payrollOverview', query);
};

const getPayrollDetails = async (id: string) => {
	return await getApi(`/payroll/details/${id}`);
};

const getPayrollEarnings = async (query: any) => {
	return await getApi(`/payroll/earnings`, query);
};

const getAllEmployeeWorkHours = async (query: any) => {
	return await getApi('/payroll/employeeWorkHours', query);
};

const getPayrollDeductions = async (query: any) => {
	return await getApi(`/payroll/deductions`, query);
};

const getAllEmployeesNetPay = async (query: any) => {
	return await getApi('/payroll/employeeNetPay', query);
};

const getPaymentForPayrollDetails = async (id: string) => {
	return await getApi('/payroll/get-payment-details', {
		payrollId: id,
	});
};

const deletePayroll = async (id: any) => {
	return await deleteApi(`/payroll/delete/${id}`);
};

const saveDeductions = async (data: any) => {
	return await postApi('/payroll/setup-deductions', data);
};

const saveEarnings = async (data: any) => {
	return await postApi('/payroll/setup-earnings', data);
};

const saveWorkingsHours = async (data: any) => {
	return await postApi('/payroll/save-working-hours', data);
};

const saveEmployeeLeaves = async (data: any) => {
	return await postApi('/payroll/save-employee-leaves', data);
};
const sendApproval = async (data: any) => {
	return await postApi('/payroll/send-approval', data);
};

const approvePayroll = async (data: any) => {
	return await postApi(`/payroll/approve`, data);
};

const rejectPayroll = async (data: any) => {
	return await postApi(`/payroll/reject`, data);
};

const proceedToPayment = async (data: any) => {
	return await postApi('/payroll/proceed-to-payment', data);
};

const fcbProceedToPaymentQueue = async (data: any) => {
	return await postApi('/bank-details/fcb-transfer-proceed', data);
};

const proceedToPaymentForStanbic = async (data: any) => {
	return await postApi('/payroll/stanbic-proceed-to-payment', data);
};

const exportBnkCsv = async (id: string) => {
	return await getApiPDF('/payroll/export/csv', {
		payrollId: id,
	});
};

const changePaymentStatus = async (data: any) => {
	return await postApi('/payroll/update-payment-status', data);
};

const downloadMultiselectedPayslips = async (data: any) => {
	return await getApiExcel('/payroll/download/multiselected-payslip', data);
};

const getBankDetailsForPayroll = async (currency: string) => {
	return await getApi('/company/bank-details-payroll', {
		currency,
	});
};

const syncBankResponse = async () => {
	return await postApi('/stanbic/response-download');
};

const getPaymentLogs = async (query: any) => {
	return await getApi('/payment-logs', query);
};

const createPayrollHistory = async (bodyData: any) => {
	return await postApi('/payroll/history-create-manual', bodyData);
};

const getPaySlipDetails = async (query: any) => {
	return await getApi('/payroll/employee-pay-slip-details', query);
};

const getSinglePaySlip = async (id: string, employeeId: string) => {
	return await getApiPDF('/payroll/single-payslip', {
		payrollId: id,
		payrollEmployeeId: employeeId,
	});
};

const getSinglePaySlipUsd = async (id: string, employeeId: string) => {
	return await getApiPDF('/payroll/single-usd-employee', {
		payrollId: id,
		payrollEmployeeId: employeeId,
	});
};

export const payrollApi = {
	getCompanyWizard,
	getRemainingPayGroup,
	getRemainingEmployeeList,
	payGroupSetup,
	getCompletedPayrollModules,
	getPayrollsHistory,
	getPayslips,
	savePayGroup,
	payrollOverview,
	deletePayroll,
	getEmployeesLeaves,
	getPayrollDetails,
	getAllEmployeeWorkHours,
	getPayrollEarnings,
	getPayrollDeductions,
	getAllEmployeesNetPay,
	saveDeductions,
	saveEarnings,
	saveWorkingsHours,
	proceedToPayment,
	sendApproval,
	approvePayroll,
	rejectPayroll,
	exportBnkCsv,
	uploadTransferReceipt,
	getBankDetailsForPayroll,
	getPaymentForPayrollDetails,
	changePaymentStatus,
	saveEmployeeLeaves,
	downloadMultiselectedPayslips,
	proceedToPaymentForStanbic,
	syncBankResponse,
	getPaymentLogs,
	fcbProceedToPaymentQueue,
	createPayrollHistory,
	getPaySlipDetails,
	getSinglePaySlip,
	getSinglePaySlipUsd,
};
