import { PermissionContext } from 'components/Global/AuthLayout';
import PayrollSummaryReportComponent from 'components/Report/PayrollSummaryReport';
import { useContext } from 'react';

const PayrollSummaryReport = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes(
		'Allow_Payroll_Reports'
	);
	return <>{check && <PayrollSummaryReportComponent />}</>;
};

export default PayrollSummaryReport;
