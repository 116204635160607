import { useContext, useEffect } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import MidMonthPaysComponent from 'components/Payroll/MidMonthPay';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export const MidMonthPay = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Mid-Month_Pay');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <MidMonthPaysComponent />}</div>;
};
