import { CloseOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import DownloadComponent from 'components/Global/Download';
import Ellipse from 'components/Global/Ellipse';
import { useSelector } from 'react-redux';
import { formatDate, formatNumber, invalidText } from 'utils/utils';
import './index.scss';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allCompensationData: any;
	isLoading: boolean;
	fetchAllCompensationDetails: any;
};

const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allCompensationData,
		isLoading,
	} = props;

	const { data } = useSelector((state: any) => state?.userProfile);

	const { data: profileData } = useSelector(
		(state: any) => state?.userProfile
	);

	return (
		<>
			<Modal
				open={open}
				maskClosable={true}
				width={1000}
				closable={false}
				footer={null}
				rootClassName="compModalRoot"
			>
				<div className="modal-header">
					<Title level={4} className="modal-title">
						History
					</Title>
					<div className="close-icon" onClick={closeModal}>
						<CloseOutlined />
					</div>
				</div>
				<Table
					dataSource={allCompensationData}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(value) => {
							return formatDate(
								value,
								profileData.companyDateFormat ?? 'DD/MM/YYYY'
							);
						}}
					/>
					<Column
						title="Compensation Rate"
						key="compensationRate"
						dataIndex="compensationRate"
						className="bg-white"
						width={200}
						render={(value) => `${value}%`}
					/>
					<Column
						title="Compensation Limit"
						key="compensationLimit"
						dataIndex="compensationLimit"
						className="bg-white"
						width={200}
						render={(value: string) => {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}}
					/>
					<Column
						title="Updated By"
						dataIndex="createdBy"
						key="createdBy"
						className="bg-white"
						render={(text) => text?.fullName}
					/>
					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>
					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						render={(text, record: any) => (
							<div>
								{record?.documentName ? (
									<div>
										<DownloadComponent
											documentUrl={record.documentUrl}
											showTooltip={true}
											downloadText={record.documentName}
											text={record.documentName}
											isLink={true}
											maxLength={20}
										/>
									</div>
								) : (
									'-'
								)}
							</div>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
