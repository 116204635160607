import { postApi, getApi, getApiPDF } from 'apis';
const createNapsa = async (data: any) => {
	return await postApi('/napsa/create', data, true);
};

const getLatestNapsa = async () => {
	return await getApi('/napsa/latest');
};

const getNapsaHistory = async (params: any) => {
	return await getApi(`/napsa/history`, params);
};

const createCommunication = async (data: any) => {
	return await postApi('/napsaCommunication/create', data, true);
};

const getAllCommunication = async (params: any) => {
	return await getApi(`/napsaCommunication`, params);
};

const calculateNapsa = async (data: any) => {
	return await postApi('/calculation/napsa', data);
};

const getNapsaReports = async (params: any) => {
	return await getApi('/napsa/napsaReports', params);
};

const createNapsaRecord = async (data: any, napsaRecordId: string) => {
	return await postApi(`/napsa/record/${napsaRecordId}`, data, true);
};

const downloadNapsaCommunication = async (communicationId: string) => {
	return await getApiPDF('/napsaCommunication/download', {
		communicationId: communicationId,
	});
};

export const napsaApi = {
	createNapsa,
	getLatestNapsa,
	getNapsaHistory,
	createCommunication,
	getAllCommunication,
	calculateNapsa,
	getNapsaReports,
	createNapsaRecord,
	downloadNapsaCommunication,
};
