import { Modal, Table } from 'antd';
import Column from 'antd/es/table/Column';
import DownloadComponent from 'components/Global/Download';
import dayjs from 'dayjs';

type GlobalInformationModalProps = {
	open: boolean;
	InformationData: any;
	closeInformationModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	loading: boolean;
	downloadCommunication?: (communicationId: string) => Promise<void>;
	setDownloadingRowId: (id: string | null) => void;
	downloadingRowId: string | null;
};

const GlobalInformationModal = (props: GlobalInformationModalProps) => {
	const {
		open,
		InformationData,
		closeInformationModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		loading,
	} = props;

	return (
		<Modal
			open={open}
			maskClosable={true}
			width={1000}
			footer={null}
			onCancel={closeInformationModal}
		>
			<Table
				dataSource={InformationData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={loading}
			>
				<Column
					title="Date"
					dataIndex="createdAt"
					key="createdAt"
					render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
					sorter={true}
					className="bg-white"
					width={'33%'}
				/>
				<Column
					title="Title"
					dataIndex="docTitle"
					key="docTitle"
					width={'33%'}
					className="bg-white"
				/>
				<Column
					title="Attachment"
					dataIndex="documentName"
					key="documentName"
					className="bg-white"
					width={'33%'}
					render={(text, record: any) => (
						<div>
							{record.documentName ? (
								<DownloadComponent
									documentUrl={record.documentUrl}
									downloadText={record.documentName}
									showTooltip={true}
									text={record.documentName}
									isLink={true}
									maxLength={20}
								/>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</Modal>
	);
};

export default GlobalInformationModal;
