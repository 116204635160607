import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DownloadComponent from 'components/Global/Download';
import dayjs from 'dayjs';
import { useContext } from 'react';
import ReportPopOver from '../../ReportPopOver';

type tableProps = {
	openUploadRecordModal: (napsaRecordId: string) => void;
	calculateNapsaCurrentPage: number;
	calculateNapsaPageSize: number;
	calculateNapsaTotalRecords: number;
	calculateNapsaIsLoading: boolean;
	calculateNapsaData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};

const TableComponent = (props: tableProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const {
		openUploadRecordModal,
		calculateNapsaCurrentPage,
		calculateNapsaPageSize,
		calculateNapsaTotalRecords,
		calculateNapsaIsLoading,
		calculateNapsaData,
		tableChangeHandler,
	} = props;

	return (
		<Table
			dataSource={calculateNapsaData}
			rowKey={(record: any) => record.id}
			loading={calculateNapsaIsLoading}
			pagination={{
				total: calculateNapsaTotalRecords,
				current: calculateNapsaCurrentPage,
				pageSize: calculateNapsaPageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
				className="bg-white"
			/>
			<Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				width={'10%'}
				className="bg-white"
			/>
			<Column
				title="Pay period name"
				dataIndex="payPeriodName"
				key="payPeriodName"
				width={'20%'}
				className="bg-white"
			/>
			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				render={(text: any) => text}
				width={'12%'}
				className="bg-white"
			/>
			<Column
				title="NAPSA Reports"
				dataIndex="NapsaReport"
				key="NapsaReport"
				className="bg-white"
				width={'15%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.NapsaReport}
						reportType="NAPSA"
					/>
				)}
			/>
			<Column
				title="NAPSA Records"
				dataIndex=""
				width={'28%'}
				key="docName"
				className="bg-white"
				render={(_: any, record: any) => (
					<div
						style={{
							display: 'flex',
							gap: '2rem',
							alignItems: 'center',
						}}
					>
						{isAdd && (
							<Buttons
								buttons={[
									{
										text: 'Upload',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled: false,
										onclick: () => {
											openUploadRecordModal(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						)}
						{record.recordName && (
							<div>
								<DownloadComponent
									documentUrl={record.recordUrl}
									downloadText={record.recordName}
									showTooltip={true}
									text={record.recordName}
									isLink={true}
									maxLength={20}
								/>
							</div>
						)}
					</div>
				)}
			/>
		</Table>
	);
};

export default TableComponent;
