import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import './index.scss';
import CompanySetupComponent from 'components/Settings/PayrollSettings/CompanySetup';

export const CompanySetup = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Company_Setup');

	return <div>{check && <CompanySetupComponent />}</div>;
};
