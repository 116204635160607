import { Button, Col, Form, Radio, Row } from 'antd';
import { Loader } from 'components/Global';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import SelectDropdown from 'components/Global/SelectDropdown';
import { useState } from 'react';
import { PayGroupImage } from 'utils/svgs';
import styles from './index.module.scss';
import InputNumberField from 'components/Global/InputNumberField';

type Props = {
	selectedYear: string;
	selectedPayPeriod: string;
	selectedPayGroup: any;
	handlePayPeriod: (value: string) => void;
	handleSelectedYear: (number: string) => void;
	handlePayGroup: (number: any) => void;
	payGroupOptions: any;
	remainingEmployees: any;
	addEmployee: (id: string) => void;
	removeEmployee: (id: string) => void;
	payGroupError: {
		payPeriod: boolean;
		payGroup: boolean;
		exchangeRate: boolean;
	};
	isPayrollDetailsLoading: boolean;
	exchangeRate: any;
	handleCurrencyChange: (value: number | null) => void;
	edit: boolean;
	onlyView: boolean;
	headerData: any;
	usdCurrency: boolean;
	currencyError: boolean;
	payGroupEmployees: any;
};

const PayGroupSetupComponent = (props: Props) => {
	const {
		selectedPayPeriod,
		selectedYear,
		handlePayPeriod,
		handleSelectedYear,
		payGroupOptions,
		selectedPayGroup,
		handlePayGroup,
		remainingEmployees,
		addEmployee,
		removeEmployee,
		payGroupError,
		isPayrollDetailsLoading,
		edit,
		usdCurrency,
		onlyView,
		exchangeRate,
		handleCurrencyChange,
		currencyError,
		payGroupEmployees,
	} = props;

	const [radioValue, setRadioValue] = useState('employee');

	const handleRadioChange = (e: any) => {
		setRadioValue(e.target.value);
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					autoComplete="off"
				>
					{/* First Row */}
					<Row>
						<Col span={15}>
							<Row
								style={{
									paddingRight: '100px',
									paddingLeft: '30px',
								}}
							>
								<Col span={24} style={{ marginBottom: '15px' }}>
									<GlobalPayPeriod
										selectedYear={selectedYear}
										selectedPayPeriod={selectedPayPeriod}
										handlePayPeriod={(value) =>
											handlePayPeriod(value)
										}
										handleSelectedYear={(value) =>
											handleSelectedYear(value)
										}
										isRow={true}
										isPayPeriodRequired={true}
										isPayPeriodDisabled={edit}
										isYearRequired={true}
										payPeriodError={payGroupError.payPeriod}
										yearError={false}
										isYearLoading={isPayrollDetailsLoading}
										isPayPeriodLoading={
											isPayrollDetailsLoading
										}
									/>
								</Col>
								<Col span={24}>
									<Row gutter={50}>
										<Col span={12}>
											<SelectDropdown
												placeholder="Pay Group"
												options={payGroupOptions}
												value={selectedPayGroup}
												disabled={onlyView}
												loading={
													isPayrollDetailsLoading
												}
												onChange={(value) => {
													handlePayGroup(value);
												}}
												size="large"
												required={true}
												helperText={
													currencyError
														? 'Please select a pay group with the same currency'
														: 'Pay group is required'
												}
												label="Pay Group"
												isError={
													payGroupError.payGroup ||
													currencyError
												}
												// style={{
												// 	paddingRight: '25px',
												// }}
												mode="multiple"
											/>
										</Col>
										{usdCurrency ? (
											<Col span={12}>
												<InputNumberField
													label="Exchange Rate"
													value={exchangeRate}
													isError={
														payGroupError.exchangeRate
													}
													name="Conversion Rate"
													onChange={(value) =>
														handleCurrencyChange(
															value
														)
													}
													placeholder="Enter exchange rate"
													helperText="Exchange rate is required"
													disabled={onlyView}
													required={true}
													style={{
														paddingRight: '25px',
													}}
												/>
											</Col>
										) : (
											<Col span={12}></Col>
										)}
									</Row>
								</Col>
								{usdCurrency && (
									<>
										<Col
											span={24}
											style={{
												marginTop: '10px',
												color: 'grey',
											}}
										>
											<p>
												Selected Pay Group Consist USD
												Currency
											</p>
										</Col>
									</>
								)}
							</Row>
							<Row>
								<Col
									span={24}
									style={{
										marginTop: '12px',
										marginBottom: '-17px',
										paddingLeft: '25px',
									}}
								>
									<Form.Item>
										<Radio.Group
											onChange={handleRadioChange}
											value={radioValue}
										>
											<Radio
												value="employee"
												checked={true}
											>
												Remaining employees
											</Radio>
										</Radio.Group>
										<Radio.Group
											onChange={handleRadioChange}
											value={radioValue}
										>
											<Radio
												value="payGroupEmployees"
												checked={true}
											>
												Pay group employees
											</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col
									span={12}
									className={styles['employee-list']}
								>
									{isPayrollDetailsLoading ? (
										<>
											<div
												className={
													styles[
														'employee-listing-loader'
													]
												}
											>
												<Loader />
											</div>
										</>
									) : radioValue === 'employee' ? (
										<>
											<div
												className={
													styles['employee-header']
												}
											>
												Employees
											</div>
											<div
												className={
													styles[
														'employee-listing-container'
													]
												}
											>
												{remainingEmployees &&
													remainingEmployees.length >
														0 &&
													remainingEmployees.map(
														(emp: any) => {
															return (
																<div
																	style={{
																		display:
																			'flex',
																	}}
																	className={
																		styles[
																			'employee-item'
																		]
																	}
																	key={emp.id}
																>
																	<div>
																		<span>
																			{
																				emp.label
																			}
																		</span>{' '}
																		|{' '}
																		<span
																			style={{
																				color: '#584495',
																			}}
																		>
																			{
																				emp.employeeId
																			}
																		</span>
																	</div>

																	{emp.isAdded ? (
																		<Button
																			className={
																				styles[
																					'employee-add-button'
																				]
																			}
																			disabled={
																				onlyView
																			}
																			onClick={() =>
																				removeEmployee(
																					emp.value
																				)
																			}
																		>
																			Remove
																		</Button>
																	) : (
																		<Button
																			className={
																				styles[
																					'employee-add-button'
																				]
																			}
																			disabled={
																				onlyView
																			}
																			onClick={() =>
																				addEmployee(
																					emp.value
																				)
																			}
																		>
																			Add
																		</Button>
																	)}
																</div>
															);
														}
													)}
											</div>
										</>
									) : (
										<>
											<div
												className={
													styles['employee-header']
												}
											>
												Employees
											</div>
											<div
												className={
													styles[
														'employee-listing-container'
													]
												}
											>
												{payGroupEmployees &&
													payGroupEmployees.length >
														0 &&
													payGroupEmployees.map(
														(emp: any) => {
															return (
																<div
																	style={{
																		display:
																			'flex',
																	}}
																	className={
																		styles[
																			'employee-item'
																		]
																	}
																	key={emp.id}
																>
																	<div>
																		<span>
																			{
																				emp.label
																			}
																		</span>{' '}
																		|{' '}
																		<span
																			style={{
																				color: '#584495',
																			}}
																		>
																			{
																				emp.employeeId
																			}
																		</span>
																	</div>
																</div>
															);
														}
													)}
											</div>
										</>
									)}
								</Col>
							</Row>
						</Col>

						<Col span={9}>
							<PayGroupImage />
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
};
export default PayGroupSetupComponent;
