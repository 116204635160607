import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DownloadComponent from 'components/Global/Download';
import dayjs from 'dayjs';
import { useContext } from 'react';
import ReportPopOver from '../../ReportPopOver';

type tableProps = {
	openUploadRecordModal: (nhimaRecordId: string) => void;
	calculateNhimaCurrentPage: number;
	calculateNhimaPageSize: number;
	calculateNhimaTotalRecords: number;
	calculateNhimaIsLoading: boolean;
	calculateNhimaData: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const TableComponent = (props: tableProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const {
		openUploadRecordModal,
		calculateNhimaCurrentPage,
		calculateNhimaPageSize,
		calculateNhimaTotalRecords,
		calculateNhimaIsLoading,
		calculateNhimaData,
		tableChangeHandler,
	} = props;

	return (
		<Table
			dataSource={calculateNhimaData}
			rowKey={(record: any) => record.id}
			loading={calculateNhimaIsLoading}
			pagination={{
				total: calculateNhimaTotalRecords,
				current: calculateNhimaCurrentPage,
				pageSize: calculateNhimaPageSize,
				showSizeChanger: false,
				pageSizeOptions: [10, 20, 50, 100, 200],
			}}
			onChange={tableChangeHandler}
		>
			<Column
				title="Created Date"
				dataIndex="createdAt"
				key="createdAt"
				render={(text: any) => dayjs(text).format('DD/MM/YYYY')}
				sorter={true}
				width={'15%'}
				className="bg-white"
			/>
			<Column
				title="Currency"
				dataIndex="currency"
				key="currency"
				className="bg-white"
				width={'10%'}
			/>
			<Column
				title="Pay period name"
				dataIndex="payPeriodName"
				key="payPeriodName"
				className="bg-white"
				width={'20%'}
			/>

			<Column
				title="Calculated by"
				dataIndex="calculatedBy"
				key="calculatedBy"
				className="bg-white"
				render={(text: any) => text}
				width={'12%'}
			/>
			<Column
				title="NHIMA Reports"
				dataIndex="nhimaReport"
				key="nhimaReport"
				className="bg-white"
				width={'15%'}
				render={(_: any, record: any) => (
					<ReportPopOver
						content={record.nhimaReport}
						reportType="NHIMA"
					/>
				)}
			/>
			<Column
				title="NHIMA Records"
				dataIndex=""
				key="docName"
				className="bg-white"
				width={'28%'}
				render={(_: any, record: any) => (
					<div
						style={{
							display: 'flex',
							gap: '2rem',
							alignItems: 'center',
						}}
					>
						{isAdd && (
							<Buttons
								buttons={[
									{
										text: 'Upload',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled: false,
										onclick: () => {
											openUploadRecordModal(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						)}

						<div>
							{record.recordName && (
								<div>
									<DownloadComponent
										documentUrl={record.recordUrl}
										showTooltip={true}
										downloadText={record.recordName}
										text={record.recordName}
										isLink={true}
										maxLength={20}
									/>
								</div>
							)}
						</div>
					</div>
				)}
			/>
		</Table>
	);
};

export default TableComponent;
