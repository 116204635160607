import styles from './index.module.scss';
import PaySlipTable from './Table';

type PaySlipProps = {
	selectedPayrollId: string;
	onlyView: boolean;
	headerData: any;
	usdCurrency: any;
	setIsUniversalLoading: any;
};
const PaySlipComponent = (props: PaySlipProps) => {
	const {
		selectedPayrollId,
		onlyView,
		headerData,
		usdCurrency,
		setIsUniversalLoading,
	} = props;

	return (
		<>
			<div className={styles['pay-Slip__table']}>
				<PaySlipTable
					selectedPayrollId={selectedPayrollId}
					onlyView={onlyView}
					usdCurrency={usdCurrency}
					setIsUniversalLoading={setIsUniversalLoading}
					headerData={headerData}
				/>
			</div>
		</>
	);
};

export default PaySlipComponent;
