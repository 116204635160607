import { Col, Row, Table } from 'antd';
import { payrollApi } from 'Api/payroll';
import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { PaySlipsImage } from 'utils/svgs';
import { toastText } from 'utils/utils';
import GlobalHeader from '../../GlobalHeader';
import './index.scss';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	onlyView: boolean;
	usdCurrency: boolean;
	setIsUniversalLoading: any;
	//onDownloadPayslips?: () => void;
	headerData: any;
};

const PaySlipTable: React.FC<Props> = (props: Props) => {
	const {
		selectedPayrollId,
		usdCurrency,
		setIsUniversalLoading,
		headerData,
	} = props;

	const [paySlipData, setPaySlipData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [page, setPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(30);
	const [selectedPayslips, setSelectedPayslips] = useState([]);

	const handlePayslips = (data: any) => {
		setSelectedPayslips(data);
	};

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const rowSelection = {
		getCheckboxProps: (record: any) => ({
			disabled: record.name === 'Disabled User',
		}),
		selectedRowKeys,
		onChange: (keys: React.Key[], selectedRows: any[]) => {
			setSelectedRowKeys(keys);
			handlePayslips(keys);
		},
	};

	const getPaySlips = async (payrollId: string) => {
		try {
			setIsLoading(true);

			setIsUniversalLoading(true);
			let response;
			response = await payrollApi.getPaySlipDetails({
				payrollId,
				page,
				pageSize,
			});

			if (response) {
				setPaySlipData(response.data.data.data);
				setTotalRecords(response.data.data.totalRecords);
			}
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);

			setIsUniversalLoading(false);
		}
	};
	const handlePayslipClick = async (record: any) => {
		const employeeId = record.payrollEmployeeId;
		try {
			let response;
			if (usdCurrency) {
				response = await payrollApi.getSinglePaySlipUsd(
					selectedPayrollId,
					employeeId
				);
			} else {
				response = await payrollApi.getSinglePaySlip(
					selectedPayrollId,
					employeeId
				);
			}

			if (response && response.data) {
				const pdfBlob = new Blob([response.data], {
					type: 'application/pdf',
				});

				const pdfUrl = URL.createObjectURL(pdfBlob);
				const link = document.createElement('a');
				link.href = pdfUrl;
				link.setAttribute('download', 'payslips.pdf');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error('Error fetching payslips:', error);
		}
	};

	useEffect(() => {
		getPaySlips(selectedPayrollId);
	}, [selectedPayrollId, page, pageSize]);

	return (
		<>
			<Row>
				<Col span={24}>
					<GlobalHeader
						headerData={headerData}
						toggleRequired={true}
						fetchAllData={() => getPaySlips(selectedPayrollId)}
						isLoading={isLoading}
						selectedPaySlips={selectedPayslips}
						selectedPayrollId={selectedPayrollId}
						Payslip={true}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={15}>
					<div className="pay-slip-table payroll-steps-table ">
						<Table
							dataSource={paySlipData}
							className="pay-slips"
							scroll={{ y: 'calc(90vh - 400px)' }}
							pagination={{
								total: totalRecords,
								current: page,
								pageSize: pageSize,
								showSizeChanger: false,
								// onShowSizeChange: pageSizeHandler,
								pageSizeOptions: [10, 20, 50, 100, 200],
							}}
							rowKey={(record) => record.payrollEmployeeId}
							loading={isLoading}
							rowSelection={{ ...rowSelection, columnWidth: 1 }}
							onChange={tableChangeHandler}
						>
							<Column
								title="Employee Name | Code"
								dataIndex="optionalEmployeeId"
								key="optionalEmployeeId"
								width={'45%'}
								className="bg-white"
								render={(text, record: any) => (
									<>
										<span>{record.employeeName}</span> |{' '}
										<span className="color-purple">
											{record.employeeId}
										</span>
									</>
								)}
							/>

							<Column
								title="Pay Slips"
								width={'45%'}
								className="bg-white"
								dataIndex="paymentSlips"
								key="paymentSlips"
								render={(text, record: any) => (
									<>
										<a
											href={record.documentLink}
											target="_blank"
											rel="noopener noreferrer"
											onClick={() =>
												handlePayslipClick(record)
											}
										>
											<FaFilePdf
												color="#d32f2f"
												size={20}
											/>
										</a>
									</>
								)}
							/>
						</Table>
					</div>
				</Col>
				<Col span={2}></Col>
				<Col span={7}>
					<div>
						<PaySlipsImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default PaySlipTable;
