import { PermissionContext } from 'components/Global/AuthLayout';
import AttendanceReportComponent from 'components/Report/attendanceReport';
import { useContext } from 'react';

const AttendanceReportPage = () => {
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes(
		'Allow_Attendance_Report'
	);
	return <>{check && <AttendanceReportComponent />}</>;
};

export default AttendanceReportPage;
